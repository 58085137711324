import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`
    mutation changePoaType($selectedPoaType:PoaType) {
        changePoaType(
            selectedPoaType: $selectedPoaType
        ) {
            ...ContractFields
        }
    }
    ${contractFragment}
`;
