import styled from "styled-components";

export const AccordionItem = styled.div`
    &:last-of-type {
        & > div:last-of-type {
            div {
                border-bottom: 0;
                border-top: 0;
            }
        }
    }
`;

export const AccordionHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    padding: 22px 0;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        height: 85px;
    }

    h3 {
        margin-bottom: 0;
        margin-right: auto;
        font-size: 26px;
        line-height: 36px;
        font-weight: ${props => props.isOpen ? 600 : 400};
        color: ${props => props.isOpen ? props.theme.colors.resourceGreen : props.theme.colors.expertiseGray};
    }

    .badge {
        display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: ${props => props.isOpen ? props.theme.colors.resourceGreen : props.theme.colors.lightGrey};
		font-size: 22px;
		color: ${props => props.isOpen ? props.theme.colors.white : props.theme.colors.expertiseGray};
		font-weight: ${props => props.isOpen ? 600 : 400};
		margin-right: 15px;
		flex-shrink: 0;    
    }
    
    .completed {
        display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		background: ${props => props.theme.colors.resourceGreen};
		border-radius: 50%;
		flex-shrink: 0;
		margin-left: 15px; 
    }
`;

export const AccordionBody = styled.div`
    padding-left: 0;
    padding-bottom: 30px;
    border-top: 1px solid ${props => props.theme.colors.lightGrey};
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        padding-bottom: 40px;
    } 
    
    .label {
	    font-size: 15px;
		font-weight: 400;
        margin-top: 15px;
        text-align: left;
		margin-bottom: 5px;
		color: ${props => props.theme.colors.textBlack};
		
		@media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
		    text-align: left;
		}
	}
	
    .label-sign-wrapper {
        text-align: left;
        width: 100%;
        margin-top: 24px;
		font-size: 16px;
		font-weight: 500;
		color: ${props => props.theme.colors.expertiseGray};
		
		a {
		    color: ${props => props.theme.colors.expertiseGray};
		    
		    &:hover {
		        text-decoration: none;

                .link-icon > path {
                    fill: ${props => props.theme.colors.resourceGreen};
                }
		    }
		}

        .label-sign-links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            text-align: left;
            margin-top: 16px;
            margin-bottom: 24px;
        }
    }


    .label-sign-link {
        display: flex;
        gap: 8px;
        max-width: 250px;
        line-height: 22px;

        .link-icon {
            margin-top: 2px;
            display: flex;
            flex-shrink: 0;
        }
    }
		
    .sign-container {
        display: flex;
        max-width: 290px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 15px;
        margin: 0 auto;
		margin-top: 40px;
		
		img {
		    margin-bottom: 0;
		}
		
		button {
		    width: 100%;
		}
    }
    
    .contract-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        .button {
            width: 150px;
        }     
    }
`;
