import React, { useEffect } from "react";
import {useQuery} from "@apollo/react-hooks";
import {Redirect} from "react-router-dom";
import Loading from "../components/Loading";

import {Container, Inner, WhiteBox, Wrapper} from "../style/grid";
import {OnlineSignPoaThanks} from "../style/onlineSignPoaThanks";
import GET_CONTRACT from "../graphql/queries/getContract";
import {Button} from "../style/button";
import {Separator} from "../style/separator";
import {ERROR_ROUTE} from "../configs/routes";
import {getLabel} from "../helpers/getLabel";
import SideBoxSupplyPoint from "../components/SideBoxSupplyPoint";
import { logPoaEvent } from "../utils/analytics";

const OnlineSignPoaThanksContainer = () => {
    const {loading, error, data} = useQuery(GET_CONTRACT);
    
    useEffect(() => {
        const additionalPodEventData = {
            step: 5,
            name: "Podepsáno",
        };

        logPoaEvent(additionalPodEventData);
    }, []);

    if(loading) return <Loading/>;
    if(error) return <Redirect to={`/${ERROR_ROUTE}`} />;

    const {contract: {situation, person: newOwner }} = data;
    return (
        <Wrapper>
            <Container>
                <Inner>
                    <WhiteBox>
                        <OnlineSignPoaThanks>
                            <h1>Děkujeme za vyplnění a podpis plné moci.</h1>
                            <p>Pomůže nám rycheji vyřídit přepis {getLabel("meraku")} na nového majitele.</p>
                            <Separator my="40px"/>
                            <h2>Vyřiďte si energie také pohodlně, on-line...</h2>
                            <Button
                                data-analytics="click"
                                data-name="GetOffer"
                                data-type="Button"
                                data-area="Page"
                                as="a" href="https://www.mnd.cz/" target="_blank" align="center" mt="30px" rel="noopener noreferrer">Mám zájem</Button>
                            <a
                                href="https://www.mnd.cz/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="../images/tezar.png" alt="Těžař"/>
                            </a>
                        </OnlineSignPoaThanks>
                    </WhiteBox>
                    <SideBoxSupplyPoint
                        supplyPoints={data.contract?.supplyPoints}
                        top="0"
                    />
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default OnlineSignPoaThanksContainer;
