import React, { useState } from "react";
import {Formik} from "formik";
import * as yup from "yup";
import {Button} from "../style/button";
import {Radio} from "../style/radio";
import {DocumentsFormWrapper} from "../style/DocumentsFormWrapper";
import TheRightTooltip from "./TheRightTooltip";
import {DOCUMENT_TYPE_POA_CEZ, DOCUMENT_TYPE_POA_MND} from "../configs";
import VariableInputSection from "./VariableInputSection";


const DEAD = "dead";
const MISSING = "missing";

const OWNER = "owner";
const RENT = "rent";

const RELATION_TO_PROPERTY_NAME = "relationToProperty";
const ORIGINAL_CLIENT_STATE_NAME = "originalClientState";

const ParamsForm = ({documentTypes, saveParams, mutationLoading, parameters}) => {

    let initialData;


    if (parameters) {
        initialData = {};

        for (const key in parameters) {
            if (parameters.hasOwnProperty(key) && parameters[key] !== null) {
                initialData[key] = parameters[key].value;
            }
        }
    }

    // Graphql returns null even if param is not present
    const showRelationToPropertySection = Object.keys(initialData).includes(RELATION_TO_PROPERTY_NAME);
    const relationToPropertyRequired = showRelationToPropertySection && parameters[RELATION_TO_PROPERTY_NAME].isRequired;

    const showOriginalClientStateSection = Object.keys(initialData).includes(ORIGINAL_CLIENT_STATE_NAME);
    const originalClientStateRequired = showOriginalClientStateSection && parameters[ORIGINAL_CLIENT_STATE_NAME].isRequired;

    // validations that assert that a section must have a value if it is required
    // i.e. if the first section is required and has radio buttons, then one of them must be selected
    const validSchema = yup.object().shape({
        ...(relationToPropertyRequired && { [RELATION_TO_PROPERTY_NAME]: yup.string().typeError("Vyberte jednu z možností.").required("Vyberte jednu z možností.") }),
        ...(originalClientStateRequired && { [ORIGINAL_CLIENT_STATE_NAME]: yup.string().typeError("Vyberte jednu z možností.").required("Vyberte jednu z možností.") }),
    });

    const [relationToPropertyCheckboxValue, setRelationToPropertyCheckboxValue] = useState(initialData[RELATION_TO_PROPERTY_NAME] || null);
    const [originalClientStateCheckboxValue, setOriginalClientStateCheckboxValue] = useState(initialData[ORIGINAL_CLIENT_STATE_NAME] || null);
    
    return (
        <DocumentsFormWrapper>
            <Formik
                initialValues={initialData}
                validationSchema={validSchema}
                onSubmit={(values) => {
                    const relationVariableValue = 
                        relationToPropertyRequired ?
                            values[RELATION_TO_PROPERTY_NAME]
                            :
                            relationToPropertyCheckboxValue;

                    const originalClientStateVariableValue =
                        originalClientStateRequired ?
                            values[ORIGINAL_CLIENT_STATE_NAME]
                            :
                            originalClientStateCheckboxValue;
                    
                    const variables = {
                        ...(showRelationToPropertySection && { [RELATION_TO_PROPERTY_NAME]: relationVariableValue || null }),
                        ...(showOriginalClientStateSection && { [ORIGINAL_CLIENT_STATE_NAME]: originalClientStateVariableValue || null }),

                    };

                    return saveParams({
                        variables
                    });
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      isValid,
                      values,
                      touched,
                      errors
                  }) => {
                    return (
                        <form onSubmit={handleSubmit}>


                            <div className="section">
                                <p>Potřebujeme doplnit informace k těmto dokumentům:</p>

                                <ul>
                                    {documentTypes.includes(DOCUMENT_TYPE_POA_MND) && <li>
                                        <span>Plná moc pro MND Energie a. s. <TheRightTooltip
                                            text={"Plnou moc používáme pro dokončení procesu zasmluvnění."}/></span>
                                    </li>}
                                    {documentTypes.includes(DOCUMENT_TYPE_POA_CEZ) && <li>
                                        <span>Plná moc pro ČEZ Distribuce, a. s. <TheRightTooltip
                                            text={"Plnou moc používáme pro uzavření smlouvy o připojení mezi zákazníkem a distribucí ČEZ."}/></span>
                                    </li>}
                                </ul>

                                <p style={{marginBottom: 0}}>Náhled dokumentů k podpisu se zobrazí v dalším kroku.</p>
                            </div>

                            {showRelationToPropertySection && 
                                <VariableInputSection
                                    title="Čestně prohlašuji"
                                    isRequired={relationToPropertyRequired}
                                    inputs={[
                                        {
                                            value: OWNER,
                                            label: "jsem vlastníkem nemovitosti/-í, kde se nachází odběrná místa a jsem oprávněnji/je užívat."
                                        },
                                        {
                                            value: RENT,
                                            label: "nejsem vlastníkem nemovitosti/-í, kde se nachází níže uvedená odběrná místa, ale disponuji právem k uzavření smlouvy o sdružených službách dodávky energií pro tato odběrná místa."
                                        }
                                    ]}
                                    inputName="relationToProperty"
                                    values={values}
                                    handleChange={handleChange}
                                    errors={errors}
                                    globalCheckboxState={relationToPropertyCheckboxValue}
                                    setGlobalCheckboxState={setRelationToPropertyCheckboxValue}
                                />
                            }

                            {showOriginalClientStateSection &&
                                <VariableInputSection
                                    title="Vyplňte pouze, pokud došlo k úmrtí původního zákazníka, nebo se zákazník odstěhoval neznámo kam:"
                                    isRequired={originalClientStateRequired}
                                    inputs={[
                                        {
                                            value: MISSING,
                                            label: "původního zákazníka již není možné dohledat a není známa ani jeho současná adresa pobytu/sídla firmy"
                                        },
                                        {
                                            value: DEAD,
                                            label: "původní zákazník zemřel"
                                        }
                                    ]}
                                    inputName="originalClientState"
                                    values={values}
                                    handleChange={handleChange}
                                    errors={errors}
                                    globalCheckboxState={originalClientStateCheckboxValue}
                                    setGlobalCheckboxState={setOriginalClientStateCheckboxValue}
                                />
                            }

                            <div className="control">
                                <Button type="submit" disabled={mutationLoading || !isValid}>
                                    {mutationLoading ? "Načítání..." : "Pokračovat"}
                                </Button>
                            </div>

                        </form>
                    )
                }}
            </Formik>
        </DocumentsFormWrapper>
    );
};

export default ParamsForm;
