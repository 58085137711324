import GET_CONTRACT from "../graphql/queries/getContract";

export default (deleteDocument, contract, id) => {
    return () => {
        deleteDocument({
            variables: {
                id
            },
            optimisticResponse: {
                __typename: "Mutation",
                deleteDocument: {
                    ...contract,
                    documents: contract.documents.filter(item => item.id !== id)
                }
            },
            update: (proxy, { data: { deleteDocument } }) => {
                const data = proxy.readQuery({ query: GET_CONTRACT });

                proxy.writeQuery({ query: GET_CONTRACT, data: {
                        contract: {
                            ...data.contract,
                            documents: data.contract.documents.filter(item => item.id !== deleteDocument.id)
                        }
                    }});
            }
        })
    }
}