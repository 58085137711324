const {DOCUMENT_TYPE_POA} = require("../configs");

module.exports.isUploadValid = (configuration, documents, filterArray, isPoaSelectedOffline) => {

    return configuration.every(item => {
        // BE neví jakou možnost klient zvolí => posílá defaultně required false
        // pokud je zvolen OFFLINE, jsou dokumenty povinné.
        const shouldRequirePoaDocument = item === DOCUMENT_TYPE_POA && isPoaSelectedOffline;

        if (filterArray.includes(item) && !shouldRequirePoaDocument) {
            return true;
        }
        return documents.filter(doc => doc.category === item).length > 0;
    });
};
