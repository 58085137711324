import React, {useEffect} from 'react';
import {Field, Formik} from 'formik';
import CustomInputComponent from "../components/CustomInputComponent";

import {FieldStyled} from "../style/FieldStyled";
import {FormStyled} from "../style/FormStyled";
import {ButtonWrapper} from "../style/grid";

import {ADD_DOCUMENTS} from "../configs/routes";
import {Button} from "../style/button";
import {Link} from "react-router-dom";

import * as yup from "yup";

const initialState = {
    emailFor: "",
    emailFrom: ""
};

const validEmailSchema = yup.object().shape({
    emailFor: yup.string()
        .email('Prosím vyplňte validní e-mail')
        .required('Prosím vyplňte e-mail'),
});

const SendPoaForm = ({newOwner, sendPOASignatureRequest, sendingPoaSignatureRequest}) => {
    useEffect(() => {
        initialState.message = initialState.message + newOwner.name;
    }, []); // eslint-disable-line

    return (
        <Formik
            initialValues={initialState}
            validationSchema={validEmailSchema}
            onSubmit={async(values, {setSubmitting, resetForm}) => {
                sendPOASignatureRequest({
                    variables: {
                        "email": values.emailFor
                    }
                });
            }}>{(props) => (
            <FormStyled onSubmit={props.handleSubmit}>
                <Field
                    type="email"
                    name="emailFor"
                    placeholder="e-mail"
                    label="Pro"
                    component={CustomInputComponent}
                />
                <FieldStyled>
                    <label>Od</label>
                    <p>mnd@mndcz.cz</p>
                </FieldStyled>
                <ButtonWrapper px="15px">
                    <Button as={Link} to={`/${ADD_DOCUMENTS}`} color="gray" disabled={sendingPoaSignatureRequest}>Zpět</Button>
                    <Button type="submit" disabled={sendingPoaSignatureRequest}>{sendingPoaSignatureRequest ? "Načítání..." : "Odeslat"}</Button>
                </ButtonWrapper>
            </FormStyled>
        )}
        </Formik>
    )
};

export default SendPoaForm;
