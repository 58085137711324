import React from "react";
import LoadingIcon  from "../style/loading";

const Loading = () => {
	return <LoadingIcon>
				<span>Načítání...</span>
			</LoadingIcon>;
};

export default Loading;
