import gql from 'graphql-tag';

export default gql`
    fragment PersonFields on Person {
        name
        email
        surname
        city
        zipCode
        phone
        street
        streetNumber
        houseNumber
        companyName
        companyId
    }`;

