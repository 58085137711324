import React from "react";
import slugify from "slugify";
import moment from "moment";
import "moment/locale/cs";
import momentLocalizer from "react-widgets-moment";
import {DateTimePicker} from "react-widgets";

import {FieldStyled} from "../style/FieldStyled";

moment.locale("cs");
momentLocalizer();

const CustomDateComponent = ({
    field,
    form: { touched, errors, setFieldValue },
    ...props
}) => (
    <FieldStyled className="dateField">
        <label htmlFor={slugify(props.label)}>{props.label}</label>
        <DateTimePicker
            format="l"
            value={field.value === "" ? null : moment(field.value).toDate()}
            parse={["D. M. YYYY", "D.M.YYYY", "DDMMYYYY"]}
            time={false}
            messages={{calendarButton: "Vyberte datum"}}
            placeholder="DD.MM.RRRR"
            onChange={(value) => setFieldValue(field.name, moment(value).format("YYYY-MM-DD"))}
        />
        {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </FieldStyled>
);

export default CustomDateComponent;
