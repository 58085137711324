import styled from "styled-components";

export const Document = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.image {
		display: inline-block;
		border: 5px solid ${props => props.theme.colors.resourceGreen};

		img {
			display: block;
			max-width: 145px;
			margin: 0;
		}
	}

	.label {
		font-weight: 600;
		font-size: 15px;
		margin: 0 auto;
		color: ${props => props.theme.colors.exploratoryGreen};
		max-width: 145px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: 25px;

		&:not(:empty) {
			height: 20px;
			margin-bottom: 10px;
			@media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
				height: 50px;
			}
		}
	}

	.link {
		margin: 10px 0 20px 0;
		text-decoration: underline;
		color: ${props => props.theme.colors.expertiseGray};
		font-size: 14px;

		&:hover {
			text-decoration: none;
			color: ${props => props.theme.colors.expertiseGray};
		}
	}
`;
