import React from "react";
import {Link} from "react-router-dom";

import poaTypeConfig from "../configs/poaTypeConfig";
import {POA_TYPE_ONLINE} from "../configs/poaType";
import {SEND_POA, signatureStates} from "../configs/routes";

import PoaRadio from "../components/PoaRadio";

import {Button} from "../style/button";
import {UploadSection} from "../style/uploadSection";
import TheRightTooltip from "./TheRightTooltip";

export const Poa = ({
    contract,
    selectedPoaType,
    onlinePoa,
    uploadDocument,
    changePoaType,
    setModal,
    documentThirdPartyPoa,
    documentsPoaOffline,
    changePoaTypeLoading,
    setModalPreview,
    uploadDocumentLoading,
    deleteDocument,
    letter,
    configuration
}) => {
    return (
        <UploadSection>
            {letter && <span className="letter">{letter}</span>}
            <h3>
                Plná moc od původního majitele, čestné prohlášení nebo akceptace výpovědi
                <TheRightTooltip text={"Plnou moc od původního majitele potřebujeme, abychom za něj mohli ukončit jeho aktuální smlouvu s jiným dodavatelem. Následně můžeme přepsat měřidlo."} />
            </h3>

            <PoaRadio
                contract={contract}
                poaTypeConfig={poaTypeConfig}
                handleChange={(event) => {
                    return changePoaType({
                        variables: {
                            selectedPoaType: event.target.value
                        },
                        optimisticResponse: {
                            __typename: "Mutation",
                            changePoaType: {
                                ...contract,
                                selectedPoaType: event.target.value,
                            }
                        },
                    });
                }}
                selectedPoaType={selectedPoaType}
                onlinePoa={onlinePoa}
                documentThirdPartyPoa={documentThirdPartyPoa}
                documentsPoaOffline={documentsPoaOffline}
                changePoaTypeLoading={changePoaTypeLoading}
                setModal={setModal}
                uploadDocument={uploadDocument}
                setModalPreview={setModalPreview}
                uploadDocumentLoading={uploadDocumentLoading}
                deleteDocument={deleteDocument}
                configuration={configuration}
            />

            {selectedPoaType === POA_TYPE_ONLINE && (!onlinePoa?.signatureState || onlinePoa?.signatureState === signatureStates.NONE)  && <Button as={Link} variant="small" to={`/${SEND_POA}`}>Vytvořit plnou moc on-line</Button>}
        </UploadSection>
    );
};

export default Poa;
