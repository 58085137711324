import React from "react";
import {Radio} from "../style/radio";

import getDocumentPreview from "../helpers/getDocumentPreview";
import {DOCUMENT_TYPE_POA} from "../configs";
import {POA_TYPE_OFFLINE, POA_TYPE_ONLINE} from "../configs/poaType";
import {signatureStates} from "../configs/routes";

import {InfoBoxPoa} from "../style/infoBoxPoa";
import {StyledDropzoneStatic} from "../style/styledDropzoneStatic";

import UploadedDocument from "./UploadedDocument";
import UploadDocuments from "./UploadDocuments";

const PoaRadio = ({
    contract,
    radioType = "default",
    poaTypeConfig,
    handleChange,
    selectedPoaType,
    onlinePoa,
    documentThirdPartyPoa,
    documentsPoaOffline,
    setModal,
    uploadDocument,
    setModalPreview,
    uploadDocumentLoading,
    deleteDocument,
    configuration
}) => {
    return poaTypeConfig.map((type) => <Radio key={type.type}>
            <label key={type.type} className={selectedPoaType === type.type ? "checked" : ""}>
                <input type="radio"
                    value={type.type}
                    onChange={handleChange}
                    key={type.type}
                    name="type"
                    checked={selectedPoaType === type.type}
                />
                {type.label}
                <span/>
                {(onlinePoa && onlinePoa?.signatureState && onlinePoa?.signatureState === signatureStates.SENT_FOR_SIGNATURE && type.type === POA_TYPE_ONLINE) &&
                <InfoBoxPoa>
                    <span>E-mail odeslán. Čekáme na podpis plné moci.</span>
                    {/* <Link to={`/${SEND_POA}`}>Poslat znovu</Link> */}
                </InfoBoxPoa>}

                {(onlinePoa && onlinePoa?.signatureState && onlinePoa?.signatureState === signatureStates.SIGNATURE_CONFIRMED && type.type === POA_TYPE_ONLINE) &&
                <InfoBoxPoa>
                    <span>Plná moc podepsaná.</span>
                </InfoBoxPoa>}
            </label>
            {type.description && <p>{type.description}</p>}
            {documentThirdPartyPoa && onlinePoa?.signatureState && onlinePoa?.signatureState === signatureStates.ONLINE_SIGNATURE_CONFIRMED && type.type === POA_TYPE_ONLINE && selectedPoaType === POA_TYPE_ONLINE && <StyledDropzoneStatic>
                <UploadedDocument
                    link={documentThirdPartyPoa.fileLink}
                    linkLabel={documentThirdPartyPoa.fileName}
                    overlayLabel="Podepsaná plná moc"
                    preview={getDocumentPreview(documentThirdPartyPoa.mimetype, documentThirdPartyPoa.fileLink)}
                    setModalPreview={setModalPreview}
                />
            </StyledDropzoneStatic>}

            {type.type === POA_TYPE_OFFLINE && selectedPoaType === POA_TYPE_OFFLINE && radioType === "default" && <UploadDocuments
                configuration={configuration}
                type="simple"
                setModal={setModal}
                documents={documentsPoaOffline}
                uploadDocument={uploadDocument}
                setModalPreview={setModalPreview}
                contract={contract}
                uploadDocumentLoading={uploadDocumentLoading}
                deleteDocument={deleteDocument}
            />}
        </Radio>
    );
};

export default PoaRadio;
