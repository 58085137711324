import React from "react";
import slugify from "slugify";
import {FieldStyled} from "../style/FieldStyled";
import {TextField} from "../style/textField";

const CustomInputComponent = ({
    field,
    form: { touched, errors },
    ...props
}) => (
    <FieldStyled>
        <label htmlFor={slugify(props.label)}>{props.label}</label>
        <TextField
            type="text"
            {...field}
            {...props}
            id={slugify(props.label)}
        />
        {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </FieldStyled>
);

export default CustomInputComponent;
