import React, {useState} from "react";
import {useMediaQuery} from "react-responsive";
import {useMutation} from '@apollo/react-hooks';

import SaveMessage from "../components/SaveMessage";
import {SideBoxContainer as SideBoxContainerStyle} from "../style/sideBox";
import SaveIcon from "../style/icons/save";
import {Link} from "../style/link";
import theme from "../style/theme";
import SAVE_CONTRACT from "../graphql/mutations/saveContract";
import {ERROR_ROUTE} from "../configs/routes";
import {Redirect} from "react-router-dom";

const SideBoxRecap = ({
                          savingEnabled = true,
                          top,
                          mt,
                      }) => {
    const isMobileScreen = useMediaQuery({query: `(max-width: ${theme.breakpoints.mobile})`});
    const [isMessageOpen, setMessage] = useState(false);

    const handleSaveContract = () => {
        setMessage(true);
        saveContract({
            variables: {
                "url": window.location.href
            }
        });
    };

    const [
        saveContract,
        {error: saveContractError},
    ] = useMutation(SAVE_CONTRACT, {});

    if (saveContractError) return <Redirect to={`/${ERROR_ROUTE}`}/>;

    return (
        <SideBoxContainerStyle mt={mt} top={top}>
            <div className="header">
                {isMobileScreen && savingEnabled &&
                <Link
                    className="save" onClick={() => handleSaveContract()}><SaveIcon/>Uložit a dokončit později</Link>}
            </div>
            {(!isMobileScreen && savingEnabled) &&
            <Link
                className="save" onClick={() => handleSaveContract()}><SaveIcon/>Uložit a dokončit později</Link>}
            <SaveMessage isOpen={isMessageOpen} setMessage={setMessage}/>
        </SideBoxContainerStyle>
    );
};

export default SideBoxRecap;
