const PAGE_VIEW_EVENT_TYPE = 'page_view';
const CLICK_EVENT_TYPE = 'click';
const SIGNATURE_EVENT_TYPE = 'SignatureStep';
const POA_EVENT_TYPE = 'POA';

// General event properties
const MND_PLATFORM = 'web';
const MND_LANGUAGE = 'cs'
const MND_CURRENCY = 'CZK';
                        
// Enviroments
const PRODUCTION_ENVIROMENT = 'production';
const PROJECT_ENVIROMENT = 'project';
const DEVELOPMENT_ENVIROMENT = 'development';

const CLICK_TRIGGER_QUERY_SELECTOR = '[data-analytics="click"]';

// Page specific data
export const PAGE_SECTION_GAS = 'Gas';
export const PAGE_SECTION_ELECTRICITY = 'Electricity';

export const PAGE_TYPE_PURCHASE = 'Purchase';
export const PAGE_TYPE_CONFIRM = 'Confirm';
export const PAGE_TYPE_CHECKOUT = 'Checkout';

function getEnvironment() {
    const { hostname } = document.location;
    
    if (hostname.includes("project-podpis.mnd.cz")) {
        return PROJECT_ENVIROMENT;
    }

    if (hostname.includes("podpis.mnd.cz")) {
        return PRODUCTION_ENVIROMENT;
    }
    
    return DEVELOPMENT_ENVIROMENT;
}

export const getFormatRequestDocuments = (activeDocumentRequest) => {
    if(!activeDocumentRequest) {
        return null;
    }

    if(activeDocumentRequest.type !== "custom") {
        return null;
    }

    const documentTypes = activeDocumentRequest.configurationItems.map(item => item.category).concat(
        activeDocumentRequest.documents.map(document => document.category)
    );

    return documentTypes.join("|");
};

const gaEventPageTypeByPathname = {
    '/online-podpis-potvrzeni': PAGE_TYPE_CONFIRM,
    '/online-podpis-dekujeme': PAGE_TYPE_PURCHASE,
    '/online-poa-podpis-potvrzeni': PAGE_TYPE_CONFIRM,
    '/online-poa-podpis-dekujeme': PAGE_TYPE_CHECKOUT,
};

export function logPoaEvent(additionalPodEventData) {
    const signatureEventData = {
        event: POA_EVENT_TYPE,
        type: 'SignPOA',
        ...additionalPodEventData,
        _clear: true
    }

    window.dataLayer.push(signatureEventData);   
}

export function logSignatureStepEvent(additionalSignatureEventData) {
    const signatureEventData = {
        event: SIGNATURE_EVENT_TYPE,
        ...additionalSignatureEventData,
        _clear: true
    }

    window.dataLayer.push(signatureEventData);   
}

export function logPageViewEvent() {
    const pageViewEventData = {
        event: PAGE_VIEW_EVENT_TYPE,
        site: document.location.hostname,
        platform: MND_PLATFORM,
        environment: getEnvironment(),
        language: MND_LANGUAGE,
        currency: MND_CURRENCY,
        page: {
            title: document.title,
            url: document.location.href,
            path: document.location.pathname,
            type: gaEventPageTypeByPathname[document.location.pathname] || PAGE_TYPE_CHECKOUT,
        },
        _clear: true
    };

    if (!window.dataLayer) {
        window.dataLayer = window.dataLayer || [];
    }

    window.dataLayer.push(pageViewEventData);
}

function getGenericEventDataFromElement(element) {
    const { name, type, area, url, text, supplier } = element.dataset;

    const clickUrl = url || element.href;
    const clickText = text || element.innerText;

    return {
        ...(name ? { name } : {}),
        ...(type ? { type } : {}),
        ...(area ? { area } : {}),
        ...(clickUrl ? { url: clickUrl } : {}),
        ...(clickText ? { text: clickText } : {}),
    };
}

function logClickEvent(element) {
    const elementData = getGenericEventDataFromElement(element);

    const clickEventData = {
        event: CLICK_EVENT_TYPE,
        click: elementData,
        _clear: true
    };

    window.dataLayer.push(clickEventData);
}

export function registerEventHandlers() {
    document.addEventListener("click", event => {
        const trackedLink = event.target.closest(CLICK_TRIGGER_QUERY_SELECTOR);
        if (trackedLink) {
            logClickEvent(trackedLink);
        }
    });
}