import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`
    mutation SIGN_DOCUMENTS($signature: String!) {
        signDocuments(
            signature: $signature
        ) {
            ...ContractFields
        }
    }
    ${contractFragment}
`;
