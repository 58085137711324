import styled from "styled-components";

export const TextField = styled.input`
    background: ${props => props.theme.colors.lightGrey};
    border: 0;
    font-size: 16px;
    color: ${props => props.theme.colors.expertiseGray};
    padding: 0 11px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    &:focus {
        outline: none;
        box-shadow: inset 0 0 0 1px ${props => props.theme.colors.resourceGreen};
    }
`;
