import {
    DOCUMENT_REQUEST_STATE_COMPLETED,
    DOCUMENT_REQUEST_STATE_NONE,
    DOCUMENT_REQUEST_STATE_SIGNATURE_CONFIRMED
} from "../configs";
import {CUSTOM} from "../configs/documentRequestType";

export const SAVE_PARAMS_STAGE = 'save_params';
export const SIGN_DOCUMENTS_STAGE = 'sign_documents';
export const DRAFT_CONTRACT_STAGE = 'draft_contract';
export const SIGN_CONTRACT_STAGE = 'sign_contract';
export const ADD_DOCUMENTS_STAGE = 'add_documents';

const stageNames = {
    [SAVE_PARAMS_STAGE]: "Doplnění údajů",
    [SIGN_DOCUMENTS_STAGE]: "Podpis dokumentů",
    [DRAFT_CONTRACT_STAGE]: "Návrh smlouvy",
    [SIGN_CONTRACT_STAGE]: "Podpis smlouvy",
    [ADD_DOCUMENTS_STAGE]: "Doplnění dokumentů",

}


const getStepsConfig = ({documentsState, signatureState, paramsPoa, type}, {forceStage = null, forceStageName = ""}) => {

    if (type === CUSTOM) {
        let steps = [];
        let isDone = documentsState === DOCUMENT_REQUEST_STATE_COMPLETED;

        if(signatureState === DOCUMENT_REQUEST_STATE_NONE) {
            steps = [ADD_DOCUMENTS_STAGE];
        } else if (documentsState === DOCUMENT_REQUEST_STATE_NONE && paramsPoa) {
            steps = [SAVE_PARAMS_STAGE, SIGN_DOCUMENTS_STAGE];
            isDone = documentsState === DOCUMENT_REQUEST_STATE_SIGNATURE_CONFIRMED;
        } else if(documentsState === DOCUMENT_REQUEST_STATE_NONE && !paramsPoa) {
            steps = [SIGN_DOCUMENTS_STAGE];
            isDone = documentsState === DOCUMENT_REQUEST_STATE_SIGNATURE_CONFIRMED;
        } else if (paramsPoa) {
            steps = [SAVE_PARAMS_STAGE, SIGN_DOCUMENTS_STAGE, ADD_DOCUMENTS_STAGE];
        } else {
            steps = [SIGN_DOCUMENTS_STAGE, ADD_DOCUMENTS_STAGE];
        }

        return {
            currentStage: steps.indexOf(forceStageName),
            isDone,
            stages: steps.map(step => stageNames[step])
        }
    }

    return {
        currentStage: forceStage,
        isDone: false,
        stages: ["Návrh smlouvy", "Podpis smlouvy", "Další dokumenty"]
    }

}

export default getStepsConfig;