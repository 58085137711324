import {useCallback, useState} from "react";

const useAddOrRemove = (defaultValue = []) => {
    const [valuesArray, setFilter] = useState(defaultValue);

    const addOrRemove = useCallback((value) => {
        setFilter((prevState) => {
            let newState = [...prevState];
            if(newState.includes(value)) {
                newState = newState.filter(item => item !== value);
                setFilter(newState);
                return;
            }
            newState.push(value);
            setFilter(newState);
        });
    }, []);


    return {valuesArray, addOrRemove};

};

export default useAddOrRemove


