import styled from "styled-components";
import psbc from "../helpers/psbc";

export const Button = styled.button`
    display: flex;
    align-items: center;
    align-self: ${props => props.align === "center" ? "center" : "flex-start"};
    justify-content: center;
    padding: 3px 20px 0 20px;
    border: 0;
    background-color: ${props => props.theme.colors.resourceGreen};
    cursor: pointer;
    min-height: 51px;
    min-width: ${props => props.autoWidth ? "auto" : "230px"};
    font-size: 18px;
    color: ${props => props.theme.colors.white};
    font-weight: 600;
    border-bottom: 3px solid ${props => props.theme.colors.resourceGreenDarker};
    transition: background-color ${props => props.theme.times.defaultAnimation};
    text-decoration: none;
    white-space: nowrap;
    max-width: ${props => props.maxWidth ? "260px" : "auto"}; 
    margin-top: ${props => props.mt ? props.mt : 0};
    position: relative;
    
    svg {
        margin-right: 40px;
    }
    
    &:focus {
        outline: 0;
    }
    
    @media (hover: hover) {         
        &:hover {
            background-color: ${props => props.theme.colors.resourceGreenDarker};
        }
    }
    
    &:disabled {
        background-color: ${props => props.theme.colors.professionalGray};
        cursor: not-allowed;
        border-color: ${props => props.theme.colors.professionalGray};
        color: ${props => props.theme.colors.white};
    }
    
    &:disabled:hover {
        background-color: ${props => props.theme.colors.professionalGray};
    }
    
    .tooltip {
        display: none;
        position: absolute;
        bottom: 55px;
        width: 400px;
        
        line-height: 20px;
        
        text-align: left;
        white-space: normal;

        padding: 14px;
        
        background-color: ${props => props.theme.colors.resourceGreen100};

        border: 1px solid ${props => props.theme.colors.professionalGray};
        border-radius: 4px;
        color: ${props => props.theme.colors.exploratoryGreen};
        
        font-weight: 500;
        font-size: 12px;
        
        &:after {
            content:'';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -6px;
            width: 0;
            height: 0;
            border-top: solid 6px ${props => props.theme.colors.professionalGray};
            border-left: solid 6px transparent;
            border-right: solid 6px transparent;
        }
        
  
    }
    
    &:hover {
        background-color: ${props => psbc(props.theme.colors.resourceGreen, 0.2)};
    }
    
    &:hover .tooltip {
        display: block;
        opacity: 1;
        z-index: 11;
    }
    
    
    
  
    ${props => props.variant === "small" && `
        min-height: 40px;
        min-width: 173px;
        font-size: 16px;
        font-weight: 600;
        padding: 3px 25px 0 25px;
       
    `}
    
    ${props => props.color === "white" && `
        background-color: ${props.theme.colors.white};
        color: ${props.theme.colors.textBlack};
        border-bottom: 3px solid ${props.theme.colors.grey};
        
        @media (hover: hover) { 
            &:hover {
                background-color: ${props.theme.colors.resourceGreenDarker};
                color: ${props.theme.colors.white};
            } 
        }      
    `}
    
    ${props => props.color === "gray" && `
        background-color: ${props.theme.colors.grey};
        color: ${props.theme.colors.exploratoryGreen};
        min-width: auto;
        border-bottom: 3px solid ${props.theme.colors.professionalGray};
        
        &:hover {
            background-color: ${props.theme.colors.resourceGreenDarker};
            color: ${props.theme.colors.white};
        }
    `}
    
    ${props => props.color === "exploratoryGreen" && `
        background-color: ${props.theme.colors.exploratoryGreen};
        color: ${props.theme.colors.white};
        border-bottom: 3px solid ${props.theme.colors.exploratoryGreen600};
        min-width: auto;
        
        &:hover {
            background-color: ${props => psbc(props.theme.colors.exploratoryGreen, 0.2)};
        }
    `}
    
    ${props => props.variant === "info" && `
        position: relative;
        align-self: center;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
        border: 10px solid ${props.theme.colors.professionalGray};
        border-radius: 10px;
        margin: 0 5px;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        transition: all 250ms;
        
        .tooltip {
            bottom: 25px;
        }     

        &:hover {
            border-color: ${psbc(props.theme.colors.professionalGray, 0.2)};
        }
    `}
    
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
        ${props => props.variant === "info" && `
            width: 16px;
            height: 16px;
            min-height: auto;  
            min-width: auto;
            border-width: 8px;
            font-size: 11px;
            
            .tooltip {
                right: -10px;
            }
        `}
        .tooltip {
            width: 90vw;
        }
    }
      
    ${props => props.variant === "link" && `
        background-color: transparent;
        border: none;
        color: ${props.theme.colors.textBlack};
        text-decoration: underline;
        font-size: 15px;
        font-weight: normal;
        min-width: auto;
        
        &:hover {
            background-color: transparent;
            border: none;
        }
        
        svg {
            margin-right: 10px;
        } 
    `}
`;
