import React, {Fragment} from "react";

import {StepNavigation as StepNavigationStyle, Step, Bar} from "../style/stepNavigation";
import TickIcon from "../style/icons/tick";

const StepNavigation = ({currentStage = 0, isDone = false, stages = ["Návrh smlouvy", "Podpis smlouvy", "Další dokumenty"]}) => {

    return <StepNavigationStyle>
        <div>
            {stages.map((item, index) => <Fragment key={item}>
                    {index > 0 && <Bar width={index <= currentStage ? 100 : 0}/>}
                    <Step
                        type={currentStage > index|| (currentStage === index && isDone) ? "done" : currentStage < index ? "disabled" : "active"}>
                        <div>{currentStage > index || (currentStage === index && isDone) ? <TickIcon/> : (index + 1)}</div>
                        <p>{item}</p>
                    </Step>
                </Fragment>)}
        </div>
    </StepNavigationStyle>;
};

export default StepNavigation;



