import React from "react";

import {Wrapper, Container, WhiteBoxError, Inner} from "../style/grid";
import {PageHeaderError} from "../style/pageHeader";

const ErrorContainer = () => {
    return (
        <Wrapper>
            <Container>
                <Inner>
                    <WhiteBoxError>
                        <PageHeaderError>
                            <h1>Někde se stala chyba</h1>
                            <p>Prosím volejte na infolinku: <a href="tel:800400500">800 400 500</a></p>
                        </PageHeaderError>
                    </WhiteBoxError>
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default ErrorContainer;
