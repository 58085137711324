import React, {useEffect, useState} from "react";
import {Field, Formik} from "formik";
import * as yup from "yup";

import {PERSON_TYPE_BUSINESS, PERSON_TYPE_PERSON} from "../configs";
import {ThirdPartyForm} from "../style/thirdPartyForm";
import {Button} from "../style/button";
import {Radio} from "../style/radio";
import CustomInputComponent from "./CustomInputComponent";
import CustomDateComponent from "./CustomDateComponent";
import {getLabel} from "../helpers/getLabel";


const validEmailSchemaPerson = yup.object().shape({
    fullName: yup.string().required("Vyplňte jméno"),
    dateOfBirth: yup.date().required("Zadejte datum narození"),
    wholeAddr: yup.string().required("Vyplňte adresu"),
    placeOfSignature: yup.string().required("Vyplňte místo podpisu")
});

const validEmailSchemaBusiness = yup.object().shape({
    wholeAddr: yup.string().required("Vyplňte adresu"),
    businessName: yup.string().required("Vyplňte název firmy"),
    businessId: yup.string().required("Vyplňte IČO"),
    placeOfSignature: yup.string().required("Vyplňte místo podpisu"),
    thirdPartyRepresented: yup.string().required("Vyplňte zástupce"),
});

const Filter = ({type, setType, resetForm}) => {
    return (
        <div className="radioFilter">
            <Radio>
                <label>
                    <input type="radio"
                           value={PERSON_TYPE_PERSON}
                           checked={type === PERSON_TYPE_PERSON}
                           onChange={(e) => {
                               resetForm();
                               setType(e.target.value);
                           }}
                           name="personType"
                    />
                    Soukromá osoba
                    <span/>
                </label>
            </Radio>

            <Radio>
                <label>
                    <input type="radio"
                           value={PERSON_TYPE_BUSINESS}
                           checked={type === PERSON_TYPE_BUSINESS}
                           onChange={(e) => {
                               resetForm();
                               setType(e.target.value);
                           }}
                           name="personType"
                    />
                    Firma nebo živnostník
                    <span/>
                </label>
            </Radio>
        </div>
    );
};

const MainForm = ({handleSubmit, type, dirty, isValid, mutationLoading, setFieldValue}) => {

    useEffect(() => {
        window.smartform && window.smartform.rebindAllForms(true, function () {
            const instanceId = window.smartform.getInstanceIds()[0];
            const instance = window.smartform.getInstance(instanceId);
            instance.setSelectionCallback((element, text, type, suggestion) => {
                if (suggestion.isWholeAddress()) {
                    setFieldValue("wholeAddr", text);
                }
                return true;
            });
        });
    }, [setFieldValue]);

    return <form onSubmit={handleSubmit}>
        <div className="formFields">
            {type === PERSON_TYPE_PERSON ? <>
                <Field
                    type="text"
                    name="fullName"
                    label="Jméno, příjmení, titul"
                    component={CustomInputComponent}
                />

                <Field
                    className="date"
                    name="dateOfBirth"
                    label="Datum narození"
                    component={CustomDateComponent}
                />

                <Field
                    type="text"
                    name="wholeAddr"
                    label="Adresa trvalého pobytu"
                    className={"smartform-whole-address"}
                    component={CustomInputComponent}
                />
                <Field
                    type="text"
                    name="thirdPartyRepresented"
                    label={"Zastoupen"}
                    placeholder="jméno případného zmocněnce"
                    component={CustomInputComponent}
                />
            </> : <>
                <Field
                    type="text"
                    name="businessName"
                    label="Obchodní firma, název"
                    component={CustomInputComponent}
                />
                <Field
                    type="text"
                    name="businessId"
                    label="IČO"
                    component={CustomInputComponent}
                />
                <Field
                    type="text"
                    name="wholeAddr"
                    label="Sídlo"
                    className={"smartform-whole-address"}
                    component={CustomInputComponent}
                />
                <Field
                    type="text"
                    name="thirdPartyRepresented"
                    label={"Zastoupena"}
                    component={CustomInputComponent}
                />
            </>}
            <Field
                type="text"
                name="placeOfSignature"
                label="Podepsáno v"
                placeholder="např. Hodoníně nebo Blansku"
                component={CustomInputComponent}
            />
        </div>
        <div className="buttonContainer">
            <Button type="submit" disabled={mutationLoading || !isValid || !dirty} variant="small">
                {mutationLoading ? "Načítání..." : "Pokračovat"}
            </Button>
        </div>
    </form>;
}

const ThirdParty = ({saveThirdParty, mutationLoading, oldOwner}) => {
    const [type, setType] = useState(PERSON_TYPE_PERSON);

    return (
        <ThirdPartyForm>
            <p>
                Doplňte prosím vaše osobní údaje pro zplnomocnění společnosti MND k vyřízení
                převodu {getLabel("meraku")} na nového majitele.
            </p>
            <Formik
                initialValues={{
                    fullName: "",
                    dateOfBirth: "",
                    wholeAddr: "",
                    businessName: "",
                    businessId: "",
                    placeOfSignature: "",
                    thirdPartyRepresented: ""
                }}
                validationSchema={type === PERSON_TYPE_PERSON ? validEmailSchemaPerson : validEmailSchemaBusiness}
                onSubmit={(values) => {
                    saveThirdParty({
                        variables: {
                            "person": {
                                type: type,
                                businessName: values.businessName,
                                businessId: values.businessId,
                                fullName: values.fullName,
                                dateOfBirth: values.dateOfBirth,
                                address: values.wholeAddr,
                                placeOfSignature: values.placeOfSignature,
                                thirdPartyRepresented: values.thirdPartyRepresented
                            },
                        }
                    })
                }}
            >
                {
                    ({
                         handleSubmit,
                         isValid,
                         resetForm,
                         dirty,
                         setFieldValue,
                         values,
                        errors
                     }) => {

                        return <>
                            <Filter type={type} setType={setType} resetForm={resetForm}/>
                            <MainForm
                                type={type}
                                handleSubmit={handleSubmit}
                                isValid={isValid}
                                dirty={dirty}
                                mutationLoading={mutationLoading}
                                setFieldValue={setFieldValue}
                            />
                        </>
                    }
                }
            </Formik>
        </ThirdPartyForm>
    );
};

export default ThirdParty;
