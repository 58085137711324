import React, {useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {useMutation} from '@apollo/react-hooks';

import SaveMessage from "../components/SaveMessage";
import {SideBox as SideBoxStyle, SideBoxContainer as SideBoxContainerStyle} from "../style/sideBox";
import SaveIcon from "../style/icons/save";
import {Link} from "../style/link";
import ArrowIcon from "../style/icons/arrow";
import theme from "../style/theme";
import SAVE_CONTRACT from "../graphql/mutations/saveContract";
import {ERROR_ROUTE} from "../configs/routes";
import {
    Redirect
} from "react-router-dom";

const SideBoxOwner = ({
                          savingEnabled = true,
                          name,
                          surname,
                          email,
                          phone,
                          street,
                          streetNumber,
                          zipCode,
                          city,
                          houseNumber,
                          mt,
                          showTitle,
                          top,
                          isMessageOpen,
                          setMessage,
                      }) => {
    const isMobileScreen = useMediaQuery({query: `(max-width: ${theme.breakpoints.mobile})`});
    const [isBoxOpen, setBox] = useState(true);


    useEffect(() => {
        setBox(!isMobileScreen);
    }, [isMobileScreen]);

    const handleSaveContract = () => {
        setMessage(true);
        saveContract({
            variables: {
                "url": window.location.href
            }
        });
    };

    const [
        saveContract,
        {error: saveContractError},
    ] = useMutation(SAVE_CONTRACT, {});

    if (saveContractError) return <Redirect to={`/${ERROR_ROUTE}`}/>;

    return (
        <SideBoxContainerStyle mt={mt} top={top} showTitle={showTitle}>
            <div className="header">
                {showTitle && <p>Nový majitel</p>}
                {isMobileScreen && savingEnabled &&
                <Link
                    className="save" onClick={() => handleSaveContract()}><SaveIcon/>Uložit a dokončit později</Link>}
            </div>
            <SideBoxStyle isOpen={isBoxOpen}>
                <div className="mainLine">
                    <div>
                        <p className="sub-label">Adresa:</p>
                        <p className="label">{street}&nbsp;{houseNumber}{streetNumber ? `/${streetNumber}` : ''}<br/>
                            {zipCode ? `${zipCode.substr(0, 3)} ${zipCode.substr(3, 2)}` : ""} {city}
                        </p>
                    </div>

                    {(isMobileScreen) && <button onClick={() => setBox(!isBoxOpen)}>
                        <ArrowIcon/>
                    </button>}
                </div>
                <div className="info">
                    <hr/>
                    <div className="owner-info"><p>Jméno:</p> <strong>{name} {surname}</strong></div>
                    <div className="owner-info"><p>E-mail:</p> <strong>{email}</strong></div>
                    <div className="owner-info"><p>Mobilní telefon:</p> <strong>{phone}</strong></div>
                </div>
            </SideBoxStyle>

            {(!isMobileScreen && savingEnabled) &&
            <Link
                className="save" onClick={() => handleSaveContract()}><SaveIcon/>Uložit a dokončit později</Link>}
            <SaveMessage isOpen={isMessageOpen} setMessage={setMessage}/>
        </SideBoxContainerStyle>
    );
};

export default SideBoxOwner;
