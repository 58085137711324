import styled from "styled-components";

export const DocumentsThanks = styled.div`   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    img {
        margin-top: 30px;
        width: 100%;
        
        @media screen and (min-width: 400px) {
            max-width: 400px;
        } 
    }
    
    h1 {
        text-align: center;
        font-size: 26px;
        line-height: 36px;
        color: ${props => props.theme.colors.exploratoryGreen};    
        margin-bottom: 20px;
    
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            line-height: 49px;
            font-size: 36px;
            margin-bottom: 35px;
        }    
    }
    
    p {
        color: ${props => props.theme.colors.expertiseGray};
        text-align: center;
        max-width: 617px;
        font-size: 16px; 
        margin: 0;
      
        &:not(:last-of-type) {
            margin-bottom: 1rem;
        }
    }
    
    hr {
        max-width: 617px;
    }
`;
