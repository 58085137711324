import styled from "styled-components";

export const UploadSection = styled.section`
    display: flex;
    position: relative;
    flex-direction: column;
    border-bottom: ${props => props.type === "simple" ? 0 : "1px"} solid ${props => props.theme.colors.grey};
    margin-top: 10px;
    padding-bottom: ${props => props.type === "simple" ? 0 : "25px"};
    padding-left: 0;
    margin-bottom: ${props => props.type === "simple" ? 0 : "10px"};
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        margin-top: 15px;
        padding-left: ${props => props.type === "simple" ? "30px" : props.type === "poa" ? 0 : "45px"};
        margin-bottom: ${props => props.type === "simple" ? 0 : "20px"};
    }
    
    & > h3 {
        height: auto;
        line-height: 25px;
        font-size: 18px;
        color: ${props => props.theme.colors.resourceGreen};
        margin-bottom: 10px;
        padding-left: 30px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            font-size: 20px;
            min-height: 32px;
            line-height: 32px;
            padding-left: 0;
        }
    }
    
    & > p {
        font-size: 14px; 
        margin-bottom: 10px;  
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            margin-bottom: 20px;
        } 
    }
    
    & > a {
        margin-top: 24px;
        width: 100%;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            width: auto;
        }
    }
    
    .letter {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0;
        top: 0;
        font-size: 17px;
        line-height: 24px;
        color: ${props => props.theme.colors.white};
        border-radius: 16px;
        background: ${props => props.theme.colors.resourceGreen};
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            width: 32px;
            height: 32px;
            font-size: 20px;
            line-height: normal;
        }
    }
    
    .desc {
        max-width: 100%;

        p:empty {
            margin-bottom: 0;
        }
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            max-width: 75%;
        }
        
        strong {
            margin-bottom: 10px;
            display: inline-block;
        }
      
        .label {
          display: flex;
          align-items: center;

          min-height: 32px;
          
          & > div {
            padding-left: 8px;
          }
          
          @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            flex-direction: row;
          }
          
          strong {
            margin-bottom: 0;
            color: ${props => props.theme.colors.exploratoryGreen}; ;
          }
        } 
        
    }
    
    .wrapper {
        display: flex;
        flex-direction: column-reverse;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
            flex-direction: row;
        }
        
        .upload {
            width: 100%;
            display: flex;
            flex-direction: column;
            
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                width: 350px;
            }

            &--wider {
              @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                  width: 480px;
              }
            }
            
            button {
                width: 100%;
                min-height: 40px;
                color: ${props => props.theme.colors.expertiseGray};
                
                &:hover {
                    text-decoration: none;
                }
            }
        }
        
        .example {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 20px;
            
            @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
                padding-left: 60px;
                padding-bottom: 40px;
            }
        }
    }
    
    .error {
        text-align: center;
        width: 100%;
        padding: 10px;
        margin: 0;
        color: ${props => props.theme.colors.errorRed};

        &:empty {
            padding: 0;
        }
    }
`;
