import styled from "styled-components";

import {Wrapper} from "../style/grid"

export const Message = styled(Wrapper)`
    position: fixed;
    z-index: 999;
    top: ${props => props.isOpen ? "0" : "-500px"};
    left: 0;
    right: 0;
    height: auto;
    padding: 0 15px;
    background: linear-gradient(90deg, ${props => props.theme.colors.resourceGreen}, ${props => props.theme.colors.exploratoryGreen});
    transition: top ${props => props.theme.times.defaultAnimation};
    
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 15px 0;
        height: 100%;
        
        .buttons {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            padding-bottom: 5px;
            
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                flex-direction: row;
                align-items: center;
            }
            
            .update-button {
                color: ${props => props.theme.colors.exploratoryGreen};
            
                svg {
                    margin-right: 25px;
                }
                
                &:hover {
                    background-color: ${props => props.theme.colors.grey};
                } 
            }
            
            .close-button {
                color: ${props => props.theme.colors.white};
                font-size: 14px;
                width: auto;
                min-height: auto;
                padding: 0;
                cursor: pointer;
                text-decoration: underline;
                
                @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                    align-self: center;
                }
                
                &:focus {
                    outline: none;
                }
                
                &:hover {
                    text-decoration: none;
                }
            }   
        }
        
        button:nth-of-type(2) {
            margin-top: 15px;
        
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                margin-top: 0;
                margin-left: 20px;
            }
        }
    }
    
    h3 {
        font-weight: 600;
        font-size: 20px;
        color: ${props => props.theme.colors.white};
        margin-bottom: 5px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            font-size: 26px;
        }
    }
    
    p {
        color: ${props => props.theme.colors.white};
        font-size: 15px;
        margin-bottom: 10px;
    }
`;
