import styled from "styled-components";

const LoadingIcon = styled.div`
	position: relative;
    width: 200px;
    min-height: 115px;
    margin: 30px auto;
    background: ${props => props.simple ? `url("../images/loading-simple.gif") no-repeat 50% 50%` : `url("../images/loading.gif") no-repeat 50% 50%`};
    
	span {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		text-align: center;
		font-weight: 600;
		color: #70b214;
	}	
`;

LoadingIcon.displayName = "LoadingIcon";

LoadingIcon.defaultProps = {};

export default LoadingIcon;
