const SIGN_CONTRACT_AND_WARRANT = "sign_contract_and_warrant";
const SIGN_THIRDPARTY_POWER_OF_ATTORNEY = "sign_third_party_power_of_attorney";
const DELIVER_STANDARD_DOCUMENTS = "deliver_standard_documents";
const CUSTOM = "custom";

export {
    SIGN_CONTRACT_AND_WARRANT,
    SIGN_THIRDPARTY_POWER_OF_ATTORNEY,
    DELIVER_STANDARD_DOCUMENTS,
    CUSTOM
};