import {PDF_FILE_TYPE, PREVIEW_FILE_TYPES} from "../configs/index";
import {DEFAULT_PREVIEW} from "../configs";

export default (fileType, filePreview) => {
    if (PREVIEW_FILE_TYPES.indexOf(fileType) > -1) {
        return {
            filePreview: filePreview,
            filePreviewType: "modal",
            fileType: "image",
            fileBackground: filePreview
        };
    } else if (fileType === PDF_FILE_TYPE) {
        return {
            filePreview: filePreview,
            filePreviewType: "modal",
            fileType: "pdf",
            fileBackground: DEFAULT_PREVIEW
        };
    }

    return {
        filePreview: "",
        filePreviewType: null,
        fileType: "default",
        fileBackground: DEFAULT_PREVIEW
    };
};
