import styled from "styled-components";

export const Wrapper = styled.section`
    width: 100%;
    background-color: ${props => props.theme.colors.grey};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 60px;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        width: 96%;
    }
    
    @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
        width: ${props => props.theme.sizes.container};
    }
`;

export const Inner = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${props => props.pt ? props.pt : 0};
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

export const WhiteBox = styled.div`
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    padding: 15px 15px 30px 15px;
    margin: 0;
    display: flex;
    flex-direction: column;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        padding: 45px 30px;
        margin: 20px 0 0;
        min-height: 800px;
    }
    
    @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
        width: ${props => props.theme.sizes.container};
    }
`;

export const WhiteBoxError = styled(WhiteBox)`    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        min-height: auto;
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: ${props => props.px ? props.px : 0} 0;
    
    a {
        align-self: center;
    }
    
    button {
        align-self: center;
        margin-left: 10px;
        min-width: auto;
        flex-grow: 1;
            
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            transform: translateX(-40px);
            margin-left: auto;
            margin-right: auto;
            flex-grow: 0;
            min-width: 230px;
        }
    }
`;

export const ButtonWrapperDocuments = styled.div`
    margin-top: 25px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    button {
        padding: 3px 20px 0 20px;
    
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            padding: 3px 45px 0 45px;
        }
        
        &:nth-of-type(2) {
            margin-left: 20px;
            margin-top: 15px;
        }
    }
        
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        margin-top: 0;
        margin-bottom: 0;
    }
    
    @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
        flex-direction: row;   
        
        button:nth-of-type(2) {
            margin-top: 0;
        } 
    }
`;




