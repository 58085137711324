import React from "react";

import {Message} from "../style/message";
import {Container} from "../style/grid";
import {Button} from "../style/button";

const SaveMessage = ({isOpen, setMessage}) => <Message as="div" isOpen={isOpen}>
    <Container>
        <h3>Aktuální stav byl uložen.</h3>
        <p>Odkaz pro pokračování později jsme Vám odeslali do e-mailu.</p>
        <div className="buttons">
            <Button onClick={() => setMessage(false)} variant="link" className="close-button">Beru na vědomí</Button>
        </div>
    </Container>
</Message>;

export default SaveMessage;
