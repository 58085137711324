import React from 'react';
import RadioInputSection from './RadioInputSection';
import CheckboxInputSection from './CheckboxInputSection';

const VariableInputSection = ( {title, isRequired = false, inputName, inputs, values, handleChange, errors, globalCheckboxState, setGlobalCheckboxState } ) => {

    const inputsJSX = isRequired ?
        <RadioInputSection values={values} handleChange={handleChange} errors={errors} radioInputName={inputName} radioInputs={inputs} />
        :
        <CheckboxInputSection
            values={values}
            handleChange={handleChange}
            errors={errors}
            checkboxInputName={inputName}
            checkboxInputs={inputs}
            globalCheckboxState={globalCheckboxState}
            setGlobalCheckboxState={setGlobalCheckboxState}
        />
        

    return (
        <div className='section'>
            <h3>{title}</h3>
            {inputsJSX}
        </div>
    )
};

export default VariableInputSection;