import React from "react";
import {Switch, Route, Link} from "react-router-dom";
import AnimateHeight from 'react-animate-height';

import {AccordionItem as AccordonItemStyle, AccordionBody, AccordionHead} from "../style/accordion";
import TickIcon from "../style/icons/tick";
import {Button} from "../style/button";
import Document from "./Document";
import SignIcon from "../style/icons/sign";
import { SignLink } from "./SignLink";
import { VOP_FILE_URL } from "../configs";

const AccordionItem = ({item, index, route, accordionLength, document, setModal, children, priceListLink, shouldShowLinkForUnverifiedConsent, shouldShowPriceListLink}) => {
    return <AccordonItemStyle>
        <Switch>
            <Route path={`/${route}/:step`} render={({match: {params: {step}}}) => (
                <>
                    <AccordionHead isOpen={parseInt(step) === (index + 1)}>
                        <span className="badge">{index + 1}</span>
                        <h3>{item.heading}</h3>
                        {parseInt(step) > (index + 1) && <div className="completed">
                            <TickIcon/>
                        </div>}
                    </AccordionHead>

                    <AnimateHeight
                        duration={300}
                        height={parseInt(step) === (index + 1) ? "auto" : 1}
                    >
                        <AccordionBody>
                            {(index < accordionLength && !children) && <div>
                                <p className="label">{item.label}</p>
                                <div className="contract-container">
                                    {document && <Document link={document.fileLinkInline} preview={item.documentPreview} linkLabel="Zobrazit náhled v PDF"/>}

                                    <Button as={Link} to={`/${route}/${index + 2}`} align="center" mt="10px">Pokračovat</Button>
                                </div>
                            </div>}
                            {(index === accordionLength && !children) && <div className="sign-container">
                                <img src="../images/contract-icon.svg" alt="Ikona podpisu smlouvy"/>
                                <div className="label-sign-wrapper">
                                    <span>{item.label}</span>
                                    <div className="label-sign-links">
                                        <SignLink link={VOP_FILE_URL} text={`obchodními podmínkami${shouldShowPriceListLink || shouldShowLinkForUnverifiedConsent ? ',' : '.'}`} isPdf />
                                        {shouldShowPriceListLink ? <SignLink link={priceListLink} text={`ceníkem${shouldShowLinkForUnverifiedConsent ? ',' : '.'}`} isPdf /> : null}
                                        {shouldShowLinkForUnverifiedConsent ? <SignLink link="https://www.mnd.cz/osobniudaje" text="ověřením osobních údajů i&nbsp;platební schopnosti." /> : null}
                                    </div>
                                </div>
                                <Button onClick={() => setModal(true)} align="center">
                                    <SignIcon/>
                                    Podepsat dokumenty
                                </Button>
                            </div>}
                            {children && children}
                        </AccordionBody>
                    </AnimateHeight>
                </>
            )}/>
        </Switch>

    </AccordonItemStyle>;
};

export default AccordionItem;



