import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`
    mutation SAVE_THIRD_PARTY($person: ThirdPartyPersonInput!) {
        saveThirdParty(
            person: $person
        ) {
            ...ContractFields
        }
    }
    ${contractFragment}
`;
