import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`
    mutation SEND_POA_SIGNATURE_REQUEST($email: String!) {
        sendPOASignatureRequest(
            email: $email
        ) {
            ...ContractFields
        }
    }
    ${contractFragment}
`;
