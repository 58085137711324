import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`
    mutation REQUEST_TO_SIGN($url: String!) {
    requestToSign(
        url: $url
    ) {
        ...ContractFields
        }
    }
     ${contractFragment}    
`;