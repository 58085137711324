import gql from 'graphql-tag';
import documentFragment from "../fragments/document";

export default gql`
    mutation uploadDocument($file: Upload!, $category: String!) {
        uploadDocument(file: $file, category: $category) {
            ...DocumentFields
        }
        
    }
    ${documentFragment}
`;
