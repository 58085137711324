import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from '@apollo/react-hooks';
import {Redirect} from "react-router-dom";

import {filterAccordionConfig, getAccordionConfig} from "../configs/accordion";
import {DOCUMENT_TYPE_POA} from "../configs";
import {ERROR_ROUTE} from "../configs/routes";

import GET_CONTRACT from "../graphql/queries/getContract";
import APPROVE_SIGNED_DOCUMENTS from "../graphql/mutations/approveSignedDocuments";
import SIGN_DOCUMENTS from "../graphql/mutations/signDocuments";
import {VIEWER_PROCESS_SIGNATURE_PM} from "../configs";

import {Button} from "../style/button";
import {Link} from "../style/link";
import {PageHeader} from "../style/pageHeader";
import {Container, Inner, WhiteBox, Wrapper} from "../style/grid";
import {OnlineSignConfirmation} from "../style/onlineSignConfirmation";

import StepNavigation from "../components/StepNavigation";
import SideBoxRecap from "../components/SideBoxRecap";
import Document from "../components/Document";
import ModalSign from "../components/ModalSign";
import Loading from "../components/Loading";
import getStepsConfig, {SIGN_DOCUMENTS_STAGE} from "../helpers/getStepsConfig";
import { CUSTOM } from "../configs/documentRequestType";
import { getFormatRequestDocuments, logSignatureStepEvent } from "../utils/analytics";

const OnlineSignConfirmationContainer = ({viewer}) => {
    const {loading, error, data} = useQuery(GET_CONTRACT);
    const [modalOpen, setModal] = useState(false);

    useEffect(() => {
        const requestedDocument = getFormatRequestDocuments(data?.contract?.activeDocumentRequest);

        const additionalSignatureEventData = {
            step: 6,
            name: "Kontrola dokumentů",
            type: "Signature",
            text: "Odeslat dokumenty",
            situationType: data?.contract?.situation,
            ...(requestedDocument ? { requestedDocument } : {})
        };

        logSignatureStepEvent(additionalSignatureEventData);
    }, []);

    const [
        approveSignedDocuments,
        { loading: approveSignedDocumentsLoading, error: approveSignedDocumentsError },
    ] = useMutation(APPROVE_SIGNED_DOCUMENTS, {});

    const [
        signDocuments,
        { loading: signDocumentsLoading, error: signDocumentsError },
    ] = useMutation(SIGN_DOCUMENTS, {
        onCompleted() {
            setModal(false);
        }
    });

    if(loading) return <Loading/>;
    if(error || approveSignedDocumentsError || signDocumentsError) return <Redirect to={`/${ERROR_ROUTE}`} />;

    const {contract: {
        products,
        supplyPoints,
        activeDocumentRequest: {
            documents,
            configuration,
        }
    }} = data;
    const documentsWithoutPOA = documents.filter(document => document.category !== DOCUMENT_TYPE_POA);

    const documentsForConfirmation = documentsWithoutPOA
        .filter(doc => filterAccordionConfig(
            getAccordionConfig(), configuration, documents).some(item => item.documentType === doc.category));

    const savingEnabled = !(data.contract?.activeDocumentRequest?.type === CUSTOM);

    return (
        <Wrapper>
            <Container>
                <StepNavigation {...getStepsConfig(data.contract.activeDocumentRequest, {forceStage: 1, forceStageName: SIGN_DOCUMENTS_STAGE})}  />
                <Inner>
                    <WhiteBox>
                        <PageHeader>
                            <h1>Zkontrolujte a odešlete podepsané dokumenty</h1>
                            <p>Podpisy můžete ještě jednou zkontrolovat a případně opravit.</p>
                        </PageHeader>
                        <OnlineSignConfirmation>
                            <div className="documents">
                                {documentsForConfirmation.map(document => <Document
                                    key={document.fileName}
                                    link={document.fileLinkSignedInline}
                                    downloadLink={document.fileLinkSignedInline}
                                    preview={getAccordionConfig().filter(item => item.documentType === document.category)[0].documentPreview}
                                    label={document.fileName }
                                    linkLabel="Zobrazit náhled v PDF"
                                />)}
                            </div>
                            <Link onClick={() => setModal(true)} mt="25px">Opravit podpisy</Link>
                            <Button
                                disabled={approveSignedDocumentsLoading ? "disabled" : ""}
                                onClick={() => {
                                    approveSignedDocuments({
                                        variables: {}
                                    });
                                }}
                                align="center"
                                mt="25px"
                            >{approveSignedDocumentsLoading ? "Načítání..." : "Odeslat dokumenty"}</Button>
                        </OnlineSignConfirmation>
                        <ModalSign
                            modalOpen={modalOpen}
                            setModal={setModal}
                            mutationLoading={signDocumentsLoading}
                            signMutation={signDocuments}
                        />
                    </WhiteBox>
                      { viewer.process !== VIEWER_PROCESS_SIGNATURE_PM && products.map((product, i) =>
                        <SideBoxRecap
                            supplyPoints={supplyPoints}
                            tariffLabel={product.label}
                            tariffName={product.name}
                            consumption={product.consumption}
                            price={product.priceYear}
                            onlineDiscount={product.reduction}
                            savingEnabled={savingEnabled}
                            key={i}
                        />)
                    }
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default OnlineSignConfirmationContainer;
