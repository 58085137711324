import React from "react";

import isMobile from "../helpers/isMobile";
import {Document as DocumentStyle} from "../style/document";

const Document = ({label, link, downloadLink, linkLabel, preview}) => <DocumentStyle>
    <p className="label">{label}</p>
    <a href={link} target={isMobile() ? "_self" : "_blank"} className="image" >
        <img src={preview} alt="Dokument"/>
    </a>
    <a href={downloadLink || link} className="link"
       target={isMobile() ? "_self" : "_blank" } type="application/pdf"
    >
        {linkLabel}
    </a>
</DocumentStyle>;


export default Document;
