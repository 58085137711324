import gql from 'graphql-tag';

export default gql`
    fragment ProductFields on Product {
            name
            consumption
            consumptionLt
            consumptionHt
            priceYear
            reduction
    }
`;
