const {COMMODITY_GAS, COMMODITY_ELE} = require("../configs/commodity");

let commodityKey = 0;

const labels = {
    komodita: ["Plyn", "Elektřina", "Výkup elektřiny"],
    komoditu: ["Plyn", "Elektřinu", "Výkupu elektřiny"],
    meraku: ["Plynoměru", "Elektroměru", "Výkupu elektřiny"],
    merak: ["Plynoměr", "Elektroměr", "Výkup elektřiny"],
}

export const setLabelCommodity = (labelCommodity) => commodityKey = (labelCommodity === COMMODITY_GAS ? 0 : labelCommodity === COMMODITY_ELE ? 1 : 2);

export const getLabel = (key, upperCase = false) => {
    if(typeof labels[key] !== "undefined") {
        return upperCase ? labels[key][commodityKey] : labels[key][commodityKey].toLowerCase();
    }
    return `*${key}`;
}