import React, {useState, useEffect} from "react";
import Modal from "react-modal";
import classnames from "classnames";

import SignaturePad from "./SignaturePad";
import {ModalSign as ModalSignStyle} from "../style/modal";

const ModalSign = ({modalOpen, setModal, signMutation, mutationLoading}) => {
    const [orientation, setOrientation] = useState(null);

    useEffect(() => {
        function handleOrientation() {
            let width = window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;

            let height = window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight;

            setOrientation(width > height ? "landscape" : "portrait");
        }

        window.addEventListener("resize", handleOrientation);
        handleOrientation();

        return function cleanup() {
            window.removeEventListener("resize", handleOrientation);
        };
    }, []);

    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={() => setModal(false)}
            contentLabel="Podpis"
            className={classnames({
                "modal": true,
                "landscape": orientation === "landscape",
                "portrait": orientation === "portrait"
            })}
            overlayClassName="modalOverlay"
            shouldCloseOnOverlayClick={false}
        >
            <ModalSignStyle>
                <div className="inner">
                    <span className="modalClose" onClick={() => setModal(false)}>Zavřít</span>
                    <div className="modalContent">
                        <h2 className="onlineSignHeading">On-line podpis smlouvy</h2>
                        <h3>Podepište se prosím</h3>
                        <p>Podepište se stejným podpisem jako na papírových dokumentech, tahem myši se zmáčknutým levým tlačítkem.</p>
                        <p className="mobile-text">Podepište se prstem, stejným podpisem jako na papírových dokumentech.</p>
                        <SignaturePad
                            signMutation={signMutation}
                            orientation={orientation}
                            mutationLoading={mutationLoading}
                        />
                    </div>
                </div>
            </ModalSignStyle>
        </Modal>
    );
};

export default ModalSign;
