import styled from "styled-components";

export const ExampleDocument = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .image {
        width: 50px;
        position: relative;
        display: inline-block;
		border: 2px solid ${props => props.theme.colors.resourceGreen};
		
		@media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            width: 60px;
        }
		
		img {
		    display: block;
		    width: 100%;
		    margin: 0;
		}
		
		span {
		    position: absolute;
		    left: -14px;
		    top: 50%;
		    transform: translateY(-50%);
		    width: 143px;
		    background: ${props => props.theme.colors.resourceGreen};
		    height: 20px;
		    font-size: 14px;
		    color: ${props => props.theme.colors.white};
		    text-decoration: none;
		    border-radius: 4px;
		}	
    }
    
    .textRow {
        padding-left: 13px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
    
        span, p {
            text-transform: uppercase;
            font-size: 15px;
            color: ${props => props.theme.colors.exploratoryGreen};
            font-weight: bold;
            margin: 0;
        }
        
        p {
            text-transform: none;
            padding-left: 0;
        }
        
        a {
            margin-top: 5px;
            font-size: 14px;
            color: ${props => props.theme.colors.exploratoryGreen};
            display: flex;
            align-items: center;
            
            svg {
                margin-right: 8px;
            }
            
            &:hover {
                text-decoration: none;
            }
        }
    }
`;
