import styled from "styled-components";

export const Radio = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 14px;
    }

    p {
        margin-bottom: 0;
        padding-left: 30px;
    }
    
    label {
        position: relative;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        display: flex;       
        flex-direction: column;
        align-items: flex-start;
        color: ${props => props.theme.colors.expertiseGray}; 
        font-weight: 600;
        padding-left: 30px;
        margin-bottom: 6px;
        
        &.checked {
            color: ${props => props.theme.colors.exploratoryGreen};
        }
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            flex-direction: row;
            align-items: center;
        }
        
        &:hover > input ~ span {
            background: ${props => props.theme.colors.white};
            transition: background-color ${props => props.theme.times.defaultAnimation};
        }
        
        & > input:checked ~ span {    
            border: 2px solid ${props => props.theme.colors.exploratoryGreen}; 
            background: ${props => props.theme.colors.white};
        }
        
        & > input:checked ~ span:after {
            content: "";
            display: block;
            width: 11px;
            height: 11px;
            background: ${props => props.theme.colors.exploratoryGreen}; 
            border-radius: 15px;
            position: absolute;
            top: 3px;
            left: 3px;
        } 
          
        & > input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        
        & > span {
            position: absolute;
            top: -2px;
            left: 0;
            height: 21px;
            width: 21px;
            border-radius: 15px;
            border: 2px solid ${props => props.theme.colors.expertiseGray};
            background: ${props => props.theme.colors.grey};
            transition: background-color ${props => props.theme.times.defaultAnimation};
        }
    }

    &.sub-situation {
        label {
            font-size: 16px;
            line-height: 24px;
            
            margin-bottom: 0;
        }
    }
`;
