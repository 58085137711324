import styled from "styled-components";
import theme from "./theme";

const getColor = (props) => {
    if (props.isDragAccept) {
        return theme.colors.exploratoryGreen;
    }

    if (props.isDragReject) {
        return theme.colors.errorRed;
    }

    if (props.isDragActive) {
        return theme.colors.exploratoryGreen;
    }

    return theme.colors.darkGrey;
};

export const StyledDropzone = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 15px;
    border-width: 1px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: ${theme.colors.lightGrey};
    color: ${theme.colors.exploratoryGreen};
    outline: none;
    min-height: 88px;
    font-size: 14px;
    transition: border .24s ease-in-out;
    width: 100%;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        min-height: 150px;
        justify-content: flex-start;
    }
    
    div {
        margin-right: 24px;
        margin-bottom: 3px;
    }
    
    div.loading {
        margin: auto;
    }
    
    &>span {
        width: 100%;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-decoration: underline;
        align-self: center;
        margin: 20px auto;
        
        &:hover {
            text-decoration: none;
        }
    }
    
    svg {
        margin-right: 10px;
    }
`;
