import styled from "styled-components";

export const Checkbox = styled.div`
    margin-bottom: 14px;
    
    p {
        margin-bottom: 0;
        padding-left: 30px;
    }
    
    label {
        position: relative;
        cursor: pointer;
        font-size: 14px;
        display: flex;       
        flex-direction: column;
        align-items: flex-start;
        color: ${props => props.theme.colors.expertiseGray}; 
        font-weight: 600;
        padding-left: 30px;
        &.checked {
          color: ${props => props.theme.colors.exploratoryGreen}
        }
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            flex-direction: row;
            align-items: center;
        }
        
        &:hover > input ~ span {
            background: ${props => props.theme.colors.white};
            transition: background-color ${props => props.theme.times.defaultAnimation};
        }

	      
        
        & > input:checked ~ span {    
            border: 2px solid ${props => props.theme.colors.exploratoryGreen}; 
            background: ${props => props.theme.colors.white};
        }
        
        & > input:checked ~ span:after {
            content: "";
            display: block;
            width: 17px;
            height: 17px;
            background: url("../images/check.svg") no-repeat center / contain, ${props => props.theme.colors.white};

            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
        } 
          
        & > input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        
        & > span {
            position: absolute;
            left: 0;
            top: 0;
            height: 21px;
            width: 21px;
            border: 2px solid ${props => props.theme.colors.expertiseGray};
            background: ${props => props.theme.colors.grey};
            transition: background-color ${props => props.theme.times.defaultAnimation};
        }
    }
`;
