import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from '@apollo/react-hooks';
import {Redirect, useHistory} from "react-router-dom";

import {
    ERROR_ROUTE,
    getThirdPartyDataCompletedRoute,
    getThirdPartyOnlineSignConfirmationRoute,
    ONLINE_SIGN_POA_ROUTE,
    signatureStates
} from "../configs/routes";
import AccordionItem from "../components/AccordionItem";
import ModalSign from "../components/ModalSign";
import Loading from "../components/Loading";
import {getPoaAccordionConfig} from "../configs/accordion";
import ThirdPartyForm from "../components/ThirdPartyForm";
import GET_CONTRACT from "../graphql/queries/getContract";
import SAVE_THIRD_PARTY from "../graphql/mutations/saveThirdParty";
import SIGN_DOCUMENTS from "../graphql/mutations/signDocuments";

import {Container, Inner, WhiteBox, Wrapper} from "../style/grid";
import {PageHeader} from "../style/pageHeader";
import {getLabel} from "../helpers/getLabel";
import SEND_POA_SIGNATURE_REQUEST from "../graphql/mutations/sendPoaSignatureRequest";
import SideBoxSupplyPoint from "../components/SideBoxSupplyPoint";
import { logPoaEvent } from "../utils/analytics";


const getStepByMatch = match => match.path.substring(match.path.length-1);

const OnlineSignPoaContainer = ({match}) => {
    const [modalOpen, setModal] = useState(false);
    const history = useHistory();
    const {loading, error, data} = useQuery(GET_CONTRACT);
    const [
        saveThirdParty,
        { loading: saveThirdPartyLoading, error: saveThirdPartyError },
    ] = useMutation(SAVE_THIRD_PARTY, {
        onCompleted() {
            history.push(getThirdPartyDataCompletedRoute());
        }
    });
    const [
        signPOA,
        { loading: signPOALoading, error: signPOAError },
    ] = useMutation(SIGN_DOCUMENTS, {
        onCompleted() {
            history.push(getThirdPartyOnlineSignConfirmationRoute());
        }
    });

    const [requestToSignPOA, {loading: poaLoading}] = useMutation(SEND_POA_SIGNATURE_REQUEST, {});

    useEffect(() => {
        const additionalPodEventData = {
            step: getStepByMatch(match),
            name: "Dokumenty",
            text: "Pokračovat"
        };

        logPoaEvent(additionalPodEventData);
    }, []);

    useEffect(() => {
        if(data?.contract?.onlinePoa?.state === signatureStates.NONE && data?.contract?.onlinePoa?.newOwner?.email){
            requestToSignPOA({
                    variables: {
                        "email": data?.contract?.onlinePoa?.newOwner?.email // v pripade ze jeste neni spsuteny, je to varianta zaslu sam, takze mail posilame na noveho vlastnika
                    }
                }
            )
        }
    }, [data?.contract?.onlinePoa?.newOwner?.email]); // eslint-disable-line

    if(loading || poaLoading) return <Loading/>;
    if(error || saveThirdPartyError || signPOAError) return <Redirect to={`/${ERROR_ROUTE}`} />;

    const {contract: {situation, activeDocumentRequest: {documents}, person: newOwner}} = data;
    const accordion = getPoaAccordionConfig();
    return (
        <Wrapper>
            <Container>
                <Inner pt="10px">
                    <WhiteBox>
                        <PageHeader>
                            <h1>Doplňte vaše údaje a podepište<br/>plnou moc pro přepis {getLabel("meraku")}</h1>
                        </PageHeader>
                        <div>
                            {accordion.map((item, index) =>
                                <AccordionItem
                                    key={index}
                                    item={item}
                                    index={index}
                                    route={ONLINE_SIGN_POA_ROUTE}
                                    accordionLength={accordion.length - 1}
                                    document={documents.filter(document => document.category === item.documentType)[0]}
                                    setModal={setModal}
                                >
                                    {index === 0 && <div className="contract-container">
                                        <ThirdPartyForm
                                            saveThirdParty={saveThirdParty}
                                            mutationLoading={saveThirdPartyLoading}
                                            oldOwner={data?.contract?.activeDocumentRequest?.onlinePoa?.oldOwner}
                                        />
                                    </div>}
                                </AccordionItem>
                            )}
                        </div>
                        <ModalSign
                            modalOpen={modalOpen}
                            setModal={setModal}
                            mutationLoading={signPOALoading}
                            signMutation={signPOA}
                        />
                    </WhiteBox>
                    <SideBoxSupplyPoint
                        supplyPoints={data.contract?.supplyPoints}
                        top="0"
                    />
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default OnlineSignPoaContainer;
