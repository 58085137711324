import React, {useCallback, useRef, useState} from "react";
import useToggle from "../hooks/useToggle";

import documentsCategory from "../configs/documentsCategory";
import getDocumentPreview from "../helpers/getDocumentPreview";
import isMobile from "../helpers/isMobile";
import Dropzone from "./Dropzone";
import ExampleDocument from "./ExampleDocument";

import {Checkbox} from "../style/checkbox";

import {Button} from "../style/button";
import {UploadSection} from "../style/uploadSection";
import CameraIcon from "../style/icons/camera";

import GET_CONTRACT from "../graphql/queries/getContract";

export const UploadDocuments = ({uploadDocument, uploadDocumentLoading, configuration, contract, documents, letter, setModal, setModalPreview, deleteDocument, type = "normal", setMandatory}) => {
    const [errorMessage, setErrorMessage] = useState(false);
    const dropzoneRef = useRef(null);

    const configData = configuration.customConfigData ? configuration.customConfigData : documentsCategory[configuration.category];

    if(configData && !!configData.exampleFile && configuration.sampleDocuments.length > 0){
        configData.exampleFile.link = configuration.sampleDocuments[0]
    }

    const {status: expanded, toggleStatus: toggleExpanded} = useToggle();

    const onDrop = useCallback((files) => {
        files.forEach(file => {
            const preview = getDocumentPreview(file.type, URL.createObjectURL(file));
            uploadDocument({
                variables: {
                    file,
                    category: configuration.category
                },
                optimisticResponse: {
                    __typename: "Mutation",
                    uploadDocument: {
                        __typename: "Document",
                        id: `loading-${Math.random()}`,
                        isDraft: true,
                        fileLink: preview.filePreview,
                        fileLinkInline: preview.filePreview,
                        fileLinkSigned: null,
                        fileLinkSignedInline: null,
                        fileName: file.name,
                        signed: false,
                        category: configuration.category,
                        mimetype: file.type
                    }
                },
                update: (proxy, {data: {uploadDocument}}) => {
                    const data = proxy.readQuery({query: GET_CONTRACT});
                    proxy.writeQuery({
                        query: GET_CONTRACT,
                        data: {
                            contract: {
                                ...data.contract,
                                documents: [
                                    ...data.contract.documents,
                                    uploadDocument
                                ]
                            }
                        }
                    });
                }
            });
        });
    }, [uploadDocument]); // eslint-disable-line

    return (
        <UploadSection type={type} key={configuration.category}>
            {type !== "simple" && (
                <>
                    {letter && <span className="letter">{letter}</span>}
                    <h3>{configData?.title} {configData.tooltip && <Button variant={"info"}>i
                        <div className={"tooltip"}>{configData.tooltip}</div>
                    </Button>}</h3>
                    <div className="desc">
                        <p>{configData.description}</p>
                    </div>
                </>
            )}
            {configData.mandatorySetting && documents.length < 1 &&
            <Checkbox key={"death_certificate"}>
                <label key={"death_certificate"}>
                    <input type="checkbox"
                           key={"death_certificate"}
                           name={`${configuration.category}`}
                           onChange={e => {
                               toggleExpanded(e);
                               setMandatory(configuration.category)
                           }}
                           value={`${configuration.category}`}
                    />
                    {configData.mandatorySettingText}
                    <span/>
                </label>
            </Checkbox>
            }
            {expanded &&
            <div className="wrapper">
                <div className="upload">
                    <Dropzone
                        dropzoneRef={dropzoneRef}
                        onDrop={onDrop}
                        contract={contract}
                        uploadDescription={configData.uploadDescription}
                        uploadMoreDescription={configData.uploadMoreDescription}
                        documents={documents}
                        setErrorMessage={setErrorMessage}
                        setModalPreview={setModalPreview}
                        uploadDocumentLoading={uploadDocumentLoading}
                        deleteDocument={deleteDocument}
                    />
                    <p className="error">{errorMessage}</p>
                    <Button
                        variant="link"
                        onClick={() => {
                            if (isMobile()) {
                                dropzoneRef.current.click();
                            } else {
                                setModal(true);
                            }
                        }}
                        data-analytics="click"
                        data-name="SwitchDevice"
                        data-type="Link"
                        data-area="Page"
                    >
                        <CameraIcon/>
                        {isMobile() ? "Použít kameru" : "Přejít na telefon s fotoaparátem"}
                    </Button>
                </div>
                {configData.exampleFile?.link && <div className="example">
                    <ExampleDocument
                        link={configData.exampleFile.link}
                        title={configData.exampleFile.title}
                        label={configData.exampleFile.label}
                        preview={configData.exampleFile.preview}
                    />
                </div>}
            </div>
            }
        </UploadSection>
    );
};

export default UploadDocuments;
