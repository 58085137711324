import styled from "styled-components";
import {Wrapper} from "../style/grid";

export const Header = styled(Wrapper)`
    background-color: ${props => props.theme.colors.white};
    text-align: center;
    
    & > div {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 94px;
        }
        
        @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
            padding: 0;
        }     
    }
    
    .mobileContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .logo {
        width: 94px;
        margin: 0;
    
        a, img {
            width: 100%;
            display: block;
            margin: 0;
        }
    
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            width: 140px;
        }
    }
    
    .text {
        width: 100%;
        color: ${props => props.theme.colors.grey};
        margin: 23px 0 0 0;
        text-align: left;
        font-size: 14px;
    
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            font-size: 16px;
            text-align: center;
            margin: 0;
            width: auto;
        }
    }
    
    .hotline {
        font-weight: 600;
        color: ${props => props.theme.colors.exploratoryGreen};
        margin: 0;
        font-size: 16px;
    
        svg {
            position: relative;
            left: -7px;
            top: 2px;
        }
    }
`;
