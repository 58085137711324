import styled from "styled-components";

export const ThirdPartyForm = styled.div`
    p {
        font-size: 15px;
        margin-top: 15px;
    }
   
    .formFields { 
        label {
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                width: calc(100% / 3.5);
            }
            
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                width: calc(100% / 3);
            }
        }
        
        input {        
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                width: calc(100% / 2);
                max-width: 100%;
            }
            
            @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
                width: calc(100% / 2.5);
            }
        }
        
        .dateField {  
            & > div {
                width: 100%;
            
                @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                    width: calc(100% / 2);
                    max-width: 100%;
                }
                
                @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
                    width: calc(100% / 2.5);
                }
            }
            
            input {        
                width: 100%;
            }         
        }
    }
    
    .radioFilter {
        display: flex;
        margin-bottom: 15px;
        flex-direction: column;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            padding-left: calc(100% / 3.5);
            flex-direction: row;
        }
        
        label:nth-of-type(1) {
            margin-right: 15px;
        }
    }
    
    .buttonContainer {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        width: 100%;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            padding-top: 30px;
            margin-left: calc(100% / 3.5);
            width: calc(100% / 2);
        }
        
        @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
            width: calc(100% / 2.5);
        }
    }
`;
