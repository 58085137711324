import styled from "styled-components";
import {Wrapper} from "./grid";

export const Footer = styled(Wrapper)`
    background-color: ${props => props.theme.colors.expertiseGray};
    position: relative;
    min-height: 120px;
    margin-top: auto;
    
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
        min-height: 275px;
    }
    
    a, .button-link {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        color: white;
        text-decoration: none;
        color: white;
    }
    
    a:hover, .button-link:hover {
        color: rgb(214, 214, 214);
    }
    
    .button-link {
        padding: 0;
        min-height: 0;
    }
    
     
    .logo {
        position: absolute;
        bottom: -1px;
        left: 0;
        display: block;
        margin: 0;
        width: 265px;
        z-index: 1;
        pointer-events: none;
    }
    .copy {
        color: ${props => props.theme.colors.professionalGray};
        font-size: 14px;
        line-height: 20px;
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
           margin: 0 20px;
        }
    }
    
    button svg {
        margin: 0 10px 7px 0
    }
    svg {
        display: inline-block;
        margin: 0 10px 0 0
    }
    
    ul {
        margin: 26px 0;
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
           margin: 20px;
        }
    }
    
    li {
        list-style: none;
        
    }
    
    
    
`;
