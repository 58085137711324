import styled from "styled-components";
import {PageHeader} from "./pageHeader";

export const PageHeaderDocuments = styled(PageHeader)`  
    ${props => props.variant === "border" && `h2 {
        border-bottom: 1px solid ${props.theme.colors.grey};
        border-top: 1px solid ${props.theme.colors.grey};
        padding: 20px 0;
        margin-bottom: 10px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            padding: 25px 0;
            margin-bottom: 0;
        }
    }`}

    
    
    ${props => props.variant === "bottomBorder" && `
        border-bottom: 1px solid ${props.theme.colors.grey};
    `}
  
    p {
        text-align: left;
        margin-top: 15px;
        font-size: 15px;
        margin-bottom: 8px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            margin-top: 22px;
        }
    }
    
    .no-mobile {
        display: none;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
            display: block;
        }
    }

    .no-small-mobile {
        display: none;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.smallMobile}) {
            display: block;
        }
    }
    
    .bold {
        font-weight: bold;
        margin: 20px 0 30px 0;
    }
`;
