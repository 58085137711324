import {UploadSection} from "../style/uploadSection";
import React from "react";
import {InfoBoxPoa} from "../style/infoBoxPoa";
import {StyledDropzone} from "../style/styledDropzone";
import UploadedDocument from "./UploadedDocument";
import {
    DEFAULT_PREVIEW_CONTRACT,
    DEFAULT_PREVIEW_POA_MND,
    DOCUMENT_TYPE_CONTRACT_MND,
    DOCUMENT_TYPE_POA_CEZ,
    DOCUMENT_TYPE_POA_MND
} from "../configs";
import {signatureStates} from "../configs/routes";

const editDocuments = (oldDocuments => {
    return oldDocuments
            .filter(document =>
                [DOCUMENT_TYPE_POA_MND, DOCUMENT_TYPE_CONTRACT_MND, DOCUMENT_TYPE_POA_CEZ]
                    .indexOf(document.category) > -1)
            .filter(document => document.signed)
            .map(document => {
                switch (document.category) {
                    case DOCUMENT_TYPE_POA_MND:
                        return Object.assign({
                            type: DOCUMENT_TYPE_POA_MND,
                            overlayLabel: "Podepsaná plná moc",
                            preview: {
                                filePreview: document.fileLink,
                                filePreviewType: "modal",
                                fileType: "pdf",
                                fileBackground: DEFAULT_PREVIEW_POA_MND
                            }
                        }, document)
                    case DOCUMENT_TYPE_CONTRACT_MND:
                        return Object.assign({
                            type: DOCUMENT_TYPE_CONTRACT_MND,
                            overlayLabel: "Podepsaná smlouva",
                            preview: {
                                filePreview: document.fileLink,
                                filePreviewType: "modal",
                                fileType: "pdf",
                                fileBackground: DEFAULT_PREVIEW_CONTRACT
                            }
                        }, document)
                    case DOCUMENT_TYPE_POA_CEZ:
                        return Object.assign({
                            type: DOCUMENT_TYPE_POA_CEZ,
                            overlayLabel: "Plná moc ČEZ",
                            preview: {
                                filePreview: document.fileLink,
                                filePreviewType: "modal",
                                fileType: "pdf",
                                fileBackground: "/images/zop/cez_poa.jpg"
                            }
                        }, document)
                    default:
                        return document;
                }
            })
    }
);

const isSignatureConfirmed = (state) => {
    return state === signatureStates.SIGNATURE_CONFIRMED
}

export const DocumentsForEstateAgent = ({documents, setModalPreview, signatureState, letter}) => {

    const editedDocuments = editDocuments(documents);

    return (
        <UploadSection>
            {letter && <span className="letter">{letter}</span>}
            <h3>
                Dokumenty k&nbsp;podpisu pro nového majitele
            </h3>
            <div className="desc">
                <div className={"label"}>
                    <strong>Dokumenty jsme odeslali novému majiteli k&nbsp;podpisu</strong>
                    <InfoBoxPoa>
                        <span>{!isSignatureConfirmed(signatureState) ? <>E-mail odeslán</> : <>Dokumenty
                            podepsány</>}</span>
                    </InfoBoxPoa>
                </div>
            </div>
            {editedDocuments && editedDocuments.length !== 0 && isSignatureConfirmed(signatureState) ?
                <div className="wrapper">
                    <div className="upload upload--wider">
                        <StyledDropzone>
                            {editedDocuments.map(document => {
                                return <UploadedDocument
                                    key={document.id}
                                    isDraft={document.isDraft}
                                    link={document.fileLink}
                                    linkLabel={<>Zobrazit náhled<br/>v PDF</>}
                                    overlayLabel={document.overlayLabel}
                                    fileType={document.mimetype}
                                    preview={document.preview}
                                    setModalPreview={setModalPreview}
                                    loading={document.id.match(/^loading/)}
                                    type={document.type}
                                    onlySignedDocuments
                                />
                            })}
                        </StyledDropzone>
                    </div>
                </div> : ""
            }
        </UploadSection>
    );
}