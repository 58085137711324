import styled from "styled-components";

export const SideBoxContainer = styled.div`
    position: sticky;
    top: 10px;
    width: 100%;
    padding: 20px 0 15px 0;
    z-index: 10;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        margin-left: 15px;
        margin-top: 23px;
        width: 235px;
        padding: 0;
    }
    
    @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
        margin-top: ${props => {
            let mt = props.mt ? parseInt(props.mt, 10) : 0;
            if(!props.showTitle) {
                mt += 22;
            }
            
          return  `${mt}px`;
        }};
    }
    
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 10px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            padding: 0 0 10px;
        }
        
         & > p {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            color: ${props => props.theme.colors.exploratoryGreen};
        }
        
        button.save {
            width: auto;
            margin: 0;
        }
    }
    
    button.save {
        width: 100%;
        margin-top: 10px;
        font-size: 14px;
        justify-content: center;
        
        svg {
            margin-right: 7px;
            position: relative;
        }
    }
`;

export const SideBox = styled.div`
    flex-shrink: 0;
    padding: 4px;
    background: ${props => props.isRecap ? "linear-gradient(180deg, #2B6E33 0%, #A8BE32 100%);" : props.theme.colors.resourceGreen};
    
    width: 100%;  
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        width: 235px;
    }
    
    p.sub-label {
        font-weight: 600;
        font-size: 9px;
        text-transform: uppercase;
        margin: 0;
        padding: 0 14px;
    }
    
    p.label {
        font-size: ${props => props.isRecap ?  "14px" : "15px"};
        font-weight: bold;
        color: ${props => props.isRecap ?  props.theme.colors.white : props.theme.colors.resourceGreen};
        text-transform: ${props => props.isRecap ?  "uppercase" : "none"};
        padding: 0 ${props => props.isRecap ?  "8px" : "14px"};
        line-height: 20px;
        margin: 2px 0 0 0;
    }
    
    div.mainLine {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        padding-top: ${props => props.isRecap ? "8px": "14px"};
        color: ${props => props.isRecap ? props.theme.colors.white: "inherit"};
        background: ${props => props.isRecap ? "none" : props.theme.colors.white};
        
        button {
            background: transparent;
            border: 0;
            margin: 0 10px 0 0;
            padding: 0;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transform: ${props => props.isOpen ? "rotate(90deg)" : "none"};
            transition: transform ${props => props.theme.times.defaultAnimation};
            
            &:focus {
                outline: none;
            }
        }
        
        div {
            display: flex;
            flex-direction: column;
        }
    }
    
    address {
        background: white;
        padding: 12px 10px;
        margin: 4px 0 0 0;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: ${props => props.theme.colors.resourceGreen};
    }
    address span {
        font-weight: 600;
        font-size: 9px;
        line-height: 12px;
        text-transform: uppercase;
        color: ${props => props.theme.colors.expertiseGray};
    }
    
    div.info {
        overflow: hidden;
        max-height: ${props => props.isOpen ? "200px": 0};
        transition: max-height ${props => props.theme.times.defaultAnimation};
        background: white;
        
        div:last-of-type {
            margin-bottom: 14px;
        }
    }
    
    div.owner-info {
        width: 100%;
        padding: 11px 14px 3px 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        p {
            font-size: 12px;
            color: ${props => props.theme.colors.expertiseGray};
            margin: 0;
        }
        
        strong {
            font-size: 12px;
            color: ${props => props.theme.colors.expertiseGray};
            margin-left: 7px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden !important;
        }
        
        &:first-of-type {
            strong {
                color: ${props => props.theme.colors.exploratoryGreen};
            }
        }
    }
    
    div.consumption {
        width: 100%;
        background-color: ${props => props.theme.colors.grey};
        padding: 11px 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        p {
            font-size: 12px;
            color: ${props => props.theme.colors.expertiseGray};
            margin: 0;
        }
        
        strong {
            font-size: 12px;
            color: ${props => props.theme.colors.expertiseGray};
        }
    }
    
    div.price {
        width: 100%;
        padding: 0 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        
        p {
            font-size: 12px;
            color: ${props => props.theme.colors.exploratoryGreen};
            margin: 0;
            font-weight: bold;
        }
        
        strong {
            font-size: 12px;
            color: ${props => props.theme.colors.exploratoryGreen};
        }
    }
    
    div.onlineDiscount {
        width: 100%;
        padding: 0 14px 10px 14px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        
        p {
            font-size: 12px;
            color: ${props => props.theme.colors.professionalGray};
            margin: 0;
            padding-top: 7px;
        }
        
        p:nth-of-type(1) {
            padding-left: 15px;
            position: relative;
            
            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 4px;
                top: 5px;
                width: 4px;
                height: 10px;
                border-left: 1px solid ${props => props.theme.colors.professionalGray};
                border-bottom: 1px solid ${props => props.theme.colors.professionalGray};
            }
        }
    }
    
    div.priceMonth {
        width: 100%;
        padding: 0 14px;
        margin-top: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        p {
            font-size: 12px;
            color: ${props => props.theme.colors.expertiseGray};
            margin: 0;
        }
        
        strong {
            font-size: 12px;
            color: ${props => props.theme.colors.expertiseGray};
        }
    }
    
    div.line {
        width: 100%;
        padding: 0 14px; 
    }
    
    hr {
        margin: 0;
        background: ${props => props.theme.colors.lightGrey};
    }
`;
