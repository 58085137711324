import styled from "styled-components";

export const ZopPRE = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        flex-direction: row;
    }
    
    .row {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 9px;
        margin-bottom: 15px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            width: 50%;
            margin-bottom: 0;
        }
        
        .number {
            position: absolute;
            top: 0;
            left: 0;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            border-radius: 50%;
            border: 1px solid ${props => props.theme.colors.resourceGreen};
            color: ${props => props.theme.colors.resourceGreen};
        }
        
        .page {
            display: flex;
            width: 74px;
            height: 106px;
            background: url("../images/page.svg") no-repeat center center;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
        }
        
        a {
            font-weight: 600;
            color: ${props => props.theme.colors.exploratoryGreen};
            margin-bottom: 3px;
            
            svg {
                position: relative;
                margin-right: 7px;
                top: 3px;
            }
            
            &:hover {
                text-decoration: none;
            }
        }
        
        a.phone {
            font-size: 26px;
            font-weight: 600;
            text-decoration: none;
            margin-bottom: 0;
        }
        
        p {
            font-size: 14px;
            color: ${props => props.theme.colors.expertiseGray};
            margin-bottom: 8px;
        }
        
        strong {
            color: ${props => props.theme.colors.exploratoryGreen};
            font-weight: 600;
            font-size: 14px;
            text-align: center;
        }
        
        &:nth-of-type(2) {
            padding-top: 45px;
            
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                padding-top: 35px;
            }
        }
    }
`;
