import React, { useEffect, useState } from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {Redirect, useHistory} from "react-router-dom";

import GET_CONTRACT from "../graphql/queries/getContract";
import SEND_POA_SIGNATURE_REQUEST from "../graphql/mutations/sendPoaSignatureRequest";

import {Wrapper, Container, WhiteBox, Inner} from "../style/grid";
import StepNavigation from "../components/StepNavigation";
import SideBoxOwner from "../components/SideBoxOwner";
import Loading from "../components/Loading";

import {Link} from "../style/link";
import {PageHeader} from "../style/pageHeader";
import {UploadSection} from "../style/uploadSection";
import {SendPoaFooter} from "../style/sendPoaFooter";

import PoaRadio from "../components/PoaRadio";
import SendPoaForm from "../components/SendPoaForm";
import SendPoaLink from "../components/SendPoaLink";
import ModalPreviewMail from "../components/ModalPreviewMail";

import {ADD_DOCUMENTS, ERROR_ROUTE} from "../configs/routes";

import {POA_ONLINE_DELIVERY_OTHER, POA_ONLINE_DELIVERY_EMAIL} from "../configs/onlinePoaDelivery";
import {SITUATION_SUPPLIER_CHANGE} from "../configs";
import { logPoaEvent } from '../utils/analytics';

const poaType = [
    {
        type: POA_ONLINE_DELIVERY_EMAIL,
        label: "Poslat odkaz na podpis plné moci do e-mailu"
    },
    {
        type: POA_ONLINE_DELIVERY_OTHER,
        label: "Odkaz zkopíruji a odešlu sám/a (např. přes SMS, WhatsApp, Messenger, apod.)"
    },

];

const SendPoaContainer = () => {
    const history = useHistory();
    const [type, setPoaType] = useState(POA_ONLINE_DELIVERY_EMAIL);
    const [modalOpen, setModal] = useState(false);
    const handleChange = (event) => {
        setPoaType(event.target.value);
    };
    const {loading, error, data} = useQuery(GET_CONTRACT);
    const {contract: {situation, person: newOwner}} = data;
    const [sendPOASignatureRequest, {loading: sendPOASignatureRequestLoading, error: sendPOASignatureRequestError},] = useMutation(SEND_POA_SIGNATURE_REQUEST,
        {
            onCompleted() {
                history.push(`/${ADD_DOCUMENTS}`);
            }
        });

    useEffect(() => {
        const additionalPodEventData = {
            step: 1,
            name: "Odeslání žádosti POA",
        };

        logPoaEvent(additionalPodEventData);
    }, []);
    
    if (loading) return <Loading/>;
    if (error || sendPOASignatureRequestError) return <Redirect to={`/${ERROR_ROUTE}`} />;

    return (
        <Wrapper>
            <Container>
                <StepNavigation currentStage={2} isDone={false}/>
                <Inner>
                    <WhiteBox>
                        <PageHeader>
                            <h1>Připravíme plnou moc pro původního majitele k&nbsp;on-line podpisu</h1>
                            <h2>Vyberte, prosím, zda máme původnímu majiteli poslat odkaz na podpis plné moci do e-mailu nebo mu odkaz na podpis plné moci můžete zkopírovat a poslat sám/a</h2>
                        </PageHeader>
                        <UploadSection type="poa">
                            <PoaRadio
                                poaTypeConfig={poaType}
                                handleChange={handleChange}
                                selectedPoaType={type}
                                radioType="sendPoa"
                                contract={data.contract}
                            />
                        </UploadSection>
                        {type === POA_ONLINE_DELIVERY_EMAIL && <SendPoaForm newOwner={newOwner} sendPOASignatureRequest={sendPOASignatureRequest} sendingPoaSignatureRequest={sendPOASignatureRequestLoading}/>}
                        {type === POA_ONLINE_DELIVERY_OTHER && <SendPoaLink sendPOASignatureRequest={sendPOASignatureRequest} sendingPoaSignatureRequest={sendPOASignatureRequestLoading}/>}
                        <SendPoaFooter>
                            <p>Po doplnění údajů a podpisu plné moci původním majitelem vás budeme infomovat e-mailem.</p>
                            {type === POA_ONLINE_DELIVERY_EMAIL && <Link onClick={() => {setModal(true)}}>Co přijde původnímu majiteli?</Link>}
                        </SendPoaFooter>

                        <ModalPreviewMail
                            modalOpen={modalOpen}
                            setModal={setModal}
                            email={"mnd@mndcz.cz"}
                            name={""}
                        />
                    </WhiteBox>
                    <SideBoxOwner
                        savingEnabled={false}
                        showTitle={situation !== SITUATION_SUPPLIER_CHANGE}
                        name={newOwner.name}
                        surname={newOwner.surname}
                        email={newOwner.email}
                        phone={newOwner.phone}
                        street={newOwner.street}
                        streetNumber={newOwner.streetNumber}
                        zipCode={newOwner.zipCode}
                        city={newOwner.city}
                        houseNumber={newOwner.houseNumber}
                    />
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default SendPoaContainer;
