import React from "react";

import {ZopPRE as ZopPREStyle} from "../style/zopPRE";
import {UploadSection} from "../style/uploadSection";
import theme from "../style/theme";

import PrintIcon from "../style/icons/print";
import PhoneIcon from "../style/icons/phone";
import DownloadIcon from "../style/icons/download";

export const ZopPRE = ({document, letter}) => {
    return (
        <UploadSection>
            <>
                {letter && <span className="letter">{letter}</span>}
                <h3>Žádost o připojení k distribuční soustavě</h3>
                <p>Vzhledem k tomu že, jste v distribuční soustavě PRE. Je třeba stáhnout, vytisknout a podepsat <br />dokument níže a domluvit si návštěvu technika od PRE.</p>
            </>
            <div className="wrapper">
                <ZopPREStyle>
                    <div className="row">
                        <span className="number">1</span>
                        <div className="page">
                            <PrintIcon/>
                        </div>
                        <a target="_blank" rel="noopener noreferrer" href={document ? document.fileLink : "/documents/zop-pre.pdf"}>
                            <DownloadIcon/>
                            Stáhnout žádost
                        </a>
                        <p>...vytiskněte a podepište.</p>
                    </div>
                    <div className="row">
                        <span className="number">2</span>
                        <strong>Domluvte si schůzku s technikem PRE</strong>
                        <a href="tel:800550055" className="phone">
                            <PhoneIcon
                                width="25"
                                height="24"
                                color={theme.colors.exploratoryGreen}
                            />
                            800&nbsp;550&nbsp;055
                        </a>
                    </div>
                </ZopPREStyle>
            </div>
        </UploadSection>
    );
};

export default ZopPRE;
