import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        overflow-y: visible;
    }
    body {
        background-color: #595A5B;
    }

    body.ReactModal__Body--open {
        overflow-y: hidden;
    }

    #root {
        background-color: ${props => props.theme.colors.grey};
        min-height: calc(100vh - 1px);
        display: flex;
        flex-direction: column;
    }
 
    .mndLine {
        display: block;
        background: red;
        height: 9px;
        width: 100%;
        background: linear-gradient(90deg, ${props => props.theme.colors.resourceGreen}, ${props => props.theme.colors.exploratoryGreen});
    }
    
    .modal {
        width: 520px;
        margin: 100px 0 0 0;
        background: ${props => props.theme.colors.lightGrey};
        max-height: 100vh;
        overflow-y: auto;
        z-index: 5002;
        outline: none;
        
        @media screen and (max-height: 700px) {
            margin: 0;
        }
        
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            width: 100%;
            margin: 0;
        }
    }
    
    .modalQrCode {
        width: 630px; 
    }
    
    .modalPreviewMail {
        width: 700px; 
    }
    
    .modalPreview {
        display: flex;
        margin: 40px 20px;
        width: 900px;
        max-height: 90vh;
        background: #fff;
        overflow-y: auto;
        z-index: 5002;
        background: ${props => props.theme.colors.white};
        outline: none;
        border: 0;
        height: 90vh;
        
        .modalPreviewInner {
            position: relative;
            display: flex;
            flex-grow: 1;
        }
        
        .modalClose {
            font-size: 34px;
            cursor: pointer;
            display: block;
            position: absolute;
            right: 20px;
            top: 5px;
            color: ${props => props.theme.colors.professionalGray};
        }
        
        .modalContent {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding: 60px 40px;
            width: 100%;
        
            img {
                max-width: 100%;
                margin-top: 20px;
                margin-bottom: 30px;
            }
            
            iframe {
                width: 100%;
                height: calc(100% - 20px);
                margin-top: 20px;
                border: 0;
            }  
        }
    }
    
    .landscape {
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            h3 {
                font-size: 24px;
                padding-top: 10px;
            }
            
            .mobile-text {
                padding: 8px 15px 8px 15px !important;
                display: block !important;
            }
            
            .button-group {
                padding-top: 0;
            }
            
            .signature-pad-container {
                @media screen and (max-width: 450px) {
                    width: 100%;
                } 
            }
        }
    } 
    
    .modalOverlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${props => props.theme.colors.lightGrey};
        z-index: 5001;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    
    .rw-calendar-popup {        
        .rw-calendar-transition-group {
            .rw-state-selected {
                background-color: ${props => props.theme.colors.professionalGray};
                border: 1px solid ${props => props.theme.colors.professionalGray};
                color: ${props => props.theme.colors.textBlack};
                
                &:hover {
                    background-color: ${props => props.theme.colors.professionalGray}
                    color: ${props => props.theme.colors.textBlack}
                }  
            }       
        }
    }
    
    .rw-widget-picker {
        border: 0 !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }
    
    .rw-input {
        border: 0 !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        background: ${props => props.theme.colors.lightGrey};
        background: ${props => props.theme.colors.lightGrey};
        font-size: 14px;
        color: ${props => props.theme.colors.expertiseGray};
        padding: 0 11px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
	
	.rw-select {
        border-left: 0 !important;
        width: 40px;
        height: 40px;
        vertical-align: middle; 
        
        .rw-btn {
            width: 40px;
            height: 40px;
            background: ${props => props.theme.colors.lightGrey};
            vertical-align: top;
            opacity: 1;
            
            span {
                display: inline-block;
                vertical-align: middle;
                
                &.rw-i-calendar {
                    width: 20px;
                    height: 20px;
                    background: url("../images/calendar-icon.svg") no-repeat center center;
                    background-size: 20px 20px;
                    
                    &:before {
                        display: none;
                    }
                }     
            }      
        }   
    }  
    
    @font-face {
        //font-family: "AvenirNextLTPro-Regular"
        font-family: "AvenirNextLTPro";
        src: url("/fonts/webfonts/32B5C6_0_0.eot");
        src: url("/fonts/webfonts/32B5C6_0_0.eot?#iefix") format("embedded-opentype"), url("/fonts/webfonts/32B5C6_0_0.woff2") format("woff2"), url("/fonts/webfonts/32B5C6_0_0.woff") format("woff"), url("/fonts/webfonts/32B5C6_0_0.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        //font-family: "AvenirNextLTPro-It"
        font-family: "AvenirNextLTPro";
        src: url("/fonts/AvenirNext/2EA757_3_0.eot");
        src: url("/fonts/AvenirNext/2EA757_3_0.eot?#iefix") format("embedded-opentype"), url("/fonts/AvenirNext/2EA757_3_0.woff2") format("woff2"), url("/fonts/AvenirNext/2EA757_3_0.woff") format("woff"), url("/fonts/AvenirNext/2EA757_3_0.ttf") format("truetype");
        font-weight: 400;
        font-style: italic;
    }
    
    @font-face {
        //font-family: "AvenirNextLTPro-Demi"
        font-family: "AvenirNextLTPro";
        src: url("/fonts/AvenirNext/2EA757_5_0.eot");
        src: url("/fonts/AvenirNext/2EA757_5_0.eot?#iefix") format("embedded-opentype"), url("/fonts/AvenirNext/2EA757_5_0.woff2") format("woff2"), url("/fonts/AvenirNext/2EA757_5_0.woff") format("woff"), url("/fonts/AvenirNext/2EA757_5_0.ttf") format("truetype");
        font-weight: 600;
        font-style: normal;
    }
    
    @font-face {
        //font-family: "AvenirNextLTPro-Bold"
        font-family: "AvenirNextLTPro";
        src: url("/fonts/AvenirNext/2EA757_1_0.eot");
        src: url("/fonts/AvenirNext/2EA757_1_0.eot?#iefix") format("embedded-opentype"), url("/fonts/AvenirNext/2EA757_1_0.woff2") format("woff2"), url("/fonts/AvenirNext/2EA757_1_0.woff") format("woff"), url("/fonts/AvenirNext/2EA757_1_0.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    
    @font-face {
        //font-family: "AvenirNextLTPro-BoldIt"
        font-family: "AvenirNextLTPro";
        src: url("/fonts/AvenirNext/2EA757_2_0.eot");
        src: url("/fonts/AvenirNext/2EA757_2_0.eot?#iefix") format("embedded-opentype"), url("/fonts/AvenirNext/2EA757_2_0.woff2") format("woff2"), url("/fonts/AvenirNext/2EA757_2_0.woff") format("woff"), url("/fonts/AvenirNext/2EA757_2_0.ttf") format("truetype");
        font-weight: 700;
        font-style: italic;
    }
    
    
`;
