import React from "react";

import {Message} from "../style/message";
import {Container} from "../style/grid";
import {Button} from "../style/button";
import RefreshIcon from "../style/icons/refresh";

const UpdateMessage = ({isOpen, setMessage}) => <Message as="div" isOpen={isOpen}>
    <Container>
        <h3>Obnovte stránku</h3>
        <p>Pokud nahráváte dokumenty na jiném zařízení. Pro jejich zobrazení v tomto prohlížeči je třeba následně obnovit stránku.</p>
        <div className="buttons">
            <Button onClick={() => window.location.reload()} variant="small" color="white" className="update-button">
                <RefreshIcon/>
                Obnovit stránku
            </Button>
            <Button onClick={() => setMessage(false)} variant="link" className="close-button">Beru na vědomí</Button>
        </div>
    </Container>
</Message>;

export default UpdateMessage;
