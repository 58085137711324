import React from "react";
import Modal from "react-modal";
import classnames from "classnames";

const ModalPreview = ({modalPreview, setModalPreview}) => {
    return (
        <Modal
            isOpen={modalPreview.isOpen}
            onRequestClose={() => setModalPreview({
                ...modalPreview,
                isOpen: false
            })}
            contentLabel="Náhled dokumentu"
            className={classnames({
                "modalPreview": true,
            })}
            overlayClassName="modalOverlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="modalPreviewInner">
                <span className="modalClose" onClick={() => setModalPreview({
                    ...modalPreview,
                    isOpen: false
                })}>&#10005;</span>
                <div className="modalContent">
                    {modalPreview.fileType === "image" && <img src={modalPreview.preview} alt="Náhled dokumentu" />}
                    {modalPreview.fileType === "pdf" && <iframe src={modalPreview.preview} title="Náhled dokumentu" />}
                </div>
            </div>
        </Modal>
    );
};

export default ModalPreview;
