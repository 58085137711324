import styled from "styled-components";

export const SendPoaFooter = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    p, a {
        text-align: center;
        color: ${props => props.theme.colors.expertiseGray};
        font-size: 14px;
    }
    
    a {        
        margin-top: 30px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            margin-top: 100px;
        }
    
        &:hover {
            text-decoration: none;
        }
    }
`;
