import styled from "styled-components";

export const InfoBoxPoa = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0;
    cursor: default;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        padding: 0 0 0 8px;
    } 
    
    a, button {
        color: ${props => props.theme.colors.expertiseGray};
        margin-left: 10px;
        font-size: 14px;
                
        &:hover {
            text-decoration: none;
        }
    }
    
    span {
        display: flex;
        border-radius: 4px;
        padding: 1px 5px;
        position: relative;
        background: ${props => props.theme.colors.resourceGreen};
        color: ${props => props.theme.colors.white};
        font-weight: 600;
    }
`;
