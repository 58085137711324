import React from "react";
import {useMediaQuery} from "react-responsive";

import {Header as HeaderStyle} from "../style/header";
import {Container} from "../style/grid";
import PhoneIcon from "../style/icons/phone";
import theme from "../style/theme";
import {getLabel} from "../helpers/getLabel";
import {PRODUCTION_HOST_NAME} from "../configs";
import {CUSTOM, SIGN_THIRDPARTY_POWER_OF_ATTORNEY} from "../configs/documentRequestType";

const getMndWebUrl = () => {
    if(window.location.hostname === "project.mnd.cz") {
        return "https://project.mnd.cz/"
    }

    if( window.location.hostname === "stage.mnd.cz") {
        return "https://stage.mnd.cz/"
    }

    return "https://www.mnd.cz/"
};

const Header = ({requestType}) => {
    const isMobileScreen = useMediaQuery({ query: `(max-width: ${theme.breakpoints.mobile})` });

    let processLabel = "sjednání smlouvy s MND";
    if (requestType === SIGN_THIRDPARTY_POWER_OF_ATTORNEY) {
        processLabel = "podpis plné moci";
    } else if (requestType === CUSTOM) {
        processLabel = "doplnění dokumentů";
    }


    return <HeaderStyle>
        <Container>
            {isMobileScreen && <>
                <div className="mobileContainer">
                    <div className="logo">
                        <a href={getMndWebUrl()} onClick={ () => window.confirm('Odcházíte ze stránky, vaše data můžete uložit pomocí odkazu uložit a dokončit později')}>
                            <img src="/images/MND.cz.svg" alt="MND.cz" />
                        </a>
                    </div>
                    <p className="hotline">
                        <PhoneIcon color={theme.colors.exploratoryGreen} />800 400 500

                    </p>
                </div>
                <p className="text">{getLabel("komodita", true)} – On-line {processLabel}</p>
            </>}

            {!isMobileScreen && <>
                <h1 className="logo">
                    <a href={getMndWebUrl()} onClick={ () => window.confirm('Odcházíte ze stránky, vaše data můžete uložit pomocí odkazu uložit a dokončit později')}>
                        <img src="/images/MND.cz.svg" alt="MND.cz" />
                    </a>
                </h1>

                <p className="text">{getLabel("komodita", true)} – On-line {processLabel}</p>


                <p className="hotline">
                    <PhoneIcon color={theme.colors.exploratoryGreen} />800 400 500
                </p>
            </>}
            {window.location.hostname !== PRODUCTION_HOST_NAME &&
            <small style={{position:"absolute",top:0,color:"#ddd",lineBreak:"anywhere"}}><a href={"/?token=" + sessionStorage.getItem("token")}>?token={sessionStorage.getItem("token")}</a></small>}
        </Container>
        <span className="mndLine"/>
    </HeaderStyle>;
};

export default Header;



