import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";

import {Button} from "../style/button";
import {CopySection} from "../style/copySection";
import {ButtonWrapper} from "../style/grid";
import CopyIcon from "../style/icons/copy";

import ShortUrlContainer from "../containers/ShortUrlContainer";

import {ADD_DOCUMENTS} from "../configs/routes";
import {VIEWER_TYPE_THIRD_PARTY} from "../configs";

const SendPoaLink = ({sendPOASignatureRequest, sendingPoaSignatureRequest}) => {
    const linkInput = useRef(null);
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        linkInput.current.select();
        linkInput.current.setSelectionRange(0, 99999);
        document.execCommand("copy");

        setIsCopied(true);
    };

    const handleSubmit = () => {
        sendPOASignatureRequest({
            variables: {
                "email": ""
            }
        });
    };

    return (
        <CopySection>
            <h3>Nasdílejte Odkaz</h3>
            <ShortUrlContainer role={VIEWER_TYPE_THIRD_PARTY}>
                {link => <input type="text" readOnly value={link} ref={linkInput} />}
            </ShortUrlContainer>

            <Button onClick={copyToClipboard} variant="link" align="center">
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <CopyIcon/>
                </svg>
                {isCopied ? "Zkopírováno" : "Kopírovat"}
            </Button>
            <ButtonWrapper px="35px">
                <Button as={Link} to={`/${ADD_DOCUMENTS}`} color="gray" disabled={sendingPoaSignatureRequest}>Zpět</Button>
                <Button type="submit" disabled={sendingPoaSignatureRequest} onClick={handleSubmit}>{sendingPoaSignatureRequest ? "Načítání..." : "Odkaz jsem nasdílel"}</Button>
            </ButtonWrapper>
        </CopySection>
    )
};

export default SendPoaLink;
