import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from '@apollo/react-hooks';

import useAddOrRemove from "../hooks/useAddOrRemove";

import {
    DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION,
    DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION_OFFLINE,
    DOCUMENT_TYPE_CONTRACT_MND,
    DOCUMENT_TYPE_OTHER_DOCUMENTS,
    DOCUMENT_TYPE_POA,
    DOCUMENT_TYPE_POA_CEZ,
    DOCUMENT_TYPE_POA_MND,
    DOCUMENT_TYPE_REPRESENTATION_OF_THE_OWNER,
    SITUATION_SUPPLIER_CHANGE,
    VIEWER_PROCESS_DOCUMENTS,
    VIEWER_TYPE_PARTNER
} from "../configs";

import {ERROR_ROUTE, signatureStates} from "../configs/routes";

import {Redirect} from "react-router-dom";

import {isUploadValid} from "../helpers/isUploadValid";
import hasSinglePossibleSubSituationToChoose from "../helpers/hasSinglePossibleSubSituationToChoose";

import UploadDocuments from "../components/UploadDocuments";
import UploadDocumentsComp from "../components/UploadDocuments/UploadDocuments";
import ZopPRE from "../components/ZopPRE";
import Poa from "../components/Poa";
import Loading from "../components/Loading";

import GET_CONTRACT from "../graphql/queries/getContract";
import SEND_TO_MOBILE from "../graphql/mutations/sendToMobile";
import CHANGE_SUBSITUATION from "../graphql/mutations/changeSubSituation";
import CHANGE_POA_TYPE from "../graphql/mutations/changePoaType";
import FINALIZE_DOCUMENT_UPLOADS from "../graphql/mutations/finalizeDocumentUploads";
import UPLOAD_DOCUMENT from "../graphql/mutations/uploadDocument";
import DELETE_DOCUMENT from "../graphql/mutations/deleteDocument";
import GET_VIEWER from "../graphql/queries/getViewer";
import SAVE_CONTRACT from "../graphql/mutations/saveContract";
import {POA_TYPE_OFFLINE, POA_TYPE_ONLINE} from "../configs/poaType";

import {DocumentsForEstateAgent} from "../components/DocumentsForEstateAgent";
import getStepsConfig, {ADD_DOCUMENTS_STAGE} from "../helpers/getStepsConfig";
import { getFormatRequestDocuments, logSignatureStepEvent } from "../utils/analytics";
import { COMMODITY_PURCHASE } from "../configs/commodity";


const getFinalizeButtonTooltip = ({
                                      uploadLoading,
                                      finalizeDocumentUploadsLoading,
                                      hasAllRequiredDocuments,
                                      waitingForThirdParty,
                                      waitingToNotifyThirdParty
                                  }) => {
    if (finalizeDocumentUploadsLoading) {
        return "Načítání...";
    }

    if (hasAllRequiredDocuments && uploadLoading) {
        return "Nemůžete dokončit, protože ještě probíhá nahrávání souborů."
    }

    if (!hasAllRequiredDocuments) {
        return "Nemůžete dokončit, protože ještě nemáte přidané všechny dokumenty.";
    }

    if (waitingForThirdParty) {
        return "Nemůžete dokončit, protože ještě nemáte plnou moc od původního majitele. Jakmile ji původní majitel podepíše, pošleme vám e-mail a budete moci všechny dokumenty odeslat do MND.";
    }

    if (waitingToNotifyThirdParty) {
        return "Nemůžete dokončit, musíte nejdřív požádat původního majitele o dodání plné moci.";
    }

    return null;
};

const waitingForThirdParty = (configuration, onlinePoa, selectedPoaType) => {

    if (configuration.includes(DOCUMENT_TYPE_POA)) {
        return {
            waitingForThirdParty: selectedPoaType === POA_TYPE_ONLINE && [
                signatureStates.NONE,
                signatureStates.WAITING_FOR_SIGNATURE_CONFIRMATION,
                signatureStates.SENT_FOR_SIGNATURE
            ].indexOf(onlinePoa?.signatureState) !== -1,

            waitingToNotifyThirdParty: selectedPoaType === POA_TYPE_ONLINE && [
                signatureStates.SENT_FOR_SIGNATURE,
                signatureStates.SIGNATURE_CONFIRMED,
                signatureStates.WAITING_FOR_SIGNATURE_CONFIRMATION
            ].indexOf(onlinePoa?.signatureState) === -1
        };
    }
    return {
        waitingForThirdParty: false,
        waitingToNotifyThirdParty: false
    }
};

const getLetter = (index) => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return letters[index];
};

const UploadDocumentsContainer = ({viewer: {process, type}}) => {
        const {valuesArray, addOrRemove: setMandatory} = useAddOrRemove();
        let letterIndex = 0;

        const [modalOpen, setModal] = useState(false);
        const [isMessageOpen, setMessage] = useState(false);
        const [modalPreview, setModalPreview] = useState({
            isOpen: false,
            preview: null,
            fileType: null,
        });

        const [, setCanChangeSubsituation] = useState(false);

        const {loading, error, data} = useQuery(GET_CONTRACT);
        const {data: dataViewer} = useQuery(GET_VIEWER);

        const isPurchaseLead = data?.contract?.commodity === COMMODITY_PURCHASE;

        const [
            changeSubSituation,
            {loading: changeSubSituationLoading, error: changeSubSituationError},
        ] = useMutation(CHANGE_SUBSITUATION);
        const [
            changePoaType,
            {error: changePoaTypeError},
        ] = useMutation(CHANGE_POA_TYPE);
        const [
            sendToMobile,
            {loading: sendToMobileLoading, error: sendToMobileError},
        ] = useMutation(SEND_TO_MOBILE, {});
        const [
            finalizeDocumentUploads,
            {loading: finalizeDocumentUploadsLoading, error: finalizeDocumentUploadsError},
        ] = useMutation(FINALIZE_DOCUMENT_UPLOADS);
        const [
            uploadDocument,
            {loading: uploadDocumentLoading},
        ] = useMutation(UPLOAD_DOCUMENT);
        const [
            deleteDocument
        ] = useMutation(DELETE_DOCUMENT);
        const [
            saveContract,
            {error: saveContractError},
        ] = useMutation(SAVE_CONTRACT, {});

        useEffect(() => {
            const additionalSignatureEventData = {
                step: 8,
                name: "Doplnění dokumentů",
                type: "Documents",
                text: "Dokončit",
                situationType: data?.contract?.situation,
                requestedDocument: getFormatRequestDocuments(data?.contract?.activeDocumentRequest)
            };

            logSignatureStepEvent(additionalSignatureEventData);
        }, []);

        useEffect(() => {
            (async () => {

                if (hasSinglePossibleSubSituationToChoose(data.contract)) {
                    await changeSubSituation({
                        variables: {
                            selectedSubSituation: data.contract.subSituations[0]
                        },
                        optimisticResponse: {
                            __typename: "Mutation",
                            changeSubSituation: {
                                ...contract,
                                selectedSubSituation: data.contract.subSituations[0],
                            }
                        }
                    });
                }

                await setCanChangeSubsituation(subSituations?.length > 1);
            })();

        }, []); // eslint-disable-line

        if (loading) return <Loading/>;
        if (error || sendToMobileError || changeSubSituationError || changePoaTypeError || finalizeDocumentUploadsError || saveContractError) return <Redirect
            to={`/${ERROR_ROUTE}`}/>;

        const {
            contract: {
                situation,
                subSituations,
                selectedSubSituation,
                selectedPoaType,
                documents,
                activeDocumentRequestPoa: onlinePoa,
                activeDocumentRequest: {
                    configurationItems
                },
                commodity
            }, contract
        } = data;

        const configurationNotRequired = configurationItems.filter(item => !item.isRequired).map(item => item.category);

        const uploadConfigurationSrc = configurationItems.filter(item => {
            const skippedDocumentTypes = [
                DOCUMENT_TYPE_CONTRACT_MND,
                DOCUMENT_TYPE_POA_MND,
                DOCUMENT_TYPE_POA_CEZ,
                DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION,
            ];
            return !skippedDocumentTypes.includes(item.category);
        });

        const uploadConfiguration = uploadConfigurationSrc
            .filter(item => item.isRequired)
            .concat(
                uploadConfigurationSrc.filter(item => !item.isRequired)
            );

        const hasAllRequiredDocuments = isUploadValid(
            uploadConfiguration.map(item => item.category),
            documents,
            valuesArray.concat(configurationNotRequired),
            selectedPoaType === POA_TYPE_OFFLINE
        );

        const hasDocsForRealEstate = process === VIEWER_PROCESS_DOCUMENTS && type === VIEWER_TYPE_PARTNER;

        const createComponentsList = (config) => {
            let components = [];
            const specialConfigs = [
                DOCUMENT_TYPE_REPRESENTATION_OF_THE_OWNER,
                DOCUMENT_TYPE_POA,
                DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION_OFFLINE
            ]

            if (hasDocsForRealEstate) {
                components.push(<DocumentsForEstateAgent
                    letter={getLetter(letterIndex++)}
                    key={getLetter(letterIndex)}
                    documents={documents}
                    setModalPreview={setModalPreview}
                    signatureState={signatureStates.NONE}/> //TODO musi se najit odpovidajici podepisovaci document request
                );
            }

            components = [...components, ...config.filter(item => item.category === DOCUMENT_TYPE_REPRESENTATION_OF_THE_OWNER).map((item, index) =>
                <UploadDocuments
                    letter={getLetter(letterIndex++)}
                    key={getLetter(letterIndex)}
                    uploadDocument={uploadDocument}
                    configuration={item}
                    contract={contract}
                    setModal={setModal}
                    setModalPreview={setModalPreview}
                    documents={documents.filter(document => document.category === item.category)}
                    uploadDocumentLoading={uploadDocumentLoading}
                    deleteDocument={deleteDocument}
                    situation={situation}
                    dataViewer={dataViewer}
                >

                </UploadDocuments>
            )];

            if (config.some(item => item.category === DOCUMENT_TYPE_POA)) {
                components.push(<Poa
                    letter={getLetter(letterIndex++)}
                    key={getLetter(letterIndex)}
                    contract={contract}
                    configuration={config.find(item => item.category === DOCUMENT_TYPE_POA)}
                    selectedPoaType={selectedPoaType}
                    onlinePoa={contract.activeDocumentRequestPoa}
                    setModal={setModal}
                    setModalPreview={setModalPreview}
                    uploadDocument={uploadDocument}
                    uploadDocumentLoading={uploadDocumentLoading}
                    deleteDocument={deleteDocument}
                    changePoaType={changePoaType}
                    documentThirdPartyPoa={documents.filter(document => document.category === DOCUMENT_TYPE_POA)[0]}
                    documentsPoaOffline={documents.filter(document => document.category === DOCUMENT_TYPE_POA)}
                />);
            }
            if (config.some(item => item.category === DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION_OFFLINE)) {
                components.push(<ZopPRE letter={getLetter(letterIndex++)}
                                        key={getLetter(letterIndex)}
                                        document={documents.find(document => document.category === DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION_OFFLINE)}/>);
            }

            const componentsForRender = config.reduce((acc, item) => {
                if (isPurchaseLead) {
                    acc.push(<UploadDocuments
                        letter={''}
                        key={getLetter(letterIndex)}
                        uploadDocument={uploadDocument}
                        configuration={{
                            category: DOCUMENT_TYPE_OTHER_DOCUMENTS,
                            documentTitle: "Ostatní dokumenty",
                            isRequired: false,
                            sampleDocuments: [],
                            customConfigData: {
                                title: "Doplňte dokumenty k dosavadnímu výkupu u jiného dodavatele",
                                description: <>Pokud máte již nyní řešen výkup jinde, nahrajte nám některý ze&nbsp;stávajících dokumentů pro&nbsp;jednodušší vyřízení přechodu k&nbsp;MND. Může se jednat např. o&nbsp;smlouvu či&nbsp;dodatek, fakturu či&nbsp;akceptaci výpovědi v&nbsp;případě, že jste si již výpověď podali u&nbsp;stávajícího dodavatele pro&nbsp;výkup sami.</>,
                                uploadDescription: "Přidat dokumenty",
                                uploadMoreDescription: "Přidat další dokumenty",
                            }
                        }}
                        contract={contract}
                        setModal={setModal}
                        setModalPreview={setModalPreview}
                        documents={documents.filter(document => document.category === DOCUMENT_TYPE_OTHER_DOCUMENTS)}
                        uploadDocumentLoading={uploadDocumentLoading}
                        deleteDocument={deleteDocument}
                        setMandatory={setMandatory}
                        dataViewer={dataViewer}
                    />);
                } else if (!specialConfigs.includes(item.category)) {
                    acc.push(<UploadDocuments
                        letter={getLetter(letterIndex++)}
                        key={getLetter(letterIndex)}
                        uploadDocument={uploadDocument}
                        configuration={item}
                        contract={contract}
                        setModal={setModal}
                        setModalPreview={setModalPreview}
                        documents={documents.filter(document => document.category === item.category)}
                        uploadDocumentLoading={uploadDocumentLoading}
                        deleteDocument={deleteDocument}
                        setMandatory={setMandatory}
                        dataViewer={dataViewer}
                    />);
                }
                return acc;
            }, components);

            if (componentsForRender.length === (hasDocsForRealEstate ? 1 : 0)
            ) {
                componentsForRender.push(
                    <UploadDocuments
                        letter={getLetter(letterIndex++)}
                        key={getLetter(letterIndex)}
                        uploadDocument={uploadDocument}
                        configuration={{
                            category: DOCUMENT_TYPE_OTHER_DOCUMENTS,
                            isRequired: false,
                            documentTitle: "Další dokumenty, které potřebujeme",
                            sampleDocuments: []
                        }}
                        contract={contract}
                        setModal={setModal}
                        setModalPreview={setModalPreview}
                        documents={documents.filter(document => document.category === DOCUMENT_TYPE_OTHER_DOCUMENTS)}
                        uploadDocumentLoading={uploadDocumentLoading}
                        deleteDocument={deleteDocument}/>
                );
            }
            return componentsForRender;
        }

        const finalizedButtonTooltip = getFinalizeButtonTooltip({
            uploadLoading: uploadDocumentLoading,
            finalizeDocumentUploadsLoading,
            hasAllRequiredDocuments,
            ...waitingForThirdParty(configurationItems.map(item => item.category), onlinePoa, selectedPoaType)
        });


        const props = {
            contract,
            header: {
                variant: situation === SITUATION_SUPPLIER_CHANGE ? "supplier_change" : ""
            },
            subSituations: {
                show: false,
                data: subSituations,
                selected: selectedSubSituation,
                changeSubSituation
            },
            uploads: {
                show: Boolean( selectedSubSituation || commodity === COMMODITY_PURCHASE),
                showBottomBorder: situation === SITUATION_SUPPLIER_CHANGE,
                heading: isPurchaseLead ? '' : situation !== SITUATION_SUPPLIER_CHANGE ? "Doplňte prosím následující dokumenty" : null,
                loading: changeSubSituationLoading,
                components: [
                    {
                        component: createComponentsList(uploadConfiguration)
                    }
                ],
            },
            action: {
                primaryButtonLabel: finalizeDocumentUploadsLoading ? "Odesílá se" : "Dokončit",
                primaryButtonTooltip: finalizedButtonTooltip,
                primaryAction: finalizeDocumentUploads,
                secondaryAction: () => {
                    setMessage(true);
                    saveContract({
                        variables: {
                            "url": window.location.href
                        }
                    });
                },
                primaryButtonDisabled: finalizedButtonTooltip !== null ? "disabled" : "",
            },
            sideBar: {
                showTitle: situation !== SITUATION_SUPPLIER_CHANGE,
                newOwner: data.contract.person
            },
            message: {
                setMessage: setMessage,
                isMessageOpen: isMessageOpen
            },
            modal: {
                modalOpen: modalOpen,
                setModal: setModal,
                setModalPreview: setModalPreview,
                sendToMobileLoading: sendToMobileLoading,
                sendToMobile: sendToMobile,
                dataViewer: dataViewer,
                modalPreview: modalPreview
            },
            stepsNavigationConfig: getStepsConfig(data.contract.activeDocumentRequest, {
                forceStage: 2,
                forceStageName: ADD_DOCUMENTS_STAGE
            })
        };

        return <UploadDocumentsComp {...props} />;
    }
;

export default UploadDocumentsContainer;
