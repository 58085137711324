import styled from "styled-components";

export const ModalQrCode = styled.div`
    .modalContent {
        margin-top: 20px;
        padding: 0 0 20px 0;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            margin-top: 0;
        }
    
        h3 {
            color: ${props => props.theme.colors.exploratoryGreen};
            font-size: 26px;
            margin-bottom: 25px; 
            text-align: center;
            padding: 40px 20px 0 20px; 
            
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                padding: 0;
                text-align: left;
            }   
        }
    }
    
    .modalQrInner {
        background-color: #fff;
        padding: 20px;
        text-align: center;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            padding: 30px 100px 35px 100px;
        }
        
        h3 {
            font-size: 15px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 18px;
            color: ${props => props.theme.colors.expertiseGray};       
        }
        
        > svg {
            margin: 10px;
        }
    }
    
    .modalQrLink {
        border-bottom: 1px solid ${props => props.theme.colors.professionalGray};
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 15px;
        margin-top: 20px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            flex-direction: row;
        }
           
        input {
            width: inherit;
            color: ${props => props.theme.colors.exploratoryGreen};
            font-size: 26px;
            font-weight: 600;
            background: transparent;
            margin-right: 5px;
            padding-left: 0;
            border: none;
            
            &:focus {
                outline: 0;
            }
        }
        
        button {
            padding: 0;
        
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                padding: 0 0 0 10px;
            }
        }     
    }
    
    .modalQrEmail {
        display: flex; 
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        
        input {
            height: 53px;
            font-size: 16px;
            color: ${props => props.theme.colors.expertiseGray};
            margin-bottom: 20px;
            width: 100%;
        }
        
        span {
            width: 100%;
            display: flex;
            align-self: center;
            justify-content: flex-start;
            font-size: 15px;
            color: ${props => props.theme.colors.expertiseGray};
            margin-bottom: 13px;
        }
        
        svg {
            margin-right: 12px;
            margin-top: 1px;
            position: relative;
            top: 1px;
        }
        
        button {
            width: 100%;
        }
    }
    
    h2 {
        font-size: 22px;
        line-height: 26px;
        font-weight: 600;
        color: ${props => props.theme.colors.expertiseGray};
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 60px;
        
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            display: none;
        }
    }
    
    p {
        font-size: 14px;
        padding-top: 10px;
        padding-right: 100px;
        color: ${props => props.theme.colors.expertiseGray};
    
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            display: none;
        }
    }
    
    .mobile-text {
        display: none;
        
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            padding: 15px !important;
            display: block !important;
        }
    }
    
    .inner {
        position: relative
    }
    
    .modalClose {
        right: 0;
        font-size: 13px;
        color: ${props => props.theme.colors.expertiseGray};
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: absolute;
        
        &:after {
            content: "";
            background: url("../images/modal-close.svg");
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-left: 5px;
        }
        
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            top: 0;
            right: 10px;
        }
    }
    
    .error {
        font-size: 14px;
        color: ${props => props.theme.colors.errorRed};
        text-align: center;
        margin-top: 10px;
        padding: 0 10px;
        margin-bottom: 0;
        width: 100%;
    }
    
    .success {
        font-size: 14px;
        color: ${props => props.theme.colors.exploratoryGreen};
        text-align: center;
        margin-top: 10px;
        padding: 0 10px;
        margin-bottom: 0;
        width: 100%;
    }
    
    .button-group {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
        padding-top: 15px;
    
        button {
            font-size: 16px;
        }
    
        button:nth-of-type(1) {
            margin-right: 5px;
        }
    }  
`;


export const ModalSign = styled.div`
    .modalContent {
        margin-top: 20px;
        padding: 0 20px 20px 0;
        
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            margin-top: 0;
            padding: 0 0 20px 0;
        }
    }
    
    h2 {
        font-size: 22px;
        line-height: 26px;
        font-weight: 600;
        color: ${props => props.theme.colors.expertiseGray};
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 60px;
    
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            display: none;
        }
    }
    
    h3 {
        font-size: 26px;
        margin-bottom: 0;
        margin-top: 10px;
        color: ${props => props.theme.colors.exploratoryGreen};
        text-align: center;
        
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            text-align: center;
            padding-top: 75px;
        }
        
        @media screen and (max-width: ${props => props.theme.breakpoints.smallMobile}) {
            padding-top: 30px;
        }
    }
    
    p {
        font-size: 14px;
        padding-top: 10px;
        color: ${props => props.theme.colors.expertiseGray};
        text-align: center;
    
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            display: none;
        }
    }
    
    .mobile-text {
        display: none;
        text-align: center;
        margin: 0;
        
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            padding: 15px !important;
            display: block !important;
        }
    }
    
    .inner {
        position: relative;
    }
    
    .modalClose {
        top: 75px;
        right: 20px;
        font-size: 13px;
        color: ${props => props.theme.colors.expertiseGray};
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: absolute;
    
        &:after {
            content: "";
            background: url("../images/modal-close.svg");
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-left: 5px;
        }
        
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            top: 0;
            right: 10px;
        }
    }
    
    .error {
        font-size: 12px;
        color: ${props => props.theme.colors.errorRed};
        text-align: center;
        margin-top: 10px;
        padding: 0 10px;
    }
    
    .button-group {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
        padding-top: 15px;
    
        button {
            margin-top: 10px;
            font-size: 16px;
            
            @media screen and (max-width: ${props => props.theme.breakpoints.smallMobile}) {
                min-width: auto;
            }
        }
        
        button:nth-of-type(1) {
            margin-right: 5px;
        }
    }
    
    .landscape-info {
        display: none;
        pointer-events: none;
        color: ${props => props.theme.colors.expertiseGray};
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
        padding: 0 10px;

        img {
            display: block;
            margin-bottom: 5px;
        }
    
        @media screen and (max-width: ${props => props.theme.breakpoints.smallMobile}) {
            display: flex;
        }
    }

    .signature-pad-container {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 375px;
    
        @media screen and (max-width: ${props => props.theme.breakpoints.smallMobile}) {
            width: 100%;
        }		
    }		
`;


export const ModalPreviewMail = styled.div`
    .modalContent {
        margin-top: 20px;
        padding: 0 0 20px 0;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            margin-top: 0;
        }
    
        h3 {
            color: ${props => props.theme.colors.exploratoryGreen};
            font-size: 26px;
            margin-bottom: 25px; 
            text-align: center;
            padding: 40px 20px 0 20px; 
            
            @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                padding: 0;
                text-align: left;
            }   
        }
    }
    
    .modalPreviewMailInner {
        background-color: #fff;
        padding: 20px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            padding: 30px 40px 1px;
        }
        
        form {
            input, textarea, p {
                color: #7c7c7c;
                width: 100%;
                @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
                    max-width: 530px;
                }
                
                span {
                  text-decoration: underline;
                }
                
                a {
                  &:link, &:visited {
                    color: inherit;
                  }
                  &:hover {
                    text-decoration: none;
                  }
                }
            }
        }
    }
    
    .inner {
        position: relative
    }
    
    .modalClose {
        right: 0;
        font-size: 13px;
        color: ${props => props.theme.colors.expertiseGray};
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: absolute;
        
        &:after {
            content: "";
            background: url("../images/modal-close.svg");
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-left: 5px;
        }
        
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
            top: 0;
            right: 10px;
        }
    }
`;