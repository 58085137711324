const now = Math.round((new Date()).getTime() / 1000);

const getToken = () => {
    if (window.localStorage) {
        if(window.localStorage.getItem("token")) {
            if(now > window.localStorage.getItem("tokenExpiration")) {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("tokenExpiration");
            }
            return window.localStorage.getItem("token");
        }
    }
    return null;
}

const storeToken = (token) => {
    if (window.localStorage) {
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("tokenExpiration", now + 86400 * 2);
    }
}

export {
    getToken,
    storeToken
}