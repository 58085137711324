import styled from "styled-components";

export const DocumentsFormWrapper = styled.div`
	p {
		font-size: 15px;
		margin-bottom: 1em;
	}

	.section {
		padding: 24px 40px;
		border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
		ul {
			list-style: none;
			margin: 0 0 1em 0;
			color: ${props => props.theme.colors.exploratoryGreen};

			li {
          display: flex;
				position: relative;
				padding-left: 19px;
				margin-bottom: 0.1rem;
				font-size: 20px;

				&:after {
					content: "";
					position: absolute;
					top: 9px;
					left: 0;
					width: 10px;
					height: 10px;
					border-radius: 9999px;
					background-color: ${props => props.theme.colors.resourceGreen};
				}
			}
		}

		h3 {
			max-width: 600px;
			line-height: 1.3;
			font-weight: 400;
			color: ${props => props.theme.colors.exploratoryGreen};
		}
	}

	.control {
		display: flex;
		justify-content: center;
		padding: 40px 0 20px;
	}
    
    @media screen and (max-width: 500px) {
        .section {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;
