import React, {useState, useRef, useEffect} from "react";
import Signature from "signature_pad/dist/signature_pad";

import {VALID_SIGN_SIZE_PERCENTAGE} from "../configs";
import isIOS from "../helpers/isIOS";
import {SignaturePad as SignaturePadStyle} from "../style/signaturePad";
import {Button} from "../style/button";

let pad = null;
const SignaturePad = ({orientation, signMutation, mutationLoading}) => {
    const [data, setData] = useState(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [canvasWidth, setCanvasWidth] = useState(false);
    const signaturePad = useRef();

    const clear = () => {
        pad.clear();

        setIsEmpty(true);
        setIsValid(false);
    };

    const checkValid = () => {
        let canvas = signaturePad.current;
        let ctx = canvas.getContext("2d");
        let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let pixels = imgData.data;

        let whitePixelCount = 0;
        let allPixelCount = canvas.width * canvas.height;

        for (let index = 0; index < pixels.length; index += 4) {
            if (pixels[index] === 0 && pixels[index + 1] === 0 && pixels[index + 2] === 0) {
                whitePixelCount++;
            }
        }

        let percentage = (allPixelCount - whitePixelCount) / allPixelCount * 100;

        setIsValid(percentage >= VALID_SIGN_SIZE_PERCENTAGE);
        setData(pad.toDataURL());
    }

    useEffect(() => {
        function resize() {
            let canvas = signaturePad.current;
            const padData = !!pad && pad.toData();

            if (canvasWidth !== canvas.offsetWidth) {
                if (isIOS()) {
                    setTimeout(() => {
                        handleCanvasScale(canvas);
                        !!padData && pad.fromData(padData);
                        checkValid();
                    }, 100);
                } else {
                    handleCanvasScale(canvas);
                }
            }

            setCanvasWidth(canvas.offsetWidth);
        }

        function handleCanvasScale(canvas) {
            let ratio = Math.max(window.devicePixelRatio || 1, 1);

            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext("2d").scale(ratio, ratio);

            pad.clear();

            setIsEmpty(true);
            setIsValid(false);
            setCanvasWidth(canvas.offsetWidth);
        }

        pad = new Signature(signaturePad.current, {
            penColor: "rgb(0, 15, 85)"
        });

        pad.onEnd = checkValid;

        window.addEventListener("resize", resize);
        resize();

        return function cleanup() {
            pad.off();

            window.removeEventListener("resize", resize);
        };
    }, []); // eslint-disable-line

    useEffect(() => { // eslint-disable-line
        if (isEmpty && !pad.isEmpty()) {
            setIsEmpty(false);
        }
    });

    return <div className="signature-pad-container">
        <SignaturePadStyle>
            <div className="signature-pad">
                <canvas ref={signaturePad}/>
            </div>
            <span className="sign-label">Podpis zákazníka</span>
        </SignaturePadStyle>
        {!isValid && !isEmpty && <div className="error">Zdá se, že podpis není kompletní. Prosíme upravte jej.</div>}
        
        <p className={"signature-pad-tip"}>
            <strong>Tip:</strong> K podpisu můžete využít i telefon s dotykovou obrazovkou. 
            <br />Odkaz na podpis jsme vám zaslali SMS zprávou.
        </p>
        
        <div className="button-group">
            <Button
              disabled={isEmpty ? "disabled" : ""}
              autoWidth={true}
              color="white"
              variant="small"
              onClick={() => clear()}
            >
                Upravit podpis
            </Button>
            <Button
              disabled={isEmpty || !isValid || mutationLoading ? "disabled" : ""}
              autoWidth={true}
              variant="small"
              onClick={() => {
                  signMutation({
                      variables: {
                          "signature": data
                      }
                  });
              }}
            >{mutationLoading ? "Načítání..." : "Použít podpis"}</Button>
        </div>
        {orientation === "portrait" && <span className="landscape-info">
            <img src="/images/landscape-info-icon.svg" alt="Otočte telefon" />
            Otočte telefon pro větší prostor na podpis (bude nutné znovu podepsat).
        </span>}
    </div>;
};

export default SignaturePad;
