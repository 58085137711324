import { useState, useCallback, useMemo } from "react";

const useToggle = () => {
    const [status, setStatus] = useState(true);
    const toggleStatus = useCallback(() => {
        setStatus((prevStatus) => !prevStatus);
    }, []);
    return useMemo(
        () => ({
            status,
            toggleStatus
        }),
        [status, toggleStatus]
    );
};

export default useToggle