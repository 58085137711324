import gql from 'graphql-tag';

export default gql`
    mutation SAVE_CONTRACT($url: String!) {
        saveContract(
            url: $url
        ) {
            id
        }
    }
`;
