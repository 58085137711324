import styled from "styled-components";

export const FieldStyled = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-direction: column;
    flex-wrap: wrap;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        flex-direction: row;
    }
    
    & > div {
        background: transparent
    }
    
    label {
        align-self: ${props => props.textarea ? "flex-start" : "center"};
        text-align: left;
        width: 100%;
        padding-right: 0;
        margin-bottom: 5px;
        white-space: nowrap;
        user-select: none;
        cursor: pointer;
        color: ${props => props.theme.colors.exploratoryGreen}; 
        font-weight: 600;
        font-size: 14px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            text-align: right;
            padding-right: 17px;
            margin-bottom: 0;
            width: 6em;
        }
    }
    
    input, textarea, p {
        width: 100%;
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            max-width: 350px;
        }
        
        background-color: ${props => props.background ? "#E6E6E7" : "none"};
        padding: ${props => props.background ? "10px" : "none"};
    }
    
    p {
        margin-bottom: 0;
    }
    
    textarea {
        width: 100%;
        height: 320px;
        font-size: 15px;
        line-height: 24px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
            max-width: 580px;
        }
    }
    
    .error {
        color: ${props => props.theme.colors.errorRed};
        font-size: 14px;
        text-align: left;
        flex-grow: 1;
        width: 100%;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            width: auto;
            text-align: right;
            padding-left: 15px;
        }
        
        @media screen and (min-width: ${props => props.theme.breakpoints.container}) {
            text-align: left;
        }
    }
`;
