import React from "react";
import {SideBox as SideBoxStyle, SideBoxContainer as SideBoxContainerStyle} from "../style/sideBox";

const SideBoxSupplyPoint = ({
                                supplyPoints,
                                mt,
                                top,
                            }) => {
    return (
        <SideBoxContainerStyle mt={mt} top={top}>
            <SideBoxStyle>
                <div className="mainLine">
                    <div>
                        {supplyPoints.map((supplyPoint, i) => <div
                            style={{marginBottom: supplyPoints.length > 1 ? "10px" : 0}}>
                            <p className="sub-label">Adresa odběrného
                                místa{supplyPoints.length > 1 ? ` č. ${i + 1} ` : ""}:</p>
                            <p className="label">{supplyPoint.address.street}&nbsp;{supplyPoint.address.houseNumber}{supplyPoint.address.streetNumber ? `/${supplyPoint.address.streetNumber}` : ''}<br/>
                                {supplyPoint.address.zipCode ? `${supplyPoint.address.zipCode.substr(0, 3)} ${supplyPoint.address.zipCode.substr(3, 2)}` : ""} {supplyPoint.address.city}
                            </p>
                        </div>)}
                    </div>
                </div>

            </SideBoxStyle>
        </SideBoxContainerStyle>
    );
};

export default SideBoxSupplyPoint;
