export default {
    property_purchase: {
        label: "Koupě nemovitosti",
        key: "property_purchase"
    },
    property_lease: {
        label: "Pronájem nemovitosti",
        key: "property_lease"
    },
    other_reason: {
        label: "Jiný důvod",
        key: "other_reason"
    }
};
