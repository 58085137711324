import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from '@apollo/react-hooks';
import {Redirect, useHistory} from "react-router-dom";

import {ERROR_ROUTE, getOnlineSignConfirmationRoute, ONLINE_SIGN_ROUTE, signatureStates} from "../configs/routes";
import StepNavigation from "../components/StepNavigation";
import AccordionItem from "../components/AccordionItem";
import ModalSign from "../components/ModalSign";
import Loading from "../components/Loading";
import SideBoxRecap from "../components/SideBoxRecap";
import {filterAccordionConfig, getAccordionConfig} from "../configs/accordion";

import REQUEST_TO_SIGN from "../graphql/mutations/requestToSign";
import SIGN_DOCUMENTS from "../graphql/mutations/signDocuments";

import {Container, Inner, WhiteBox, Wrapper} from "../style/grid";
import {PageHeader} from "../style/pageHeader";
import GET_CONTRACT from "../graphql/queries/getContract";
import {VIEWER_PROCESS_SIGNATURE_PM} from "../configs";
import GET_SHORT_URL from "../graphql/queries/getShortUrl";
import getStepsConfig, {SIGN_DOCUMENTS_STAGE} from "../helpers/getStepsConfig";
import { CUSTOM, SIGN_CONTRACT_AND_WARRANT } from "../configs/documentRequestType";
import { getFormatRequestDocuments, logSignatureStepEvent } from "../utils/analytics";
import { COMMODITY_PURCHASE } from "../configs/commodity";
import { SOLUS_CONSENT_STATE_GRANTED_UNVERIFIED } from "../configs/solusConsentType";


const getStepByMatch = match => match.path.substring(match.path.length-1);

const isOnlineSignatureInitiated = ({contract}) => {
    return contract.activeDocumentRequest.signatureState !== signatureStates.NONE &&
        contract.activeDocumentRequest.signatureState !== null
}

const getData = (contractData) => {


    return {
        products: contractData.contract.products,
        documents: contractData.contract.activeDocumentRequest.documents,
        configuration: contractData.contract.activeDocumentRequest.configuration,
    };
}


const OnlineSignContainer = ({viewer, match}) => {
    const [modalOpen, setModal] = useState(false);
    const history = useHistory();
    const {loading: loadingContract, error: errorContract, data: dataContract} = useQuery(GET_CONTRACT);
    const {loading: shortUrlLoading, data: shortUrlData} = useQuery(GET_SHORT_URL, {
        variables: {
            role: viewer.type,
            process: viewer.process
        }
    })

    const [
        signDocuments,
        {loading: mutationLoading, error: mutationError},
    ] = useMutation(SIGN_DOCUMENTS, {
        onCompleted() {
            history.push(getOnlineSignConfirmationRoute());
        }
    });

    const [requestToSign, {data, loading, error}] = useMutation(REQUEST_TO_SIGN, {});

    useEffect(() => {
        const requestedDocument = getFormatRequestDocuments(dataContract?.contract?.activeDocumentRequest);

        const additionalSignatureEventData = {
            step: getStepByMatch(match),
            name: "Dokumenty",
            text: "Pokračovat",
            type: "Signature",
            situationType: dataContract?.contract?.situation,
            ...(requestedDocument ? { requestedDocument } : {})
        };

        logSignatureStepEvent(additionalSignatureEventData);
    }, []);

    useEffect(() => {
        if (shortUrlLoading || loadingContract || isOnlineSignatureInitiated(dataContract)) {
            return;
        }
        (async () => {
            await requestToSign({
                    variables: {
                        "url": shortUrlData.contract.shortUrl
                    }
                }
            )
        })();

    }, [shortUrlLoading]); // eslint-disable-line


    if (loading || loadingContract || typeof dataContract === 'undefined' || (typeof data === 'undefined' && !isOnlineSignatureInitiated(dataContract))) return <Loading/>;
    if (error || errorContract || mutationError) return <Redirect to={`/${ERROR_ROUTE}`}/>;

    const {products, documents, configuration} = getData(dataContract, data);

    const isDocumentRequestTypeCustom = dataContract.contract?.activeDocumentRequest?.type === CUSTOM;

    const accordion = filterAccordionConfig(
        getAccordionConfig(),
        configuration, documents);

    const savingEnabled = !isDocumentRequestTypeCustom;
    const shouldShowLinkForUnverifiedConsent = dataContract.contract?.activeDocumentRequest?.type === SIGN_CONTRACT_AND_WARRANT
        && dataContract.contract?.activeDocumentRequest.commodity !== COMMODITY_PURCHASE
        && dataContract.contract?.solusConsentState === SOLUS_CONSENT_STATE_GRANTED_UNVERIFIED;

    return (
        <Wrapper>
            <Container>
                <StepNavigation
                    {...getStepsConfig(dataContract.contract.activeDocumentRequest, {
                        forceStage: 0,
                        forceStageName: SIGN_DOCUMENTS_STAGE
                    })}
                />
                <Inner>
                    <WhiteBox>
                        <PageHeader>
                            <h1>Vaše dokumenty jsou připraveny k podpisu</h1>
                            <p>Prohlédněte si je a podepište on-line</p>
                        </PageHeader>
                        <div>
                            {accordion.map((item, index) =>
                                <AccordionItem
                                    key={index}
                                    item={item}
                                    index={index}
                                    route={ONLINE_SIGN_ROUTE}
                                    accordionLength={accordion.length - 1}
                                    document={documents.filter(document => document.category === item.documentType)[0]}
                                    setModal={setModal}
                                    priceListLink={dataContract.contract.pricelistLink}
                                    shouldShowLinkForUnverifiedConsent={shouldShowLinkForUnverifiedConsent}
                                    shouldShowPriceListLink={dataContract.contract.pricelistLink && !isDocumentRequestTypeCustom}
                                />
                            )}
                        </div>
                        <ModalSign
                            modalOpen={modalOpen}
                            setModal={setModal}
                            mutationLoading={mutationLoading}
                            signMutation={signDocuments}
                        />
                    </WhiteBox>
                    {viewer.process !== VIEWER_PROCESS_SIGNATURE_PM && products.map((product, i) =>
                        <SideBoxRecap
                            tariffLabel={product.label}
                            tariffName={product.name}
                            consumption={product.consumption}
                            price={product.priceYear}
                            onlineDiscount={product.reduction}
                            supplyPoints={dataContract.contract.supplyPoints}
                            savingEnabled={savingEnabled}
                            key={i}
                        />)
                    }
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default OnlineSignContainer;
