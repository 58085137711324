import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`
    mutation APPROVE_SIGNED_DOCUMENTS {
        approveSignedDocuments {
            ...ContractFields
        }
    }
    ${contractFragment}
`;
