import {DOCUMENT_REQUEST_STATE_NONE, VIEWER_TYPE_OWNER, VIEWER_TYPE_PARTNER, VIEWER_TYPE_THIRD_PARTY} from "./index";


export const ONLINE_SIGN_ROUTE = "online-podpis";
export const ONLINE_SIGN_POA_ROUTE = "online-poa-podpis";
export const SEND_POA = "odeslani-poa";
export const ADD_DOCUMENTS = "doplneni-dokumentu";
export const ERROR_ROUTE = "chyba";

export const states = {
    SIGN: "before_sign",
    SIGN_CONFIRMATION: "before_sign_confirm",
    DOCUMENTS_UPLOAD: "before_document_completed",
    COMPLETE: "completed",
};

export const signatureStates = {
    NONE: "none",
    SENT_FOR_SIGNATURE: "sent_for_signature",
    WAITING_FOR_SIGNATURE_CONFIRMATION: "waiting_for_signature_confirmation",
    SIGNATURE_CONFIRMED: "signature_confirmed",
}

const addRoute = (routes, route) => {
    return [...routes, `/${route}`];
}

const addSteps = (routes, prefix, count) => {
    let index = 1;

    return [...routes, ...(Array.from(Array(count)).map(_ => {
        return `/${prefix}/${index++}`;
    }))];
}


export const getAvailableSignatureRoutes = ({role, signatureState, hasParametersToFill}) => {
    let routes = [];

    const prefix = ONLINE_SIGN_ROUTE;

    if(signatureState === DOCUMENT_REQUEST_STATE_NONE) {
        return routes;
    }

    routes = addRoute(routes, ERROR_ROUTE);

    if(role !== VIEWER_TYPE_OWNER && role !== VIEWER_TYPE_PARTNER) {
        return routes;
    }


    switch (signatureState) {
        case signatureStates.SENT_FOR_SIGNATURE:
            if(hasParametersToFill){
                routes = addRoute(routes, `doplneni-udaju`);
            }
            routes = addSteps(routes, prefix, 6);
            break;
        case signatureStates.WAITING_FOR_SIGNATURE_CONFIRMATION:
            if(hasParametersToFill){
                routes = addRoute(routes, `doplneni-udaju`);
            }
            routes = addSteps(routes, prefix, 6);
            routes = addRoute(routes, `${prefix}-potvrzeni`);
            break;
        case signatureStates.SIGNATURE_CONFIRMED:
            routes = addRoute(routes, `${prefix}-dekujeme`);
            break;
        default:
    }

    return routes;
};




export const getAvailableSignatureRoutesPoa = ({role, signatureState, hasData}) => {
    const prefix = ONLINE_SIGN_POA_ROUTE;

    let routes = [];
    routes = addRoute(routes, ERROR_ROUTE);

    if(role !== VIEWER_TYPE_THIRD_PARTY) {
        return routes;
    }


    switch (signatureState) {
        case signatureStates.NONE:
        case signatureStates.SENT_FOR_SIGNATURE:
            if(hasData) {
                routes = addSteps(routes, prefix, 3);
            } else {
                routes = addSteps(routes, prefix, 1);
            }
            break;
        case signatureStates.WAITING_FOR_SIGNATURE_CONFIRMATION:
            routes = addSteps(routes, prefix, 3);
            routes = addRoute(routes, `${prefix}-potvrzeni`);
            break;
        case signatureStates.SIGNATURE_CONFIRMED:
            routes = addRoute(routes, `${prefix}-dekujeme`);
            break;
        default:
    }

    return routes;
};

export const getAvailableDocumentRoutes = ({role, documentsState, signatureState}) => {
    let routes = [];
    routes = addRoute(routes, ERROR_ROUTE);

    switch (documentsState) {
        case states.DOCUMENTS_UPLOAD:
            routes = addRoute(routes, ADD_DOCUMENTS);
            routes = addRoute(routes, `${ONLINE_SIGN_ROUTE}-dekujeme`);
            routes = addRoute(routes, SEND_POA);
            break;
        case states.COMPLETE:
            routes = addRoute(routes, "kompletni")
            break;
        default:
    }
    return routes;
};

export const getOnlineSignConfirmationRoute = () => {
    return `/${ONLINE_SIGN_ROUTE}-potvrzeni`;
}

export const getDocumentsAddRoute = () => {
    return `/${ADD_DOCUMENTS}`;
}

export const getDocumentsFinishedRoute = () => {
    return `/kompletni`;
}

export const getSignatureRoute = () => {
    return `/${ONLINE_SIGN_ROUTE}/1`;
}

export const getThirdPartyDataCompletedRoute = () => {
    return `/${ONLINE_SIGN_POA_ROUTE}/2`;
}

export const getThirdPartyOnlineSignConfirmationRoute = () => {
    return `/${ONLINE_SIGN_POA_ROUTE}-potvrzeni`;
}





