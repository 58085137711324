import styled from "styled-components";

export const SignaturePad = styled.div`
    width: 100%;
    background: ${props => props.theme.colors.white};
    padding: 17px 15px 0 15px;
    
    .signature-pad {
        width: 100%;
        height: 175px;
        border: 2px dashed ${props => props.theme.colors.resourceGreen};
    
        canvas {
            width: 100%;
            height: 100%;
        }
    
        span {
            display: inline-block;
            margin-top: 5px;
            margin-bottom: 10px;
            color: ${props => props.theme.colors.expertiseGray};
            font-weight: 600;
            font-size: 14px;
        }
    }

    .signature-pad-tip {
        margin: 20px -15px 0;
        font-size: 13px;
        text-align: center;
        color: ${props => props.theme.colors.exploratoryGreen};
        
        strong {
            font-weight: 600;
        }
    }
  
    .sign-label {
        display: inline-block;
        padding-top: 8px;
        padding-bottom: 11px;
        font-size: 14px;
        color: ${props => props.theme.colors.exploratoryGreen};
        font-weight: 600;
    }
`;
