import React from "react";
import {useMediaQuery} from "react-responsive";
import StepNavigation from "../../components/StepNavigation";
import SubSituations from "../../components/SubSituations";
import ModalQrLink from "../../components/ModalQrLink";
import {Button} from "../../style/button";
import Loading from "../../components/Loading";
import ModalPreview from "../../components/ModalPreview";
import SideBoxOwner from "../../components/SideBoxOwner";
import theme from "../../style/theme";
import {PageHeaderDocuments} from "../../style/pageHeaderDocuments";
import {ButtonWrapperDocuments, Container, Inner, WhiteBox, Wrapper} from "../../style/grid";

import {getLabel} from "../../helpers/getLabel";
import { CUSTOM } from "../../configs/documentRequestType";
import { COMMODITY_PURCHASE } from "../../configs/commodity";


const UploadDocumentsComp = (props) => {
    const isMobileScreen = useMediaQuery({query: `(max-width: ${theme.breakpoints.mobile})`});

    const savingEnabled = !(props.contract?.activeDocumentRequest?.type === CUSTOM);

    const isPurchaseLead = props.contract?.commodity === COMMODITY_PURCHASE;

    const title = isPurchaseLead ? <>Máte aktuálně řešen výkup<br className="no-small-mobile" /> u&nbsp;jiného dodavatele?</> : <>Pro přechod k MND je třeba doplnit<br className="no-mobile"/> dokumenty o vztahu k nemovitosti</>;
 
    return (
        <Wrapper>
            <Container>
                <StepNavigation {...props.stepsNavigationConfig} />
                <Inner>
                    <WhiteBox>
                        <PageHeaderDocuments>
                            {props.header.variant === "supplier_change" ?
                                <h1 className="bold">Urychlete převod svých energií</h1> :
                                <h1>{title}</h1>}
                            {props.subSituations.show && <h2>Proč {getLabel("merak")} převádíte?</h2>}
                            {props.subSituations.show && <p>{getLabel("merak", true)} převádím z důvodu:</p>}
                        </PageHeaderDocuments>

                        {props.subSituations.show && <SubSituations
                            contract={props.contract}
                            subSituations={props.subSituations.data}
                            changeSubSituation={props.subSituations.changeSubSituation}
                            selectedSubSituation={props.subSituations.selectedSubSituation}
                        />}

                        {props.uploads.show &&
                        <>
                            <PageHeaderDocuments
                                variant={props.uploads.showBottomBorder ? "bottomBorder" : "border"}>
                                {props.uploads.heading && <h2>{props.uploads.heading}</h2>}
                            </PageHeaderDocuments>


                            {props.uploads.loading ? <Loading/> : props.uploads.components.map(item => item.component)}

                            <ModalQrLink
                                modalOpen={props.modal.modalOpen}
                                setModal={props.modal.setModal}
                                mutationLoading={props.modal.sendToMobileLoading}
                                mutation={props.modal.sendToMobile}
                                viewerRole={props.modal.dataViewer.viewer.type}
                            />
                            <ModalPreview
                                modalPreview={props.modal.modalPreview}
                                setModalPreview={props.modal.setModalPreview}
                            />
                            <ButtonWrapperDocuments>
                                {!isMobileScreen && savingEnabled &&
                                <Button
                                    align="center"
                                    onClick={props.action.secondaryAction}
                                >
                                    Uložit a dokončit později
                                </Button>
                                }
                                <Button
                                    align="center"
                                    disabled={props.action.primaryButtonDisabled}
                                    onClick={props.action.primaryAction}
                                    color={"exploratoryGreen"}
                                >
                                    {props.action.primaryButtonLabel}
                                    {props.action.primaryButtonTooltip &&
                                    <div className={"tooltip"}>
                                        {props.action.primaryButtonTooltip}
                                    </div>}
                                </Button>
                            </ButtonWrapperDocuments>
                        </>}

                    </WhiteBox>
                    <SideBoxOwner
                        showTitle={props.sideBar.showTitle}
                        {...props.sideBar.newOwner}
                        isMessageOpen={props.message.isMessageOpen}
                        setMessage={props.message.setMessage}
                        mt={"-13px"}
                        savingEnabled={props.sideBar.newOwner.savingEnabled && savingEnabled || false}
                    />
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default UploadDocumentsComp;
