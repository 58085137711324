import styled from "styled-components";

export const Separator = styled.hr`
    width: 100%;
    background: ${props => props.theme.colors.lightGrey};
    margin-top: 20px;
    margin-bottom: 20px;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        margin-top: ${props => props.my ? props.my : 0};
        margin-bottom: ${props => props.my ? props.my : 0};
    }
`;
