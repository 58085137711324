import styled from "styled-components";

export const OnlineSignPoaThanks = styled.div`   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    img {
        margin-top: 10px;
        width: 100%;
        
        @media screen and (min-width: 400px) {
            max-width: 300px;
        } 
    }
    
    h1 {
        text-align: center;
        font-size: 26px;
        line-height: 36px;
        color: ${props => props.theme.colors.exploratoryGreen};    
        margin-bottom: 10px;
    
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            line-height: 49px;
            font-size: 36px;
        }    
    }
    
    h2 {
        text-align: center;
        font-size: 20px;
        line-height: 26px;
        color: ${props => props.theme.colors.exploratoryGreen};    
        margin-bottom: 10px;
    
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            font-size: 26px;
            line-height: 36px;
        }    
    }
    
    p {
        color: ${props => props.theme.colors.expertiseGray};
        text-align: center;
        max-width: 617px;
        margin: 0;
        font-size: 16px;
    }
    
    .infoLink {
        font-weight: normal;
    }
`;
