import React from "react";
import Modal from "react-modal";
import classNames from "classnames";

import {FormStyled} from "../style/FormStyled";
import {FieldStyled} from "../style/FieldStyled";
import {ModalPreviewMail as ModalPreviewMailStyle} from "../style/modal";

import ShortUrlContainer from "../containers/ShortUrlContainer";

import {VIEWER_TYPE_THIRD_PARTY} from "../configs";

const ModalPreviewMail = ({modalOpen, setModal, email, name}) => {

    const handleClose = () => {
        setModal(false);
    };

    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={() => handleClose()}
            contentLabel="Co přijde původnímu majiteli?"
            className={classNames([
                "modal",
                "modalPreviewMail"
            ])}
            overlayClassName="modalOverlay"
            shouldCloseOnOverlayClick={true}
        >
            <ModalPreviewMailStyle>
                <div className="inner">
                    <span className="modalClose" onClick={() => handleClose()}>Zavřít</span>
                    <div className="modalContent">
                        <h3>Co přijde původnímu majiteli?</h3>
                        <div className="modalPreviewMailInner">
                            <p>Znění e-mailu, který přijde původnímu majiteli:</p>
                            <FormStyled>

                                <FieldStyled background>
                                    <label>Od</label>
                                    <p>{email}</p>
                                </FieldStyled>

                                <FieldStyled background>
                                    <label>Předmět</label>
                                    <p>Udělte prosím plnou moc pro převod plynoměru / elektroměru</p>
                                </FieldStyled>

                                <FieldStyled textarea background>
                                    <label>Zpráva</label>
                                    <p>
                                        Dobrý den,
                                        <br/>
                                        <br/>
                                        pro dokončení přepisu plynoměru / elektroměru je nutné podepsat plnou moc pro tento úkon společnosti MND.
                                        <br/>
                                        <br/>
                                        <ShortUrlContainer role={VIEWER_TYPE_THIRD_PARTY}>
                                            {link => <a href={link} target="_blank" rel="noopener noreferrer">
                                                Plnou moc prosím doplňte a podepište online na tomto odkazu.
                                            </a>}
                                        </ShortUrlContainer>

                                        <br/>
                                        <br/>
                                        Pokud se Vám na odkaz nedaří kliknout, zkopírujte do prohlížeče:
                                        <br/>
                                        <ShortUrlContainer role={VIEWER_TYPE_THIRD_PARTY}>
                                            {link => <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>}
                                        </ShortUrlContainer>
                                        <br/>
                                        <br/>
                                        Předem děkuji za rychlé vyřízení.
                                        <br/>
                                        <br/>
                                        S pozdravem,
                                        <br/>
                                        Vaše jméno
                                    </p>
                                </FieldStyled>
                            </FormStyled>
                        </div>
                    </div>
                </div>
            </ModalPreviewMailStyle>
        </Modal>
    );
};

export default ModalPreviewMail;
