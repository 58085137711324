import styled from "styled-components";

export const CopySection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: 20px;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        margin-top: 40px;
    }
    
    h3 {
        font-weight: 600;
        font-size: 12px;
        color: ${props => props.theme.colors.expertiseGray};
        margin-bottom: 15px;
        text-transform: uppercase;
    }
    
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        height: 40px;
        min-width: 200px;
        color: ${props => props.theme.colors.exploratoryGreen};     
        text-align: center;
        font-weight: bold;
        max-width: 350px;
        width: 100%;
        background: ${props => props.theme.colors.lightGrey}; 
    }
    
    p {
        color: ${props => props.theme.colors.white};
        font-size: 15px;
        margin-bottom: 10px;
    }
    
    input {
        max-width: 250px;
        color: ${props => props.theme.colors.exploratoryGreen};
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        background: ${props => props.theme.colors.lightGrey};
        margin-right: 5px;
        padding: 6px 6px;
        height: 50px;
        border: none;
        text-align: center;
        direction: rtl;
        
        &:focus {
            outline: 0;
        }
    }
`;
