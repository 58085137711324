import React from "react";
import { Radio } from "../style/radio";

const RadioInputSection = ( {handleChange, values, radioInputName, radioInputs, errors} ) => {

    const radioInputsJSX = radioInputs.map((input) => {

        const inputValue = input.value;
        const inputLabel = input.label;

        return (
            <Radio>
                <label className={values[radioInputName] === inputValue ? "checked" : ""}>
                    <input type="radio"
                            value={inputValue}
                            onChange={handleChange}
                            name={radioInputName}
                            checked={values[radioInputName] === inputValue}
                    />
                    {inputLabel}
                    <span/>
                </label>
            </Radio>
        );
    });

    return (
        <>
            {radioInputsJSX}
            {errors[radioInputName] &&
                <div className="error" style={{color: "red"}}>{errors[radioInputName]}</div>}
        </>
    );

};

export default RadioInputSection;
