import React, {useState, useRef} from "react";
import Modal from "react-modal";
import classNames from "classnames";
import QRCode from "qrcode.react";
import {Button} from "../style/button";
import {ModalQrCode as ModalQrCodeStyle} from "../style/modal";
import CopyIcon from "../style/icons/copy";
import EmailIcon from "../style/icons/email";

import UpdateMessage from "./UpdateMessage";
import ShortUrlContainer from "../containers/ShortUrlContainer";

const ModalQrLink = ({modalOpen, setModal, link, mutation, mutationLoading, viewerRole}) => {
    const linkInput = useRef(null);
    const [isMessageOpen, setMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    const handleClose = () => {
        setModal(false);
        setMessage(true);
    };

    const handleSubmit = async () => {
        setSuccessMessage("Email odeslán");
        setErrorMessage("");
        return mutation({
            variables: {
                "url": window.location.href
            }
        });
    };

    const onCopyClick = () => {
        linkInput.current.select();
        linkInput.current.setSelectionRange(0, 99999);
        document.execCommand("copy");

        setIsCopied(true);
    };

    return (
        <>
            <Modal
                isOpen={modalOpen}
                onRequestClose={() => handleClose()}
                contentLabel="Pokračujte na jiném zařízení"
                className={classNames([
                    "modal",
                    "modalQrCode"
                ])}
                overlayClassName="modalOverlay"
                shouldCloseOnOverlayClick={false}
            >
                <ModalQrCodeStyle>
                    <div className="inner">
                        <span className="modalClose" onClick={() => handleClose()}>Zavřít</span>
                        <div className="modalContent">
                            <h3>Pokračujte na jiném zařízení</h3>
                            <div>
                                <div className="modalQrInner">
                                    <h3>Použijte odkaz nebo načtěte QR kód:</h3>
                                    <ShortUrlContainer role={viewerRole}>
                                        {(link) => <QRCode value={link} size={139} renderAs="svg"/>}
                                    </ShortUrlContainer>

                                    <div className="modalQrLink">
                                        <ShortUrlContainer role={viewerRole}>
                                            {(link) => <input type="text" readOnly value={ link.replace("https://", "") } ref={linkInput} />}
                                        </ShortUrlContainer>

                                        <Button
                                            data-analytics="click"
                                            data-name="CopyLink"
                                            data-type="Link"
                                            data-area="Page"
                                            onClick={() => onCopyClick()} variant="link">
                                            <CopyIcon/>
                                            {isCopied ? "Zkopírováno" : "Kopírovat"}
                                        </Button>
                                    </div>
                                    <div className="modalQrEmail">
                                <span>
                                    <EmailIcon/>
                                    Poslat na e-mail
                                </span>
                                        <Button
                                            disabled={mutationLoading ? "disabled" : ""}
                                            onClick={handleSubmit}
                                            data-analytics="click"
                                            data-name="Email"
                                            data-type="Button"
                                            data-area="Page"
                                        >
                                            {mutationLoading ? "Načítání..." : "Odeslat"}
                                        </Button>
                                        {errorMessage && <p className="error">{errorMessage}</p>}
                                        {successMessage && !mutationLoading && <p className="success">{successMessage}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalQrCodeStyle>
            </Modal>
            <UpdateMessage isOpen={isMessageOpen} setMessage={setMessage}/>
        </>
    );
};

export default ModalQrLink;
