import React from "react";

import isMobile from "../helpers/isMobile";
import {ExampleDocument as ExampleDocumentStyle} from "../style/exampleDocument";
import DownloadIcon from "../style/icons/download";

const ExampleDocument = ({link, title, label, preview}) => <ExampleDocumentStyle>
    <a
        href={link}
        target={isMobile() ? "_self" : "_blank"}
        className="image"
    >
        <img src={preview} alt="Dokument"/>
    </a>
    <div className="textRow">
        <span>Vzor</span>
        <p>{title}</p>
        <a
            href={link}
            className="link"
            target={isMobile() ? "_self" : "_blank"}
        >
            <DownloadIcon/>
            {label ? label : "Stáhnout formulář"}
        </a>
    </div>

</ExampleDocumentStyle>;

export default ExampleDocument;
