import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from '@apollo/react-hooks';
import {Redirect, useHistory} from "react-router-dom";

import {ERROR_ROUTE, getSignatureRoute} from "../configs/routes";
import Loading from "../components/Loading";
import GET_CONTRACT from "../graphql/queries/getContract";
import SAVE_PARAMS from "../graphql/mutations/saveParams";

import {Container, Inner, WhiteBox, Wrapper} from "../style/grid";
import {PageHeader} from "../style/pageHeader";
import ParamsForm from "../components/ParamsForm";
import StepNavigation from "../components/StepNavigation";
import getStepsConfig, {SAVE_PARAMS_STAGE} from "../helpers/getStepsConfig";
import SideBoxRecap from "../components/SideBoxRecap";
import { CUSTOM } from "../configs/documentRequestType";
import { getFormatRequestDocuments, logSignatureStepEvent } from "../utils/analytics";

const OnlineSignParamsContainer = () => {
    const history = useHistory();
    const {loading, error, data} = useQuery(GET_CONTRACT);
    const [isMessageOpen, setMessage] = useState(false);
    const [
        saveParams,
        { loading: saveParamsLoading, error: saveParamsError },
    ] = useMutation(SAVE_PARAMS, {
        onCompleted() {
            history.push(getSignatureRoute());
        }
    });

    useEffect(() => {
        const requestedDocument = getFormatRequestDocuments(data?.contract?.activeDocumentRequest);

        const additionalSignatureEventData = {
            step: 0,
            type: "Signature",
            name: "Doplnění údajů",
            text: "Pokračovat",
            situationType: data?.contract?.situation,
            ...(requestedDocument ? { requestedDocument } : {})
        };

        logSignatureStepEvent(additionalSignatureEventData);
    }, []);

    if(loading || saveParamsLoading) return <Loading/>;
    if(error || saveParamsError) return <Redirect to={`/${ERROR_ROUTE}`} />;

    const savingEnabled = !(data.contract?.activeDocumentRequest?.type === CUSTOM);

    return (
        <Wrapper>
            <Container>
                <StepNavigation {...getStepsConfig(data.contract.activeDocumentRequest, {forceStageName: SAVE_PARAMS_STAGE})} />
                <Inner pt="10px">
                    <WhiteBox>
                        <PageHeader>
                            <h1>Doplnění údajů</h1>
                        </PageHeader>
                        <ParamsForm
                            documentTypes={data.contract.activeDocumentRequest.documents.map(doc => doc.id)}
                            saveParams={saveParams}
                            mutationLoading={saveParamsLoading}
                            parameters={data.contract.activeDocumentRequest?.paramsPoa} />
                    </WhiteBox>
                    <SideBoxRecap
                        showTitle={false}
                        isMessageOpen={isMessageOpen}
                        setMessage={setMessage}
                        savingEnabled={savingEnabled}
                    />
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default OnlineSignParamsContainer;
