import gql from 'graphql-tag';

export default gql`
    mutation DELETE_DOCUMENT($id: String!) {
        deleteDocument(
            id: $id
        ) {
            id
        }
    }
`;
