import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from '@apollo/react-hooks';
import {Redirect} from "react-router-dom";

import SideBoxOwner from "../components/SideBoxOwner";
import Document from "../components/Document";
import ModalSign from "../components/ModalSign";
import Loading from "../components/Loading";

import {getPoaAccordionConfig} from "../configs/accordion";
import {DOCUMENT_TYPE_POA, SITUATION_SUPPLIER_CHANGE} from "../configs";
import {Container, Inner, WhiteBox, Wrapper} from "../style/grid";
import {OnlineSignConfirmation} from "../style/onlineSignConfirmation";
import GET_CONTRACT from "../graphql/queries/getContract";
import SIGN_DOCUMENTS from "../graphql/mutations/signDocuments";
import {Button} from "../style/button";
import {Link} from "../style/link";
import {PageHeader} from "../style/pageHeader";

import {ERROR_ROUTE} from "../configs/routes";
import APPROVE_SIGNED_DOCUMENTS from "../graphql/mutations/approveSignedDocuments";
import SideBoxSupplyPoint from "../components/SideBoxSupplyPoint";
import { logPoaEvent } from "../utils/analytics";

const OnlineSignPoaConfirmationContainer = () => {
    const {loading, error, data} = useQuery(GET_CONTRACT);
    const [modalOpen, setModal] = useState(false);

    const [
        approveSignedPOA,
        { loading: approveSignedPOALoading, error: approveSignedPOAError},
    ] = useMutation(APPROVE_SIGNED_DOCUMENTS, {});

    const [
        signPOA,
        { loading: signPOALoading, error: signPOAError },
    ] = useMutation(SIGN_DOCUMENTS, {
        onCompleted() {
            setModal(false);
        }
    });

    useEffect(() => {
        const additionalPodEventData = {
            step: 4,
            name: "Kontrola dokumentů",
            text: "Pokračovat",
        };

        logPoaEvent(additionalPodEventData);
    }, []);


    if(loading) return <Loading/>;
    if(error || approveSignedPOAError || signPOAError) return <Redirect to={`/${ERROR_ROUTE}`} />;

    const {contract: {situation, activeDocumentRequest: {documents}, person: newOwner }} = data;
    const document = documents.filter(document => document.category === DOCUMENT_TYPE_POA)[0];

    return (
        <Wrapper>
            <Container>
                <Inner>
                    <WhiteBox pt="10px">
                        <PageHeader>
                            <h1>Zkontrolujte a<br/>odešlete plnou moc</h1>
                            <p>Podpisy můžete ještě jednou zkontrolovat a případně opravit.</p>
                        </PageHeader>
                        <OnlineSignConfirmation>
                            <div className="documents">
                                <Document
                                    key={document.fileName}
                                    link={document.fileLinkSignedInline}
                                    downloadLink={document.fileLinkSigned}
                                    preview={getPoaAccordionConfig().filter(item => item.documentType === document.category)[0].documentPreview}
                                    label={document.fileName}
                                    linkLabel="Stáhnout kopii"
                                />
                            </div>
                            <Link onClick={() => setModal(true)}>Opravit podpisy</Link>
                            <Button
                                align="center"
                                disabled={approveSignedPOALoading ? "disabled" : ""}
                                onClick={() => {
                                    approveSignedPOA({
                                        variables: {}
                                    });
                                }}
                                mt="35px"
                            >{approveSignedPOALoading ? "Načítání..." : "Odeslat dokumenty"}</Button>
                        </OnlineSignConfirmation>
                        <ModalSign
                            modalOpen={modalOpen}
                            setModal={setModal}
                            mutationLoading={signPOALoading}
                            signMutation={signPOA}
                        />
                    </WhiteBox>
                    <SideBoxSupplyPoint
                        supplyPoints={data.contract?.supplyPoints}
                        top="0"
                    />
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default OnlineSignPoaConfirmationContainer;
