import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {Redirect, useHistory} from "react-router-dom";

import StepNavigation from "../components/StepNavigation";
import Loading from "../components/Loading";

import {DOCUMENT_REQUEST_STATE_COMPLETED, DOCUMENT_REQUEST_STATE_NONE, REDIRECT_TIMEOUT} from "../configs";
import {ERROR_ROUTE, getDocumentsAddRoute, getDocumentsFinishedRoute} from "../configs/routes";
import {Container, Inner, WhiteBox, Wrapper} from "../style/grid";
import {OnlineSignThanks} from "../style/onlineSignThanks";
import GET_CONTRACT from "../graphql/queries/getContract";
import SideBoxRecap from "../components/SideBoxRecap";
import {Separator} from "../style/separator";
import {Link} from "../style/link";
import FacebookIcon from "../style/icons/fb";
import {DocumentsThanks} from "../style/documentsThanks";
import getStepsConfig, {SIGN_DOCUMENTS_STAGE} from "../helpers/getStepsConfig";
import {CUSTOM} from "../configs/documentRequestType";
import { getFormatRequestDocuments, logSignatureStepEvent } from "../utils/analytics";

const shouldRedirectToDocuments = contract => {
    return contract && contract.activeDocumentRequest.documentsState !== DOCUMENT_REQUEST_STATE_NONE;
}

const OnlineSignThanksContainer = () => {
    const [redirectTimeout, setRedirectTimeout] = useState(REDIRECT_TIMEOUT);
    const [timeoutRunning, setTimeoutRunning] = useState(false);
    const history = useHistory();
    const {loading, error, data} = useQuery(GET_CONTRACT);

    useEffect(() => {
        const additionalSignatureEventData = {
            step: 7,
            name: "Podepsáno",
            type: "Signature",
            situationType: data?.contract?.situation,
            requestedDocument: getFormatRequestDocuments(data?.contract?.activeDocumentRequest)
        };

        logSignatureStepEvent(additionalSignatureEventData);
    }, []);

    useEffect(() => {
        if (shouldRedirectToDocuments(data?.contract) && !timeoutRunning) {
            if (data.contract.activeDocumentRequest.documentsState === DOCUMENT_REQUEST_STATE_COMPLETED) {
                return history.push(getDocumentsFinishedRoute());
            }

            setTimeoutRunning(true);
            let count = 1;
            const interval = setInterval(() => {
                if (count >= REDIRECT_TIMEOUT) {
                    clearInterval(interval);

                    return history.push(getDocumentsAddRoute());
                }

                setRedirectTimeout(redirectTimeout - count);
                count++;
            }, 1000);
        }

    }, [data, timeoutRunning]); // eslint-disable-line

    if (loading) return <Loading/>;
    if (error) return <Redirect to={`/${ERROR_ROUTE}`}/>;

    const {contract: {products, supplyPoints, activeDocumentRequest: {type}}} = data;

    const savingEnabled = !(data.contract?.activeDocumentRequest?.type === CUSTOM);

    return (
        <Wrapper>
            <Container>
                <StepNavigation {...getStepsConfig(data.contract.activeDocumentRequest, {
                    forceStage: 1,
                    forceStageName: SIGN_DOCUMENTS_STAGE
                })}/>
                <Inner>
                    <WhiteBox>
                        {!shouldRedirectToDocuments(data?.contract) && <DocumentsThanks>
                            <h1>Děkujeme <br/>za {type === CUSTOM ? "podepsání dokumentů" : "podpis smlouvy."}</h1>
                            {type === CUSTOM ?
                                <p>Nyní můžeme pokračovat v&nbsp;dokončení převodu Vašeho odběrného místa k MND.
                                    O&nbsp;dalším postupu Vás budeme informovat.</p> :
                                <p>O&nbsp;úspěšném přijetí dokumentů a&nbsp;o&nbsp;dalším průběhu Vás budeme informovat
                                    SMS&nbsp;zprávou <br/>(obvykle do 60 minut během naší pracovní doby 9.00 – 17.00)
                                </p>}
                            <Separator my="40px"/>
                            <Link as="a" href="https://cs-cz.facebook.com/MNDcz" target="_blank"
                                  rel="noopener noreferrer"><FacebookIcon/>Sledujte novinky z MND</Link>
                            <a
                                href="https://www.mnd.cz/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="../images/Richard.jpg" alt="Richard"/>
                            </a>
                        </DocumentsThanks>}

                        {shouldRedirectToDocuments(data?.contract) && <OnlineSignThanks>
                            <img src="../images/contract-icon.svg" alt="Ikona podpisu smlouvy"/>
                            <h1>Děkujeme, vaše podepsané dokumenty byly odeslané do&nbsp;MND</h1>
                            <p>Budete automaticky přesměřováni za <strong>{redirectTimeout}</strong> sek.</p>
                        </OnlineSignThanks>}
                    </WhiteBox>
                    {shouldRedirectToDocuments(data?.contract) && products.map((product, i) =>
                        <SideBoxRecap
                            key={i}
                            supplyPoints={supplyPoints}
                            tariffLabel={product.label}
                            tariffName={product.name}
                            consumption={product.consumption}
                            price={product.priceYear}
                            onlineDiscount={product.reduction}
                            savingEnabled={savingEnabled}
                        />)
                    }
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default OnlineSignThanksContainer;
