import React from "react";
import { Radio } from "../style/radio";
import { Checkbox } from "../style/checkbox";

const CheckboxInputSection = ( {globalCheckboxState, setGlobalCheckboxState, checkboxInputName, checkboxInputs} ) => {
    
    const ensureOneCheckbox = (e) => {

        if(!e.target.checked) {
            setGlobalCheckboxState("");
            return true;
        }

        for(const checkboxInput of checkboxInputs) {
            const value = checkboxInput.value;

            if(e.target.value === value) {
                setGlobalCheckboxState(value);
            }

        }

        return true;
    }

    const inputsJSX = checkboxInputs.map((input) => {

        const inputLabel = input.label;
        const inputValue = input.value;
        const inputName = `${checkboxInputName}${inputValue.charAt(0).toUpperCase() + inputValue.slice(1)}`;

        const inputChecked = globalCheckboxState === inputValue;
        return (
            <Checkbox>
                <label className={inputChecked ? "checked" : ""}>
                    <input type="checkbox"
                            value={inputValue}
                            onChange={ensureOneCheckbox}
                            name={inputName}
                            checked={inputChecked}
                    />
                    {inputLabel}
                    <span/>
                </label>
            </Checkbox>
        );
    });

    return (
        <>
            {inputsJSX}
        </>
    );

};

export default CheckboxInputSection;
