import gql from 'graphql-tag';

export default gql`
    query getShortUrl($role: ViewerType!) {
        contract {
            id
            shortUrl(role: $role)
        }
    }
`;
