import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`
    mutation FINALIZE_DOCUMENT_UPLOADS {
        finalizeDocumentUploads {
            ...ContractFields
        }
    }
    ${contractFragment}
`;
