import React from "react";
import subSituationsConfig from "../configs/subSituationsConfig";
import {Radio} from "../style/radio";

export const SubSituations = ({subSituations, contract, changeSubSituation, selectedSubSituation}) => {
    return (
        <div>
            {subSituations.map((subSituation) =>
                <Radio key={subSituation} className={"sub-situation"}>
                    <label className={selectedSubSituation === subSituation ? "checked" : ""}>
                        <input type="radio"
                               value={subSituation}
                               onChange={(event) => changeSubSituation({
                                   variables: {
                                       selectedSubSituation: event.target.value
                                   },
                                   optimisticResponse: {
                                       __typename: "Mutation",
                                       changeSubSituation: {
                                           ...contract,
                                           selectedSubSituation: event.target.value,
                                       }
                                   }
                               })}
                               key={subSituation}
                               name="subSituation"
                               checked={selectedSubSituation === subSituation}
                        />
                        {subSituationsConfig[subSituation].label}
                        <span/>
                    </label>
                </Radio>
            )}
        </div>
    );
};

export default SubSituations;
