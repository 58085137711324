import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`
    mutation SAVE_PARAMS($originalClientState: String, $relationToProperty: String) {
        saveParams(
            originalClientState: $originalClientState,
            relationToProperty: $relationToProperty
            
        ) {
            ...ContractFields
        }
    }
    ${contractFragment}
`;
