import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import PropTypes from 'prop-types';
import cx from "classnames";
import {TheRightTooltipStyles} from "../style/TheRightTooltipStyles.js" 

const style = {
  position: "relative",
  top: "-2px",
  backgroundColor: "#acaeb0",
  borderRadius: "9999px",
  display: "inline-block",
  width: "19px",
  height: "19px",
  lineHeight: "18px",
  margin: "0 6px",
  color: "white",
  textAlign: "center",
  fontSize: "15px",
  border: "0"
}

const TheRightTooltip = ({ text }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [visible, setVisible] = useState(false)
  
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    
    placement: "auto",
    modifiers: [{
      name: 'arrow', 
      options: { 
        element: arrowElement
      } 
    }, {
      name: "offset",
      enabled: true,
      options: {
        offset: [0, 10]
      }
    }],
  });
  
  const handleVisibleState = async () => {
    setVisible(!visible)
    await update();
  }

  return (
    <>
      <button 
        type="button" 
        ref={setReferenceElement} 
        style={style} 
        onMouseEnter={handleVisibleState} 
        onMouseLeave={handleVisibleState} 
        onTouchStart={handleVisibleState} 
        onTouchEnd={handleVisibleState}
        // onClick={handleVisibleState}
      >
        i
      </button>

      <TheRightTooltipStyles ref={setPopperElement} {...attributes.popper} style={styles.popper} className={cx({
        "visible": visible
      })}>
        {text}
        <div ref={setArrowElement} style={styles.arrow} className={"arrow"} />
      </TheRightTooltipStyles>
    </>
  );
};

TheRightTooltip.propTypes = {
  text: PropTypes.string.isRequired
};

export default TheRightTooltip;