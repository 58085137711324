import styled from "styled-components";

export const Link = styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    color: ${props => props.theme.colors.exploratoryGreen};
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: ${props => props.mt ? props.mt : 0} 0 0 0;
    
    svg {
        margin-right: 11px;
        position: relative;
        top: -1px;
    }
    
    &:focus {
        outline: none;
    }
    
    &:hover {
        text-decoration: none;
    }
`;
