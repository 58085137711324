import gql from 'graphql-tag';

export default gql`{
    viewer {
            id
            type
            process
            documentRequestId
        }
    }
`;
