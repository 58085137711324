import {POA_TYPE_OFFLINE, POA_TYPE_ONLINE} from "./poaType";

export default [
    {
        type: POA_TYPE_ONLINE,
        label: "Vyřídit a podepsat on-line",
        description: "Plnou moc doplní původní majitel z odkazu, který mu pošlete a podepíše on-line.",
    },
    {
        type: POA_TYPE_OFFLINE,
        label: "Pořídit scan či fotografii z tištěného dokumentu",
        description: "Pokud máte plnou moc původního majitele již podepsanou, stačí nám ji nahrát. V případě, že není možné zajistit plnou moc od původního majitele (např. z důvodu stěhování bez udání adresy), nahrajte nám prosím čestné prohlášení. V případě úmrtí nám, prosím, nahrajte úmrtní list. Pokud byla smlouva s původním dodavatelem ukončena, nahrajte nám zde prosím akceptaci výpovědi, kterou původní dodavatel vystavil.",
    },
];
