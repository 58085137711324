import React from "react";

export const SignLink = ({ text, isPdf, link }) => (
    <a href={link} target="_blank" className="label-sign-link">
        {isPdf ? (
            <svg className="link-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.875 12.375H12.1816L10.5908 13.9658C10.1672 14.3895 9.60117 14.625 9 14.625C8.39883 14.625 7.83422 14.3909 7.40918 13.9658L5.81836 12.375H1.125C0.503789 12.375 0 12.8788 0 13.5V16.875C0 17.4962 0.503789 18 1.125 18H16.875C17.4962 18 18 17.4962 18 16.875V13.5C18 12.8777 17.4973 12.375 16.875 12.375ZM15.1875 16.0312C14.7234 16.0312 14.3438 15.6516 14.3438 15.1875C14.3438 14.7234 14.7234 14.3438 15.1875 14.3438C15.6516 14.3438 16.0312 14.7234 16.0312 15.1875C16.0312 15.6516 15.6516 16.0312 15.1875 16.0312ZM8.20547 13.1695C8.42344 13.391 8.71172 13.5 9 13.5C9.28828 13.5 9.57586 13.3903 9.79523 13.1702L14.2952 8.67023C14.7343 8.23078 14.7343 7.51887 14.2952 7.07941C13.8558 6.63996 13.1435 6.63996 12.7044 7.07941L10.125 9.66059V1.125C10.125 0.503789 9.62121 0 9 0C8.37879 0 7.875 0.503789 7.875 1.125V9.66094L5.29453 7.08047C4.85543 6.64102 4.14316 6.64102 3.70371 7.08047C3.26461 7.51992 3.26461 8.23184 3.70371 8.67129L8.20547 13.1695Z" fill="#2B6E33"/>
            </svg>
        ) : (
            <svg className="link-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.3 15.3H2.70002V2.70002H9.00002V0.900024H2.70002C1.70102 0.900024 0.900024 1.71002 0.900024 2.70002V15.3C0.900024 16.29 1.70102 17.1 2.70002 17.1H15.3C16.29 17.1 17.1 16.29 17.1 15.3V9.00002H15.3V15.3ZM10.8 0.900024V2.70002H14.031L5.18402 11.547L6.45302 12.816L15.3 3.96902V7.20002H17.1V0.900024H10.8Z" fill="#2B6E33"/>
            </svg>
        )}
        <span>
            {text}
        </span>
    </a>
)