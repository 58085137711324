import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`
    mutation changeSubSituation($selectedSubSituation:SubSituation) {
        changeSubSituation(
            selectedSubSituation: $selectedSubSituation
        ) {
            ...ContractFields
        }
    }
    ${contractFragment}
`;
