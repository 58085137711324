import styled from "styled-components";

export const OnlineSignConfirmation = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .documents {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        margin-right: 0;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            flex-direction: row;
            margin-top: 15px;
            
            div:not(:last-child) {
                margin-right: 45px;
            }
        }
    }
`;
