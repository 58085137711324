import styled from "styled-components";

export const TheRightTooltipStyles = styled.div`
  z-index: 5000;
	color: ${props => props.theme.colors.exploratoryGreen};
	font-size: 12px;
	font-weight: 600;
	visibility: hidden;
  padding: 8px 15px;
  background-color: ${props => props.theme.colors.resourceGreen100};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.grey};
  max-width: 280px;
  cursor: pointer;
	&.visible {
		visibility: visible;
	}
    
  .arrow {
	  position: absolute;
	  width: 0;
	  height: 0;
  }
    
	&[data-popper-placement^='top'] > .arrow {
    bottom: -7px;
	  border-left: 6px solid #0000;
	  border-right: 6px solid #0000;
	  border-top: 6px solid ${props => props.theme.colors.grey};
		
	}

	&[data-popper-placement^='bottom'] > .arrow {
	  top: -7px;
	  border-left: 6px solid #0000;
	  border-right: 6px solid #0000;
	  border-bottom: 6px solid ${props => props.theme.colors.grey};	
	}

	&[data-popper-placement^='right'] > .arrow  {
    left: -7px;
    top: -2px !important;
	  border-top: 6px solid #0000;
	  border-bottom: 6px solid #0000;
	  border-right: 6px solid ${props => props.theme.colors.grey};
      
	}

	&[data-popper-placement^='left'] > .arrow  {
	  right: -7px;
	  top: -2px !important;
	  border-top: 6px solid #0000;
	  border-bottom: 6px solid #0000;
	  border-left: 6px solid ${props => props.theme.colors.grey};
	}
`;
