import {
    DOCUMENT_TYPE_OTHER_DOCUMENTS,
    DOCUMENT_TYPE_OTHER_REQUESTED_DOCUMENT,
    DOCUMENT_TYPE_POA_CEZ,
    DOCUMENT_TYPE_REPRESENTATION_OF_THE_OWNER,
    DOCUMENT_TYPE_TERMINATION_OF_CONTRACT_PREVIOUS_OWNER,
    DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION_OFFLINE,
    DOCUMENT_TYPE_CERTIFICATE_OF_RELATION_TO_PROPERTY,
    DOCUMENT_TYPE_CONTRACT_MND,
    DOCUMENT_TYPE_LAND_REGISTRY_DOCUMENT,
    DOCUMENT_TYPE_LEASE_CONTRACT,
    DOCUMENT_TYPE_POA,
    DOCUMENT_TYPE_POA_MND,
    DOCUMENT_TYPE_POA_OFFLINE,
    DOCUMENT_TYPE_PURCHASE_CONTRACT,
    DOCUMENT_TYPE_REVISION,
    DOCUMENT_TYPE_TRANSFER_PROTOCOL,
    DOCUMENT_TYPE_OTHER_REASON,
    LAND_REGISTRY_PREVIEW,
    DOCUMENT_TYPE_DEATH_CERTIFICATE,
    DOCUMENT_TYPE_DECLARATION_OF_HONOR,
    DOCUMENT_TYPE_COMPLAINT_FORM,
    DOCUMENT_TYPE_SUBJECT_PERSONAL_DATA_CORRECTION,
    DOCUMENT_TYPE_OM_TRANSCRIPT_CONSENT,
    DOCUMENT_TYPE_CONTRACT_WITHDRAWAL,
    DOCUMENT_TYPE_CONTRACT_TERMINATION,
    DOCUMENT_TYPE_POWER_OF_ATTORNEY_ON_BEHALF,
    DOCUMENT_TYPE_ID_CARD_COPY,
    DOCUMENT_TYPE_MARRIAGE_CERTIFICATE,
    DOCUMENT_TYPE_REVISION_AND_PRESSURE_TEST,
    DOCUMENT_TYPE_TERMINATION_AGREEMENT,
    DOCUMENT_TYPE_PPP_SURPLUS_PURCHASE_WITHDRAWAL,
    DOCUMENT_TYPE_PPP_SURPLUS_PURCHASE_TERMINATION_WITHDRAWAL,
    DOCUMENT_TYPE_AFFIDAVIT_OF_DEATH,
    DOCUMENT_TYPE_SURPLUS_PURCHASE_CONTRACT,
    DOCUMENT_TYPE_EGD,
    DOCUMENT_TYPE_CONSENT_TO_SANCTION_MND,
    DOCUMENT_TYPE_CHANGE_DISTRIBUTION_RATE_OR_METER,
    DOCUMENT_TYPE_ORIGINAL_SUPPLIER_INVOICE,
} from "./index";

export default {
    [DOCUMENT_TYPE_CONTRACT_MND]: {
        title: "Smlouva s MND",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_POA_MND]: {
        title: "Plná moc MND",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_POA]: {
        title: "Plná moc 3. strany - online",
        description: "",
        uploadDescription: "Nahrát plnou moc původního majitele, čestné prohlášení nebo akceptaci výpovědi",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_POA_OFFLINE]: {
        title: "Plná moc 3. strany - offline",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_PURCHASE_CONTRACT]: {
        title: "Kupní smlouva",
        tooltip: "Pokud ještě nedošlo k přepsání majitele na katastru nemovitostí, vložte prosím kupní smlouvu.",
        description: "Pro ověření majitele",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_LEASE_CONTRACT]: {
        title: "Nová nájemní smlouva",
        description: "Jako nájemníkovi nemovistosti stačí k ověření pouze nájemní smlouva.",
        uploadDescription: "Nahrát nájemní smlouvu",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_LAND_REGISTRY_DOCUMENT]: {
        title: "Výpis z katastru",
        description: "Majiteli nemovitosti stačí výpis z katastru nemovitostí, který nemusí být úředně ověřený.",
        uploadDescription: "Nahrát výpis z katastru",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            link: "/documents/vypis-z-katastru-vzor.pdf",
            title: "Vzor výpisu z katastru",
            label: "Stáhnout vzor",
            preview: LAND_REGISTRY_PREVIEW
        }
    },
    [DOCUMENT_TYPE_TRANSFER_PROTOCOL]: {
        title: "Předávací protokol",
        tooltip: "Předávací protokol nám slouží k přesnější fakturaci původního zákazníka dle spotřeby, která byla naměřena při předání nemovitosti.",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor předávacího protokolu",
            label: "Stáhnout vzor",
            preview: "/images/handover-protocol.jpg"
        }
    },
    [DOCUMENT_TYPE_CERTIFICATE_OF_RELATION_TO_PROPERTY]: {
        title: "Doklad o vztahu k nemovitosti",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_REVISION]: {
        title: "Revize",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION_OFFLINE]: {
        title: "Offline ŽOP (bez podpisu)",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_OTHER_DOCUMENTS]: {
        title: "Další dokumenty, které potřebujeme",
        description: "Prosíme Vás o dodání nejaktuálnějších dokumentů. Jedná se např. o smlouvu nebo dodatek od stávajícího dodavatele, fakturu, uvítací dopis, apod.",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_OTHER_REQUESTED_DOCUMENT]: {
        title: "Další dokumenty, které potřebujeme",
        description: "Prosíme Vás o dodání nejaktuálnějších dokumentů.",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_REPRESENTATION_OF_THE_OWNER]: {
        title: "Plná moc k zastoupení majitele",
        description: "Pokud podepisujete dokumenty k převodu energií za nového či původního majitele, nahrajte nám prosím plnou moc k zastupování.",
        uploadDescription: "Nahrát plnou moc k zastupování",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_TERMINATION_OF_CONTRACT_PREVIOUS_OWNER]: {
        title: "Výpověď původního majitele",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_POA_CEZ]: {
        title: "Plná moc ČEZ",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_OTHER_REASON]: {
        title: "Další dokumenty, které potřebujeme",
        description: "Jedná se např. o tyto dokumenty: smlouva nebo faktura od stávajícího dodavatele, podnájemní smlouva, souhlas majitele s podnájmem, výpis z katastru, předávací protokol, ukončení nájemní smlouvy, doklad o rozvodu a majetkovém vypořádání, doklad o zdravotní nezpůsobilosti, výmaz z obchodního a živnostenského rejstříku.",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_DEATH_CERTIFICATE]: {
        title: "Úmrtní list",
        tooltip: "Pro vyřízení prosím vložte úmrtní list. V případě, že úmrtní list nemůžete doložit, budeme Vás kontaktovat, abychom se domluvili na ukončení původní smlouvy. Do řádného ukončení smlouvy zemřelého zůstavají sjednané podmínky stále v platnosti (např. povinnost platit zálohové platby)",
        uploadDescription: "Přidat dokument",
        uploadMoreDescription: "Přidat další dokumenty",
        mandatorySetting: true,
        mandatorySettingText: "Úmrtní list nemám nyní k dispozici, doložím ho později."
    },

    /*LEASE_TERMINATION_AGREEMENT: {
        title: "Dohoda o ukončení původního nájmu",
        description: "Pro ověření ...",
        uploadDescription: "Nahrát dohodu",
        uploadMoreDescription: "Nahrát dohodu"
    },*/
    [DOCUMENT_TYPE_DECLARATION_OF_HONOR]: {
        title: "Čestné prohlášení",
        description: "",
        uploadDescription: "Přidat dokument",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor čestného prohlášení",
            label: "Stáhnout vzor",
            preview: "/images/declaration-of-honor.jpg"
        }
    },
    [DOCUMENT_TYPE_COMPLAINT_FORM]: {
        title: "Formulář reklamace",
        description: "",
        uploadDescription: "Přidat dokument",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor formuláře reklamace",
            label: "Stáhnout vzor",
            preview: "/images/complaint-form.jpg"
        }
    },
    [DOCUMENT_TYPE_SUBJECT_PERSONAL_DATA_CORRECTION]: {
        title: "Oprava osobních dat",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor formuláře",
            label: "Stáhnout vzor",
            preview: "/images/subject-personal-data-correction.jpg"
        }
    },
    [DOCUMENT_TYPE_OM_TRANSCRIPT_CONSENT]: {
        title: "Souhlas s přepisem odběrného místa",
        description: "",
        uploadDescription: "Přidat dokument",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor souhlasu",
            label: "Stáhnout vzor",
            preview: "/images/om-transcript-consent.jpg"
        }
    },
    [DOCUMENT_TYPE_CONTRACT_WITHDRAWAL]: {
        title: "Odstoupení od smlouvy",
        description: "",
        uploadDescription: "Přidat dokument",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor odstoupení od smlouvy",
            label: "Stáhnout vzor",
            preview: "/images/contract-withdrawal.jpg"
        }
    },
    [DOCUMENT_TYPE_CONTRACT_TERMINATION]: {
        title: "Výpověď",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor výpovědi",
            label: "Stáhnout vzor",
            preview: "/images/contract-termination.jpg"
        }
    },
    [DOCUMENT_TYPE_POWER_OF_ATTORNEY_ON_BEHALF]: {
        title: "Plná moc pro jednání v zastoupení",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor plné moci",
            label: "Stáhnout vzor",
            preview: "/images/power-of-attorney-on-behalf.jpg"
        }
    },
    [DOCUMENT_TYPE_ID_CARD_COPY]: {
        title: "Kopie občanského průkazu",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_MARRIAGE_CERTIFICATE]: {
        title: "Oddací list",
        description: "",
        uploadDescription: "Přidat dokument",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_REVISION_AND_PRESSURE_TEST]: {
        title: "Revize a tlaková zkouška",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_TERMINATION_AGREEMENT]: {
        title: "Akceptace výpovědi",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty"
    },
    [DOCUMENT_TYPE_POA]: {
        title: "Plná moc třetí strany",
        description: "",
        uploadDescription: "Přidat dokument",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor plné moci",
            label: "Stáhnout vzor",
            preview: "/images/power-of-attorney-previous-owner.jpg"
        }
    },
    [DOCUMENT_TYPE_PPP_SURPLUS_PURCHASE_WITHDRAWAL]: {
        title: "Odstoupení / výpověď dodatku",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor odstoupení / výpovědi dodatku",
            label: "Stáhnout vzor",
            preview: "/images/ppp_surplus_purchase_termination_withdrawal.png"
        }
    },
    [DOCUMENT_TYPE_PPP_SURPLUS_PURCHASE_TERMINATION_WITHDRAWAL]: {
        title: "Zpětvzetí výpovědi smlouvy a dodatku",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor zpětvzetí výpovědi smlouvy a dodatku",
            label: "Stáhnout vzor",
            preview: "/images/ppp_surplus_purchase_withdrawal.png"
        }
    },
    [DOCUMENT_TYPE_AFFIDAVIT_OF_DEATH]: {
        title: "Čestné prohlášení - úmrtí",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor čestného prohlášení - úmrtí",
            label: "Stáhnout vzor",
            preview: "/images/affidavit_of_death.png"
        }
    },
    [DOCUMENT_TYPE_SURPLUS_PURCHASE_CONTRACT]: {
        title: "Dodatek ke smlouvě - Solární účet",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor dodatku ke smlouvě - Solární účet",
            label: "Stáhnout vzor",
            preview: "/images/fve_surplus_purchase_contract.png"
        }
    },
    [DOCUMENT_TYPE_EGD]: {
        title: "Čestné prohlášení – EG.D",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor k čestnému prohlášení – EG.D",
            label: "Stáhnout vzor",
            preview: "/images/sop_cp_egd.png"
        }
    },
    [DOCUMENT_TYPE_CONSENT_TO_SANCTION_MND]: {
        title: "Souhlas se sankcí u MND",
        description: "",
        uploadDescription: "Přidat dokument",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Souhlas se sankcí u MND",
            label: "Stáhnout vzor",
            preview: "/images/consent-to-sanction.jpg"
        }
    },
    [DOCUMENT_TYPE_CHANGE_DISTRIBUTION_RATE_OR_METER]: {
        title: "Žádost o změnu distribuční sazby/jističe",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
        exampleFile: {
            title: "Vzor žádosti o změnu distribuční sazby/jističe",
            label: "Stáhnout vzor",
            preview: "/images/change_distribution_rate_or_meter.png"
        }
    },
    [DOCUMENT_TYPE_ORIGINAL_SUPPLIER_INVOICE]: {
        title: "Faktura původního dodavatele",
        description: "",
        uploadDescription: "Přidat dokumenty",
        uploadMoreDescription: "Přidat další dokumenty",
    },
};
