import React, { useEffect } from "react";

import StepNavigation from "../components/StepNavigation";
import { Container, Inner, WhiteBox, Wrapper } from "../style/grid";
import { Link } from "../style/link";
import { DocumentsThanks } from "../style/documentsThanks";
import FacebookIcon from "../style/icons/fb";
import { Separator } from "../style/separator";
import { VIEWER_PROCESS_DOCUMENTS } from "../configs";
import getStepsConfig, { ADD_DOCUMENTS_STAGE } from "../helpers/getStepsConfig";
import { useQuery } from "@apollo/react-hooks";
import GET_CONTRACT from "../graphql/queries/getContract";
import { CUSTOM } from "../configs/documentRequestType";
import { signatureStates, states } from "../configs/routes";
import { getFormatRequestDocuments, logSignatureStepEvent } from "../utils/analytics";

const DocumentsThanksContainer = ({ viewer: { process, type } }) => {
    const { loading, data } = useQuery(GET_CONTRACT);

    useEffect(() => {
        const requestedDocument = getFormatRequestDocuments(data?.contract?.activeDocumentRequest);

        const additionalSignatureEventData = {
            step: 9,
            name: "Hotovo",
            text: 'Hotovo',
            type: "Documents",
            situationType: data?.contract?.situation,
            ...(requestedDocument ? { requestedDocument } : {})
        };

        logSignatureStepEvent(additionalSignatureEventData);
    }, []);

    const contentConfig = ({
        currentStage: process === VIEWER_PROCESS_DOCUMENTS ? 0 : 2,
        stages: process === VIEWER_PROCESS_DOCUMENTS ? ["Doplnění dokumentů"] : undefined,
    })

    const isCustomDocumentRequest = data.contract.activeDocumentRequest.type === CUSTOM;
    const hasDocumentsAndSignature = isCustomDocumentRequest &&
        data.contract.activeDocumentRequest.signatureState === signatureStates.SIGNATURE_CONFIRMED &&
        data.contract.activeDocumentRequest.documentsState === states.COMPLETE;

    return (
        <Wrapper>
            <Container>
                {!loading && <StepNavigation  {...getStepsConfig(data.contract.activeDocumentRequest, {
                    forceStage: contentConfig.currentStage,
                    forceStageName: ADD_DOCUMENTS_STAGE
                })} />}
                <Inner>
                    <WhiteBox>
                        <DocumentsThanks>
                            <h1>Děkujeme Vám<br />za{hasDocumentsAndSignature && "  podpis a"} zaslání dokumentů</h1>

                            {isCustomDocumentRequest ? <p>Nyní můžeme pokračovat v&nbsp;dokončení převodu Vašeho odběrného místa k MND. O&nbsp;dalším postupu Vás budeme informovat.</p> :
                                <p>Nyní začneme pracovat na&nbsp;Vašem převodu výkupu do&nbsp;MND.
                                    Pokud budeme potřebovat něco doplnit,&nbsp;ozveme se&nbsp;Vám.
                                </p>}
                            <Separator my="40px" />
                            <Link as="a" href="https://cs-cz.facebook.com/MNDcz" target="_blank"
                                rel="noopener noreferrer"><FacebookIcon />Sledujte novinky z MND</Link>
                            <a
                                href="https://www.mnd.cz/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="../images/Richard.jpg" alt="Richard" />
                            </a>
                        </DocumentsThanks>
                    </WhiteBox>
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default DocumentsThanksContainer;
