import gql from 'graphql-tag';

export default gql`
    mutation SEND_TO_MOBILE($url: String!) {
        sendToMobile(
            url: $url
        ) {
            id
        }
    }
`;
