import gql from 'graphql-tag';
import contractFragment from "../fragments/contract";

export default gql`{
    contract {
        ...ContractFields
        solusConsentState
        supplyPoints {
            address {
                firstName
                lastName
                street
                houseNumber
                streetNumber
                plotNumber
                city
                zipCode
            }
        }
    }
}
${contractFragment}
`;
