import styled from "styled-components";
import {StyledDropzone} from "./styledDropzone";

export const StyledDropzoneStatic = styled(StyledDropzone)`
    margin: 20px 0;
    border-color: ${props => props.theme.colors.darkGrey};
    max-width: 100%;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        max-width: 350px;
    }
`;
