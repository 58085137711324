import {useQuery} from '@apollo/react-hooks';

import GET_SHORT_URL from "../graphql/queries/getShortUrl";

const ShortUrlContainer = ({children, role}) => {
    const { loading, error, data } = useQuery(GET_SHORT_URL, {variables: {role}})

    if(loading) {
        return null;
    }

    if(error) {
        return null;
    }

    return children(data.contract.shortUrl);
}

export default ShortUrlContainer;