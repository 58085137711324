import styled from "styled-components";

export const UploadedDocument = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    max-width: 125px;
    
    .image {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        //overflow: hidden;
        width: 125px;
        height: 172px;
        position: relative;
		border: 5px solid ${props => props.theme.colors.resourceGreen};
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
      
        span.doc {
            position: absolute;
            left: -14px;
            width: 143px;
            top: 50%;
            transform: translateY(-50%);
            background: ${props => props.theme.colors.resourceGreen};
            height: 20px;
            font-size: 14px;
            font-weight: 600;
            color: ${props => props.theme.colors.white};
            text-decoration: none;
            border-radius: 4px;
            text-align: center;
		    opacity: 1;
		    transition: opacity ${props => props.theme.times.defaultAnimation};
		}
    }
    
    &:hover {
        span.doc {
            opacity: 0;
        }
      
        .overlay {
            opacity: 1;
        }
    } 
    
    .overlay {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity ${props => props.theme.times.defaultAnimation};
        
        span {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: ${props => props.theme.colors.exploratoryGreen};
            transition: background-color ${props => props.theme.times.defaultAnimation};
            margin-bottom: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            
            svg {
                margin: 0;
            }
            
            &:hover {
                background-color: ${props => props.theme.colors.resourceGreen};
            }
        }
        
        span:last-of-type {
            margin-bottom: 0;
        }
    }
    
    .default {
        background-size: 50%;
    }
    
    .other {
        background-size: contain !important;
    }

	.label {
	    font-weight: 600;
		font-size: 15px;
		margin-bottom: 10px;
		color: ${props => props.theme.colors.exploratoryGreen};
	}
		
	.link {
	    margin: 5px 0;
		text-decoration: underline;
		color: ${props => props.theme.colors.expertiseGray};
		font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 110px;
        text-align: center;
		
		&:hover {
		    text-decoration: none;
			color: ${props => props.theme.colors.expertiseGray};
		}	
	}	
`;
