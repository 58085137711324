import React from "react";
import classNames from "classnames";

import isMobile from "../helpers/isMobile";
import {UploadedDocument as UploadedDocumentStyle} from "../style/uploadedDocument";

import ViewIcon from "../style/icons/view";
import DeleteIcon from "../style/icons/delete";
import LoadingIcon from "../style/loading";
import {DEFAULT_PREVIEW} from "../configs";

const getDefaultOverlayLabel = (isNotSent => {
    if (typeof isNotSent !== "undefined") {
        if (isNotSent) {
            return "Nahráno a uloženo";
        } else {
            return "Odesláno do MND";
        }
    }
    return "";
})

const UploadedDocument = ({link, linkLabel, overlayLabel, preview, setModalPreview, loading, deleteDocument, isDraft: isNotSent}) => {
    const showPreviewIcon = preview.filePreviewType === "modal";

    const handleDeleteClick = () => {
        if (window.confirm("Opravdu chcete vymazat dokument?")) {
            deleteDocument();
        }
    }

    return <UploadedDocumentStyle>
    <span
        className={classNames({
            "image": true,
            "other": preview.fileType === "other",
            "default": preview.fileType === "default" || preview.fileBackground === DEFAULT_PREVIEW,
        })}
        style={{backgroundImage: !loading && `url(${preview.fileBackground ?? ""})`}}
    >
        {loading && <LoadingIcon className={"loading"} simple />}

        {!loading && <span className="doc">{overlayLabel ?? getDefaultOverlayLabel(isNotSent)}</span>}

        {!loading && <div
            className="overlay"
            onClick={(e) => e.stopPropagation()}
        >
            {showPreviewIcon && <span onClick={() => setModalPreview({
                isOpen: true,
                preview: preview.filePreview,
                fileType: preview.fileType
            })}>
                <ViewIcon />
            </span>}

            {isNotSent && <span onClick={handleDeleteClick}>
                <DeleteIcon/>
            </span>}
        </div>}
    </span>
    <a
        href={link}
        className="link"
        target={isMobile() ? "_self" : "_blank"}
        title={linkLabel}
        onClick={(e) => e.stopPropagation()}
    >
        {linkLabel ?? <>Zobrazit náhled<br/>v PDF</>}
    </a>
    </UploadedDocumentStyle>;
}

export default UploadedDocument;
