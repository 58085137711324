import gql from 'graphql-tag';

export default gql`
    fragment DocumentFields on Document {
        id
        category
        fileLink
        fileLinkInline
        fileLinkSigned
        fileLinkSignedInline
        fileName
        mimetype
        isDraft
    }
`;
