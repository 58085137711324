import React from "react";
import {useDropzone} from "react-dropzone";

import AttachmentIcon from "../style/icons/attachment";
import {StyledDropzone} from "../style/styledDropzone";
import UploadedDocument from "./UploadedDocument";
import getDocumentPreview from "../helpers/getDocumentPreview";
import createDeleteDocumentHandler from "../helpers/createDeleteDocumentHandler";
import {ALLOWED_FILE_TYPES, MAX_SIZE} from "../configs";


const Dropzone = ({onDrop, uploadDescription, uploadMoreDescription, dropzoneRef, contract, documents, setErrorMessage, setModalPreview, deleteDocument}) => {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: ALLOWED_FILE_TYPES,
        maxSize: MAX_SIZE,
        onDropAccepted: onDrop,
        onDropRejected: (files) => {
            let showFileSizeError = false;
            if (files && files.length) {
                files.forEach((file) => {
                    if (file.size > MAX_SIZE) {
                        showFileSizeError = true;
                    }
                });
            }

            return showFileSizeError ?
                setErrorMessage(`Soubor je příliš velký (max. ${MAX_SIZE / 1000000} MB)`) :
                setErrorMessage("Některé soubory se nepodařilo přidat. Je možné nahrávat jen obrázky ve formátu PNG a JPG a soubory PDF a DOCX.");
        }
    });

    return (
        <StyledDropzone {...getRootProps({isDragActive, isDragAccept, isDragReject})} ref={dropzoneRef}>
            <input {...getInputProps()} />
            {documents.map(document =>
                <UploadedDocument
                    key={document.id}
                    isDraft={document.isDraft}
                    link={document.fileLink}
                    linkLabel={document.fileName}
                    fileType={document.mimetype}
                    preview={getDocumentPreview(document.mimetype, document.fileLink)}
                    setModalPreview={setModalPreview}
                    loading={document.id.match(/^loading/)}
                    deleteDocument={createDeleteDocumentHandler(deleteDocument, contract, document.id)}
                />
            )}
            {documents.length === 0 && <span>
                <AttachmentIcon/>
                {uploadDescription ? uploadDescription : "Přidat soubory"}
            </span>}
            {documents.length > 0 && <span>
                <AttachmentIcon/>
                {uploadMoreDescription ? uploadMoreDescription : "Přidat další soubory"}
            </span>}
        </StyledDropzone>
    );
};

export default Dropzone;
