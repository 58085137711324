import styled from "styled-components";

export const StepNavigation = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 20px;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        margin-top: 20px;
    }

    & > div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 9px;
        padding-bottom: 30px;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            padding-bottom: 33px;
            margin: 0;
        }

        @media screen and (max-width: 360px) {
            padding-bottom: 50px;
        }
    }
`;

export const Step = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    &[type="disabled"] > p {
        color: #595b5b;
    }
  
    & > div {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background-color: ${props => props.type === "disabled" ? props.theme.colors.professionalGray : props.type === "active" ? props.theme.colors.exploratoryGreen : props.theme.colors.resourceGreen};
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 20px;
        color: ${props => props.theme.colors.white};
        font-weight: 500; 
        border: 4px solid ${props => props.theme.colors.grey};
        
        & > svg {
            align-self: center;
        }
    }
    
    & > p {
        position: absolute;
        font-size: 14px;
        top: 50px;
        white-space: normal;
        text-align: center;
        line-height: 1.3;
        color: ${props => props.theme.colors.exploratoryGreen};
        font-weight: ${props => props.type === "active" || props.type === "done" ? "bold" : "normal"};
        width: 30vw;
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            white-space: nowrap;
            width: auto;
        }

        @media screen and (max-width: 360px) {
            width: auto;
        }
    }
`;

export const Bar = styled.div`
    height: 4px;
    width: 85px;
    background-color: ${props => props.theme.colors.professionalGray};
    
    &:before {
        content: "";
        display: block;
        width: ${props => props.width}%;
        height: 100%;
        background-color: ${props => props.theme.colors.exploratoryGreen};
    }
    
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        width: 120px;
    }

    @media screen and (max-width: 360px) {
        width: 60px;
    }
`;
