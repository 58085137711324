import styled from "styled-components";

export const OnlineSignThanks = styled.div`   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
       
    @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
        padding-top: 100px;
    }
    
    img {
        width: 53px;
        margin-bottom: 15px;
    }
    
    h1 {
        text-align: center;
        font-size: 26px;
        line-height: 36px;
        color: ${props => props.theme.colors.exploratoryGreen};    
        margin-bottom: 15px;
    
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            max-width: 450px;
            line-height: 36px;
        }    
    }
    
    p {
        font-size: 14px;
        color: ${props => props.theme.colors.professionalGray};
        text-align: center;
    }
`;
