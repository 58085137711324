export default {
    colors: {
        white: "#FFF",
        textBlack: "#000",
        exploratoryGreen: "#2B6E33",
        exploratoryGreen600: "#1E4722",
        expertiseGray: "#595A5B",
        resourceGreen: "#A8BE32",
        resourceGreenDarker: "#90A032",
        resourceGreen100: "#EEF2D6",
        professionalGray: "#ACAEB0",
        lightGrey: "#E6E6E7",
        grey: "#D6D6D6",
        darkGrey: "#9F9F9F",
        errorRed: "#DD2018"
    },
    breakpoints: {
        container: "1200px",
        mobile: "865px",
        smallMobile: "400px"
    },
    sizes: {
        container: "1150px"
    },
    times: {
        defaultAnimation: "300ms"
    }
};
